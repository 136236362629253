import React from "react";

const ShowDate = ({ date }) => {
  // Create a new Date object from the provided date string
  const dateTime = new Date(date);

  // Get individual date components
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const seconds = String(dateTime.getSeconds()).padStart(2, '0');

  // Concatenate date components to form the desired format
  const formattedDate = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;

  // Return the formatted date string
  return <span>{formattedDate}</span>;
};

export default ShowDate;
