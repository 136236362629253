// chakra ui
import { Box, Button, Container, HStack, VStack, Center, Text, Heading } from "@chakra-ui/react";
// general
import { useQuery,QueryFunctionContext,QueryKey, QueryClient  } from "@tanstack/react-query";
  import { useQueryClient } from "@tanstack/react-query";
  import React, { useState, useEffect }from 'react';
// import user
import useUser from "../../lib/useUser";
// import apis
import { getCateNotes, getMyPosts } from "../../apisntypes/noteapis";
// import component
import CateNote from "./CateNote";
import Pagination from "../common/Pagination";
// import TopPanel from "../common/TopPanel";
// import types
import { IAllNotesData } from "../../apisntypes/notetypes";
// import Adsense from "../common/Adsense";
import ProtectedPage from "../ProtectedPage";


export default function MyPosts() {
  // static
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const {userLoading, isLoggedIn, user} = useUser();
  // usequery
  const { isLoading:isLoadingCateNotes, data:cateNotesData } = useQuery<IAllNotesData>([`myposts`,], getMyPosts);
  const queryClient = useQueryClient();

  if (!isLoadingCateNotes) {
//   console.log(cateNotesData);
  }
  let nextPage = cateNotesData?.next || "";
  let count = 0;
  let totalPages =0;
  let orderNumber = 0;
  let orderNumberCateAll =0;
  const cateNotesCount = cateNotesData?.count || 0;

  orderNumber = count - (currentPage-1)*pageSize;
  orderNumberCateAll = cateNotesCount - (currentPage-1)*pageSize;
  totalPages = Math.ceil(cateNotesData?.count / pageSize);

  // func
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
    <ProtectedPage>
    <Container  maxW="container.lg">
      <Center><Heading m="2"> My Posts</Heading></Center>
        {!isLoadingCateNotes &&  Array.isArray(cateNotesData?.results)   ? 
          <>
          {cateNotesData?.results.map((note, index) => (
            <React.Fragment key={note?.pk}>
            <CateNote 
              key={index}
              title={note?.title}
              content={note?.content}
              creator={note?.creator}
              link={note?.link}
              view_count={note?.view_count}
              rec_count={note?.rec_count}
              updated_at={note?.updated_at}
              pk={note?.pk}
              addr={note?.addr}
              pack_pk={note?.pack_pk}
              cate_pk={note?.cate_pk}
              prompt={note?.prompt}
              is_secret={note?.is_secret}
              count={cateNotesCount}
              orderNumber={orderNumberCateAll--}
              searchStr=""
              />
            </React.Fragment>
          ))}
          </> : null }
        {cateNotesCount > pageSize ?
          <Center m="5">
              <Pagination
                pageSize={pageSize}
                handlePageChange={handlePageChange}
                count={cateNotesCount}
                currentPage={currentPage}
                totalPages={totalPages}
                nextPage={nextPage}
                />
            </Center>
        : null }
      </Container>
      </ProtectedPage>
      </>
  );
}