import { GridItem, Grid, VStack, Box, HStack, Text, Button, 
    useToast, Tooltip, IconButton, useColorMode } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {client} from "../../index";
import useUser from "../../lib/useUser";
// import apis
import { INPack } from "../../apisntypes/packtypes"; 
// icons
import {FaRegTrashAlt,FaRegSun, FaLock, FaLockOpen, FaStar, 
    FaExclamationCircle, FaAlignJustify, FaPollH   } from "react-icons/fa";
// import custom
import { formatDate, formatDay, newTag, newTag2, useIsPhone } from "../../lib/Utils";
import { StarButton } from "../common/StarButton";
import { formTitle } from "../../lib/Utils";
import "../../css/general.css";

export default function NPackItem ({
    pk,
    pack_pk,
    title,
    addr,
    desc,
    creator,
    postCount,
    cateCount,
    updated_at,
    pubOpt,
    language,
    cateOpt,
    noteOpt,
    commentOpt,
    inputString,
    renderCount,
    nPackPage,
    isFavorite,
    favoritePacks,
    setFavoritePacks,
    is_newpost,
    }:INPack
) {
    const {userLoading, isLoggedIn, user} = useUser();
    const [showCounts, setShowCounts] = useState(false); // State to toggle visibility of counts
    const toast = useToast();
    const isCreator = (user?.username === creator?.username) || false;
    const formattedTitle = formTitle(title);
    const updateFavoriteStatus = (newStatus) => {
        // Update the favorite status here using setFavoritePacks or any other appropriate method
        // For example:
        setFavoritePacks(newStatus); // Assuming setFavoritePacks updates the state
    };
    const tag =  newTag(updated_at);
    const isNewpost = newTag2(is_newpost);
    return (
    <>
        <GridItem 
            key={pk} 
            w="100%" 
            h="auto"
            rounded={"8px"}
            textAlign="center" 
            bg={isCreator ? "blue.500" :"blue.700" }
            p="4"
            // py="10px"
            // display="flex"
            // flexDirection="column"
            // justifyContent="space-between"
            // {...customBgColor.customBgColorBlue}
            // {...embossedBox}

            >
            <VStack  h="100%" justify="space-between" w="100%">
                {/* ====제목 */}
                <Box 
                    position="relative"
                    fontWeight={"bold"}
                    w="95%"
                    maxW="200px"
                    h="auto"
                    color="white"
                    fontSize={"xl"}
                    textAlign={"center"}
                    alignItems="center"
                    px="2"
                    overflow="hidden"
                    whiteSpace="pre-line"
                    textOverflow="ellipsis"
                    css={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        height: "4em",
                        wordWrap: 'break-word', 
                        overflowWrap: 'break-word',
                      }}
                    >
                { isLoggedIn ? 
                    <StarButton 
                        pack_pk={pack_pk} 
                        favOrNot={isFavorite} // y or n
                        favoritePacks={favoritePacks} // 전체 리스트 넘겨줌
                        updateFavoriteStatus={updateFavoriteStatus} 
                        setFavoritePacks={setFavoritePacks}
                     />
                     : null }

                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            padding: '2px 4px',
                            fontSize: '10px',
                            color: 'teal',
                        }}
                    >
                        {renderCount}
                    </div>
                <Box my="2" h="60px" display="flex" alignItems="center" justifyContent={"center"}>
                    {formattedTitle.length > 10 ?
                    <Tooltip label={formattedTitle} fontSize={"16px"}>
                    <Link to={`/${addr}`} >
                        {formattedTitle.length > 10 ? `${formattedTitle.slice(0, 10)}...` : formattedTitle}
                        <span dangerouslySetInnerHTML={{ __html: tag }} />
                    </Link>
                    </Tooltip>
                    :
                    <Link to={`/${addr}`}>
                        {formattedTitle}<span dangerouslySetInnerHTML={{ __html: tag }} />
                    </Link>
                    }
                </Box>
                
                </Box>
                {/* ==== 유저정보 */}
                <Box 
                    h="20px"
                    w="100%"
                    fontSize={"sm"} 
                    color="lightgray"
                    mb="2"
                >
                    
                    <HStack justifyContent={"space-between"}>
                        <Text>
                            <FaExclamationCircle 
                                onMouseEnter={() => setShowCounts(true)} 
                                onMouseLeave={() => setShowCounts(false)} />
                        </Text>
                        <Text >
                            {creator.username} 
                        </Text>
 
                         {/* === mypack 에서 lock 버튼 보여주기*/}
                         {pubOpt === "n"  ?
                            nPackPage === "priv" || nPackPage==="fav" ?
                            <Icon as={FaLock} color="yellow.500" /> 
                            : <span> </span>
                        :  
                            nPackPage === "priv" || nPackPage==="fav" ? 
                            <FaLockOpen /> 
                            : <span></span>
                        }
                        <Text fontSize={"xs"}>
                        <Link to={`/${addr}/pd`} >
                            <FaRegSun /> {/* === pack detail */}
                        </Link>
                        </Text>
                    </HStack>
                        
                    <HStack
                        w="100%"
                        justifyContent={"space-evenly"}
                        fontSize={"10px"}
                    >
                        <HStack   >
                            <Text><FaAlignJustify /></Text>
                            <Text> {cateCount}</Text>
                        </HStack>
                        <HStack >
                            <Text><FaPollH /></Text>
                            <Text>{postCount}
                                <span dangerouslySetInnerHTML={{__html: isNewpost}}></span>
                            </Text>
                        </HStack>
                        <HStack >
                        <Text>{language}</Text>
                        </HStack>
                    </HStack>
                </Box>

                                    
            </VStack>
        </GridItem>
    </>
    )}
