// import commons
import { Box, Button, FormControl, VStack, HStack, Input, Text, useToast, Spacer, Flex, Center, Checkbox, Textarea, FormLabel, IconButton, } from "@chakra-ui/react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {client} from "../../index";
import useUser from "../../lib/useUser";
import { useQuery, useMutation } from "@tanstack/react-query";
import {  Link, useNavigate, useParams,  } from "react-router-dom";
// import apis
import { deleteNote, getNote, updateNote } from "../../apisntypes/noteapis";
import {  getComments } from "../../apisntypes/commentapis";
import { increaseRecom, unRecom, getMyRecom } from "../../apisntypes/noteapis";
import { doFav, unFav, getMyFav } from "../../apisntypes/noteapis";
import { getNPackAddr } from "../../apisntypes/packapis";
// types
import { INote } from "../../apisntypes/notetypes"; 
// import customs
import { customBgColor } from "../../css/customcolor";
import { formatDateTime } from "../../lib/Utils";
import DOMPurify from "dompurify";
// import icons
import { FaRegHeart, FaRegCommentDots, 
  FaRegEye, FaRegTrashAlt, FaRegThumbsUp, FaPencilAlt,  FaThumbsUp,
  FaHeart,
  FaLock} from "react-icons/fa";
// import components
import Comments from "../comment/Comments"
import CommentPost from "../comment/CommentPost";
import TopPanel from "../common/TopPanel";
import { INPack } from "../../apisntypes/packtypes";
import { RiOpenaiFill } from "react-icons/ri";
import { GroupPage } from "../pack/GroupPage";
import { GroupCheck } from "../pack/GroupCheck";
// import Adsense from "../common/Adsense";
import NoteImages from "./NoteImages";
import SearchWeb from "./SearchWeb";
import { newTag } from "../../lib/Utils";
import HtmlEditor from "./HtmlEditor";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

// ==== view post function

export default function NoteDetail()  {
  const [tag, setTag] = useState<string>(''); 
  const { user, userLoading, isLoggedIn } = useUser();
  const { addr, cate_pk, note_pk} = useParams();
  const contentRef = useRef(null);
  const cancelRef = useRef();
  const commentsRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  // const { isOpen, onOpen, onClose } = useDisclosure()

  const [pack_pk, setpack_pk] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
  const [editingMode, setEditingMode] = useState(true); // 
  const [isEditing, setIsEditing] = useState(false);
  // const [safeContent, setSafeContent] = useState("");
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [recommendCount, setRecommendCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImgs, setUploadedImgs] = useState([]);
  const [imageids, setImageids] = useState([]);
  // usequery
  const { isLoading:isLoadingComments, data: commentsData, error: commentsError } = useQuery<any>(
    ["comments", note_pk],
    getComments
  );
  
  const { isLoading, data, error } = useQuery<INote>({ queryKey: [`note`, note_pk], queryFn: getNote });
  const [isSecret, setIsSecret] = useState(data && data?.is_secret);

  useEffect(() => {
    setTag(newTag(data?.updated_at));
  },[data]);

  const [noteimageid, setNoteImageid] = useState(data?.noteimageid);
  const { isLoading: isLoadingMyRecom, data: myRecomData } = useQuery(['myRecom', parseInt(note_pk,10)], () => getMyRecom({pk:parseInt(note_pk,10)}), {
    enabled: isLoggedIn && !!note_pk, // Only run query if logged in and note_pk is valid
  });
  const [isRecommended, setIsRecommended] = useState(myRecomData); 

  const { isLoading: isLoadingMyFav, data: myFavData } = useQuery(['myFav', parseInt(note_pk,10)], () => getMyFav({pk:parseInt(note_pk,10)}), {
    enabled: isLoggedIn && !!note_pk,
  });
  
  const [isFaved, setIsFaved] = useState(myFavData); 

  const { isLoading: isLoadingNPack, data: nPackData } = useQuery<INPack>({
    queryKey: [`getNpackAddr`, addr],
    queryFn: getNPackAddr,
  });

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  useEffect(() => {
    if (myRecomData !== undefined) {
      setIsRecommended(myRecomData); // Update isRecommended when myRecomData is available
    }
  }, [myRecomData]);
  useEffect(() => {
    // Update the local state when isFaved changes from outside
    setIsFaved(myFavData);
  }, [myFavData]); // Re-run this effect whenever isFaved changes

  useEffect(() => {
    if (data) {
      setTitle(data.title || "");
      setContent(data.content || "");
      setNoteImageid(data.noteimageid || "");
      // Set other state values as needed
    }
  }, [data]);

  useEffect(() => {
    if (nPackData ) {
      setpack_pk(nPackData?.id);
    }
  }, [isLoadingNPack, nPackData, pack_pk]);
  
  useEffect(() => {
    setRecommendCount(data?.rec_count);
  }, [data]);

  const {register, handleSubmit:handleSubmitData, setValue,formState} = useForm<INote>({
    defaultValues: {
      pk : data?.pk ,
      title: data?.title ||"",
      content: data?.content || "",
    }
  });

  const deleteNoteMutation = useMutation((pk:number) => deleteNote(pk),{
    onSuccess: (data:INote) => {
      toast({
          status: "success",
          title: "Post deleted",
          position: "bottom-right",
      });
      if (parseInt(cate_pk,10) > 0) {
        // If cate_pk is greater than 0, navigate to the "allnote" route
        navigate(`/${addr}/cn/${cate_pk}`);
      } else {
        // If cate_pk is not greater than 0, navigate to the "catenote" route
        navigate(`/${addr}/0`);
      }
    },
    onError: (err: Error) => {
      toast({
          status: "error",
          title: "Error occurred",
          description: err.message,
          position: "bottom-right",
      });
    },
  });

  const updateNoteMutation = useMutation(
    ({ pk, title, content, noteimageid, imageids, imageURLs, is_secret }: { pk: number; title: string; content: string; noteimageid:string, imageids: Array<any>, imageURLs: Array<any>, is_secret:string,}) =>
      updateNote(pk, title, content, noteimageid, imageids, imageURLs, is_secret),
    {
      onSuccess: () => {
        toast({
          status: "success",
          title: "Note post updated",
          position: "bottom-right",
        });
        client.refetchQueries(["getNote"]);
        setIsEditing(false);
      },
      onError: (err: Error) => {
        toast({
          status: "error",
          title: "Error occurred",
          description: err.message,
          position: "bottom-right",
        });
      },
    }
  );



  const handleChange = (value: string) => {
    setValue('content', value, {shouldDirty: true});
    setValue('pk',parseInt(note_pk, 10));
    setContent(value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleSubmit = (event: React.FormEvent) => {
    
    const formData = new FormData();

    // Append basic note data
    formData.append('pk', note_pk);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('noteimageid', noteimageid);
    formData.append('imageids', JSON.stringify(imageids));
    formData.append('is_secret', String(isSecret));
    //   formData.forEach((value, key) => {
  //     console.log(`${key}: ${value}`);
  // });
  
    uploadedImgs.forEach((file, index) => {
      formData.append(`imageURLs[${index}]`, file);
  });

  updateNoteMutation.mutate({
    pk: parseInt(note_pk, 10),
    title: title,
    content: content,
    noteimageid: noteimageid,
    imageids: imageids,
    imageURLs: uploadedImgs,
    is_secret: String(isSecret),
  });
    client.refetchQueries(["getNote"]);
  };

  const onNoteDelete =  (pk) => {
    setIsDeleteDialogOpen(false);
    deleteNoteMutation.mutate(pk);
  }

  const handleTabKeyPress = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      contentRef.current.focus();
    }
  };

  const handleTextareaChange = (event) => {
      const userInput = event.target.value;
        // Use DOMPurify to sanitize the input
        const sanitizedContent = DOMPurify.sanitize(userInput, {
          ADD_TAGS: ['iframe'],  // Allow iframe tags
          ADD_ATTR: ['allowfullscreen', 'frameborder', 'src'],  // Allow specific attributes for iframe
          FORBID_TAGS: ['script'],  // Ensure scripts are forbidden
        });
        setContent(sanitizedContent);
        // setSafeContent(sanitizedContent);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ?  true : false ;
    setEditingMode(newValue);
  };


  const handleRecomClick = async (pk) => {
    try {
        const response = await increaseRecom({ pk });
        setRecommendCount(recommendCount + 1); // Update the local count
        setIsRecommended(true); // Update recommendation status
      
    } catch (error) {
      console.error('Error:', error.message); // Log any errors that occur during the API call
    }
  };

  const handleUnRecomClick = async (pk) => {
    try {
      const response = await unRecom({pk});
        setRecommendCount(recommendCount - 1); // Update the local count
        setIsRecommended(false);
    } catch (error) {
      console.log('Error:', error.message);
    }
  }

  const handleFavClick = async (pk) => {
    try {
        const response = await doFav({ pk });
        setIsFaved(true); // Update recommendation status
      
    } catch (error) {
      console.error('Error:', error.message); // Log any errors that occur during the API call
    }
  };

  const handleUnFavClick = async (pk) => {
    try {
      const response = await unFav({pk});
        setIsFaved(false);
    } catch (error) {
      console.log('Error:', error.message);
    }
  }

  const copyToClipboard = (text) => {
    // Create a temporary input element
    const tempInput = document.createElement('textarea');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    toast({
      title: 'Prompt Copied to Clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position:"bottom-right",
    });
    // Execute the copy command
    document.execCommand('copy');
    
    // Remove the temporary input element
    document.body.removeChild(tempInput);
  };

  const handleScrollToComment = () => {
    commentsRef.current.scrollIntoView();
  };


  const handleCancelEdit = () => {
    setIsEditing(false);
    setUploadedImgs([]); // Clear uploaded images on cancel
    setSelectedFiles([]); // Clear selected files on cancel
  };

  useEffect(() => {
    // Cleanup uploaded images when the component unmounts or when editing mode changes
    return () => {
      setUploadedImgs([]);
      setSelectedFiles([]);
    };
  }, [isEditing]);

  const handleImageInsert = (imgUrl) => {
    // Create an image HTML tag and insert it into the editor's content
    const imgTag = `<img src="${imgUrl}" alt="Image" />`;
    setContent((prevContent) => prevContent + imgTag);
    setValue('content', content + imgTag, {shouldDirty: true});
  };

  const handleScret = ( )=> {
    setIsSecret(!isSecret);
  }

  useEffect(() => {
    setIsSecret(isSecret);
  },[isSecret]);

  return (
    <>

     { isEditing ? 
    <>
    {/* ============================== 수정중일때 */}
    <Box 
      maxW={"container.lg"} 
      mx="auto" 
      p="4">
      
      <Checkbox
        id="editModeCheckbox"
        className="toggleCheckbox"
        onChange={handleCheckboxChange}
        isChecked={editingMode === true}
      >
        Html
      </Checkbox>
                <FormLabel>Title</FormLabel>
        <FormControl>
          <Input 
          autoFocus
          mb={5} 
          fontSize="lg" 
          onKeyDown={handleTabKeyPress} 
          {...register("title", {required:true})} 
          onChange={handleTitleChange}
          defaultValue={title}
          value={title}
          name="title" /> 
        </FormControl>
      <Box>
      <FormLabel>Content</FormLabel>
        <FormControl>
          {editingMode === true ?
          
          <HtmlEditor value={content} onChange={handleChange} 
          
          isImageStatus="post" 
          noteimageid={data?.noteimageid}
          uploadedImgs={uploadedImgs}
          setUploadedImgs={setUploadedImgs}
          imageids={imageids}
          setImageids={setImageids}
          onImageInsert={handleImageInsert}
          />
            :(
              <Textarea 
              fontSize={"xl"}
              style={{ 
                height: '350px', 
                whiteSpace:"pre-line",
                resize:"both",
                marginBottom: '30px' ,
              }}
              value={content} 
              onChange={handleTextareaChange} >
               
              </Textarea>
            )
          }
        </FormControl>
        <FormControl>
          <HStack mx="2" mt="4">
            <input type="checkbox" checked={isSecret}
              {...register('is_secret', {required:false})} 
              onChange={handleScret}
              />
            <FaLock size="12px" />
            </HStack>
        </FormControl>
        <FormControl>
            <Input type="hidden" name="pk" value={note_pk} />
        </FormControl>
      </Box>
      <Spacer height="100px" />
      <HStack justify={"flex-end"}>
          <Button 
                colorScheme={"red"} 
                size="lg" 
                w="50%"
                onClick={handleCancelEdit}
            > Cancel
            </Button>      
            <Button 
                type="submit"
                colorScheme={"green"} 
                size="lg" 
                w="50%"
                onClick={handleSubmit} 
            > Save 
            </Button>
      </HStack>
    </Box>
  </>
: // 수정이 아니고 보여주기
    <>
      <Box 
        maxW={"container.lg"} 
        mx="auto" 
        p={4}
        >
      <GroupCheck addr={addr} />
      <VStack mb="5" align="start">
        {/* ==============================category */}
          {/* <GroupPage addr={addr} /> */}
        <TopPanel 
            packTitle={nPackData?.title} 
            subTitle={data?.cate_pk.title} 
            addr={addr}
            cate_pk={parseInt(data?.cate_pk.pk,10)} />

          {/* ================= 제목 */}
          <HStack
          spacing={4} // Adjust the spacing between the elements
          justify="center" // Center the elements horizontally
          align="center" // Center the elements vertically
          mt={5} // Adjust the margin-top as needed
          >
            <Text mb={5} fontSize="3xl" p={4}>{title} </Text>
              {tag && (
              <span dangerouslySetInnerHTML={{ __html:tag }} />
              )}
             
              <Box>
              <SearchWeb kword={title} />
              </Box>
            {commentsData && commentsData.length > 0 ?
              <>
                <Link to="#comments" onClick={handleScrollToComment}>
                  <HStack>
                  <FaRegCommentDots />
                  <Text>
                      ({commentsData?.length})
                  </Text>
                  </HStack>
                </Link>
              </>
            : null }
          </HStack>
          {/* ================== infobox */}
          <Flex  p={4} rounded="md" w="100%" justify="space-between"
            {...customBgColor.customBgColorBlue}
            >   
            {/* ==== 유저 */}
            <Text fontSize="sm">{data?.creator.username}</Text>
            {/* ==== 글쓴 날자 */}
            <Text fontSize="sm">{formatDateTime(data?.updated_at)}</Text>
            
              {/* === 조회수 */}
            <HStack fontSize={"xs"}>
              <FaRegEye />
              <Text>
                ({data?.view_count})
              </Text>
            </HStack>
            {/* ==== 추천수 */}
            <HStack fontSize={"xs"}>
              {isLoggedIn && isRecommended ? //이미 추천되어 있다면
                <FaThumbsUp
                onClick={() =>handleUnRecomClick(note_pk)} 
                style={{ cursor: "pointer" }}
                />
                : // 추천안되어있다면
                <FaRegThumbsUp 
                onClick={() => {
                  if (isLoggedIn) {
                      handleRecomClick(note_pk);
                  }}}
                style={{ cursor: "pointer" }}
                /> 
              }
              <Text>
              ({recommendCount})
              </Text>
            </HStack>
            {/* === favorite */}
            <HStack fontSize={"xs"} 
              style={{ cursor: "pointer" }}
              >
                {isLoggedIn && isFaved ?
                <FaHeart 
                // title="true"
                onClick={() => {
                  if (isLoggedIn) {
                      handleUnFavClick(note_pk);
                  }}}
                />: // fav 안했을 때
                <FaRegHeart 
                // title="false"
                onClick={() => {
                  if (isLoggedIn) {
                      handleFavClick(note_pk);
                  }}}
                style={{ cursor: "pointer" }}
                />
                }
            </HStack>
          </Flex>
          {/* ==============================내용 */}
          <Box  w="98%" mx="auto" px="1px" my="4">
            {isLoading ?
            <>
            <CenteredCircularProgress />
            </>
            :<Text fontSize={"xl"}
              style={{ minHeight: '350px',  whiteSpace:"pre-line"}}
              >
            <span dangerouslySetInnerHTML={{__html:content }} />
            </Text>
            }
          </Box>
          {/* ==============================수정 삭제 메뉴 */}
          <Box p="5" w="100%" mx="auto">
            <HStack  display="flex" justifyContent={"flex-end"}>
            {isLoggedIn && data?.creator.username === user?.username  ? (
              <>
                <IconButton
                  size="sm"
                  variant={"ghost"}
                  aria-label="Edit"
                  icon={<FaPencilAlt />}
                  mr="2"
                  onClick={() => {
                    setIsEditing(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
                <IconButton
                  size="sm"
                  variant={"ghost"}
                  aria-label="Delete"
                  icon={<FaRegTrashAlt />}
                  onClick={() => setIsDeleteDialogOpen(true)}
                />
              </>
            ):null}
            {data?.prompt && data?.prompt.length>0 ? 
                <IconButton
                  size="sm"
                  variant={"ghost"}
                  aria-label="Copy prompt"
                  icon={<RiOpenaiFill />}
                  title={data?.prompt}
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyToClipboard(data?.prompt)}
                />
                :null }
            </HStack>
          </Box>
         
          {/* ==============================코멘트 */}
          <Box p="2" w="100%" id="comments" ref={commentsRef}>
            { isLoggedIn ? 
            <>
            <CommentPost note_id={note_pk} cate_pk={cate_pk} pack_pk={pack_pk} addr={addr} original_creator={data?.creator} />
            </> : null}
            <Comments note_id={note_pk} cate_pk={cate_pk} pack_pk={pack_pk} addr={addr} />
          </Box>
          {/* ==============================코멘트 */}
      </VStack>
      </Box> 
      </>
  }   
    {/* ==============================삭제 다이얼로그 박스 */}
        <AlertDialog
              isOpen={isDeleteDialogOpen}
              leastDestructiveRef={cancelRef}
              onClose={onCloseDeleteDialog}
            >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Note Post
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this note post?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} 
                onClick={() => {
                  onCloseDeleteDialog();
                  window.scrollTo({top:0, behavior:'smooth'});
                  }}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => onNoteDelete(data?.pk)} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
  </>
  )
}