import React, { useEffect, useState } from "react";
import useUser from "../../lib/useUser";
import { Badge, Button, Container, Heading, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { cancelGroup, checkPackGroup, getNPackAddr } from "../../apisntypes/packapis";
import { Link, useParams } from "react-router-dom";
import { applyGroup, openGroup, closeGroup } from "../../apisntypes/packapis";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query"; 
import { GroupAdminPage } from "../pack/GroupAdminPage";

export const GroupPage = () => {
    const {addr} = useParams();
    const toast = useToast();
    const queryClient = useQueryClient();
    const {userLoading, user, isLoggedIn} = useUser();
    const { data, isLoading, isError } = useQuery(['checkGroup',addr], () => checkPackGroup(addr));
    const {data:nPackData,isLoading:isLoadingNPack, isError:isErrorNPack} = useQuery(['nPackData', addr],  getNPackAddr);
    const [isAppliedStatus, setIsAppliedStatus] = useState(false);
    const [isAcceptedStatus, setIsAcceptedStatus] = useState(false);
    const [isGroupOpen, setIsGroupOpen] = useState(nPackData && nPackData['isGroupOpen']);
    const isGroupAdmin = nPackData && nPackData['creator']['username'] === user.username;
    
    const handleGroupApply = async (addr) => {
        try {
            const response = await applyGroup(addr);
            queryClient.invalidateQueries(["applyGroup"]);
            setIsAppliedStatus(true);
            toast({
                title: "Success",
                description: "Applied to the group .",
                status: "success",
                duration: 3000, // Display duration in milliseconds
                isClosable: true, // Allow the user to close the toast manually
            });
            
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to apply to the group.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleCancelGroup = async (addr) => {
        try {
            const response = await cancelGroup(addr);
            queryClient.invalidateQueries(["cancelGroup"]);
            setIsAppliedStatus(false);
            toast({
                title: "Success",
                description: "Group membership cancelled",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to cancel group membership.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleOpenGroup = async (addr) => {
        try {
            const response = await openGroup(addr);
            queryClient.invalidateQueries(["openGroup"]);
            setIsGroupOpen(true);
            toast({
                title: "Success",
                description: "The group is open",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to open group.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        }
    }

    const handleCloseGroup = async (addr) => {
        try {
            const response = await closeGroup(addr);
            queryClient.invalidateQueries(["closeGroup"]);
            setIsGroupOpen(false);
            toast({
                title: "Success",
                description: "The group is closed",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to close group.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        }
    }
    
    useEffect(() => {
        setIsGroupOpen(nPackData && nPackData['isGroupOpen']);
        if (data){
        setIsAppliedStatus(data && data['isApplied']);
        setIsAcceptedStatus(data && data['isAccepted']);
        }
    }, [nPackData, data]);
    
    return (
        <>
        <Container m="10">
        {!isLoading && data.isGroup  ? 
            <>
                <Heading my="4">{nPackData?.title}</Heading>
                <Link to={`/${addr}`}>
                    <Text my="4" ml="4">
                        https://drimnotes.com/{nPackData?.addr}
                    </Text>
                </Link>
                <Text>
                    <>
                        {isGroupAdmin ?  
                        <>
                            <Badge m="4">
                                You are group Admin 
                            </Badge>
                            {isGroupOpen ? // 어드민 기능
                            <>
                            <Text m="4">Group is open</Text>
                            <Button size="xs" m="4" onClick={()=>handleCloseGroup(addr)} colorScheme="red">Close group</Button>
                            </>
                            :
                            <>
                            <Text m="4">Group is closed</Text>
                            <Button size="xs" m="4" onClick={()=>handleOpenGroup(addr)} colorScheme="green">Open group</Button>
                            </>
                            }
                            <GroupAdminPage addr={addr} />
                        </> 
                        :   
                        <>
                            {data && isAppliedStatus && isAcceptedStatus  ? // 어플라이,억셉트
                                <>
                                <Text m="4">
                                    Your a group Member
                                </Text>
                                </>
                                :null}
                            
                            {data && isAppliedStatus && !isAcceptedStatus ?  // 어플라이 온리
                                <>
                                    <Text m="4">You are applied.</Text>
                                </>
                                :null}
                            
                            {data && !isAppliedStatus && !isAcceptedStatus ? // 어플라이, 억셉트 둘다 노
                            <>
                            <Text m="4">
                                You are not a group member
                            </Text>
                            </>:null}
                            
                            {isGroupOpen && !isGroupAdmin ? //그룹이 오픈되어 있으면 신청 취소 가능
                                <>
                                {data && isAppliedStatus ? 
                                    <>
                                        <Button size="xs" m="4" colorScheme="red" onClick={() => handleCancelGroup(addr)}>Cancel</Button>
                                        <Link to="/groups">
                                        <Button size="xs" m="4" colorScheme="blue" >Home</Button>
                                        </Link>
                                    </>
                                    :
                                    <>
                                        <Button size="xs" m="4" colorScheme="green" onClick={() => handleGroupApply(addr)}>Apply</Button>
                                        <Link to="/">
                                        <Button size="xs" m="4" colorScheme="blue">Home</Button>
                                        </Link>
                                    </>
                                }
                                </>:
                                <>
                                    <Text m="4">
                                        Group is closed
                                    </Text>
                                    <Link to={"/"}>
                                    <Button m="4" size="xs">Home</Button>
                                    </Link>
                                </>
                            }
                        </> 
                        }
                    </>
                </Text>
            </>
            :null }
        </Container>
        </>
    )
};