import { IComment, IPostComment, IReplyComment } from "./commenttypes";
import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})


export const getComments = ({queryKey}:QueryFunctionContext) => {
    const [_, note_pk] = queryKey;
    return instance.get(`comments/${note_pk}`, {
        headers: {
            'X-CSRFToken': Cookie.get("csrftoken") || "",
        },
    })
    .then(response => response.data)
    }; 

export const editComment = (comment_id:number, editedComment:string) => {
    return instance.put(`comments/edit/${comment_id}`, 
        {comment_id, editedComment},
        {
        headers: {
            'X-CSRFToken': Cookie.get("csrftoken") || "",
        },
    })
    .then(response => response.data)
    // .catch(error => {
    //     throw new Error(error.response?.data?.message || "Error editing comment");
    // })
    };  

export const postComment = (variables: IPostComment) => {
    return instance.post(
        `comments/post/`,
        variables,
        {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
        })
    .then((response) => response.data);
    }

export const replyComment = (variables: IReplyComment) => {
        return instance.post(
        `comments/reply/`,
        variables,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        })
    .then((response) => response.data);
    }
    
export const commentDelete =  (comment_pk:number) => {
    return instance.delete(
        `comments/delete/${comment_pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        })
    .then(response =>  response.data)
}

export const recomComment = (comment_id:number) => {
    return instance.post(
        `comments/recom/${comment_id}`,
        {},
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
    )
    .then(response => response.data)
}

export const getRecomComment = (comment_id:number) => {
    return instance.get(
        `comments/getrecom/${comment_id}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
    )
    .then(response => response.data)
}

export const getCommentAlarm = () => {
    return instance.get(
        `comments/alarm`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
    )
    .then(response => response.data)
}

export const updateCommentAlarm = (note_pk) => {
    return instance.get(
        `comments/updatealarm/${note_pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
    )
    .then(response => response.data)
}

