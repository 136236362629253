import { Box, Text, Link } from '@chakra-ui/react';
import { customBgColor } from "../../css/customcolor";

const Footer = () => {
    return (
      <Box p={4} 
      my={"40"}
      textAlign="center">
        <Text>&copy; 2023 Drimnotes.com All rights reserved.</Text>
        <Link href="/pp">Privacy Policy</Link>
      </Box>
    );
  };
  
  export default Footer;