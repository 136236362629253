import { Box, Button, HStack, useDisclosure, useToast  } from "@chakra-ui/react";
import { useQuery,useMutation } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom"
import { ICategory } from "../../apisntypes/categorytypes"; 
import { RxCornerBottomLeft } from "react-icons/rx";
import {AiOutlineCheck} from "react-icons/ai";
import { postAutoNote } from "../../apisntypes/commonapis";
import { client } from "../..";
import { useState } from "react";
import { cusColorScheme, grayColorScheme, pinkColorScheme, tealColorScheme } from "../../css/customTheme";
import { newTag } from "../../lib/Utils";

export default function Category ({
    title,
    addr,
    creator,
    upperPk,
    pk,
    prompt,
    postCount,
    cLength,
    updated_at,
    depth,
    pack_pk,
    isMoving,
}:ICategory) {

    const tag = newTag(updated_at);

    const [isButtonActive, setButtonActive] = useState(true);

    const toast = useToast();

    const postAutoNoteMutation = useMutation(postAutoNote, {
        onSuccess: () => {
            toast({
                status: "success",
                title: "Category posted",
                position: "bottom-right",
            });
        },
    });

    const handleAutoCreate = () => {
  // Check if the button is already clicked today
  const lastClickTimestamp = localStorage.getItem('lastClickTimestamp');
  const today = new Date().toLocaleDateString();

  if (lastClickTimestamp === today) {
    // Button already clicked today, do nothing
    return;
  }

  // Perform your button click logic here

  // Update local storage with the current date
  localStorage.setItem('lastClickTimestamp', today);

  // Disable the button
  setButtonActive(false);
  postAutoNoteMutation.mutate();
      }

    return (
        <Box fontSize={"sm"}>
           <HStack pr="2">
                <Link to={`/${addr}/cn/${pk}`}>
                    <HStack>
                        {depth >0 ? 
                        <Box ml={depth*4} >
                        <RxCornerBottomLeft /> 
                        </Box>
                        : null}
                    <Box fontSize={"md"} my="1">
                        {title}
                        <span dangerouslySetInnerHTML={{__html:tag}} />
                    </Box>
                    {/* ===== prompt 가 있을 때 */}
                    {/* {prompt.length>10 ?
                    <Box>
                        <Button 
                        onClick={handleAutoCreate} 
                        disabled={!isButtonActive}
                        colorScheme={grayColorScheme}
                        size="sm"
                        >p</Button>
                    </Box>
                     : null }  */}

                    <Box>
                        { postCount > 0 ? <>({postCount})</> : null }
                    </Box> 
                    </HStack>
                </Link>
            
            </HStack>  
        </Box>

    )
}

