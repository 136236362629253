import { customBgColor } from "./customcolor";


export const embossedBox = {
    backgroundColor: "#2b2a2a", // Use camelCase for backgroundColor
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", // Use camelCase for boxShadow
    padding: "16px",
    borderRadius: "8px", // Use camelCase for borderRadius
    
  };


export const resFontSize = {
        base: "24px",
        sm: "28px",
        md: "38px",
        lg: "42px",
        xl: "48px",
      }

export const boxOrange = {
  mx: 'auto',
  my: 'auto',
  h: '20vh',
  p: 4,
  borderRadius: 'md',
  boxShadow: 'md',
  ...customBgColor.customBgColorOrange,
  fontSize: 'lg',
}

export const boxBlue = {
  mx: 'auto',
  my: 'auto',
  h: '20vh',
  p: 4,
  borderRadius: 'md',
  boxShadow: 'md',
  ...customBgColor.customBgColorBlue,
  fontSize: 'lg',
}
export const boxLightBlue = {
  mx: 'auto',
  my: 'auto',
  h: '20vh',
  p: 4,
  borderRadius: 'md',
  boxShadow: 'md',
  ...customBgColor.customBgColorLightBlue,
  fontSize: 'lg',
}

export const containerStyle = {
  marginTop: "5px",
  padding: "20px",
};
