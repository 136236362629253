import { Box, Container, Heading, Text, Textarea } from "@chakra-ui/react";
import React from "react";

export const PrivacyPolicy = () => {
    return (
        <Box maxW="800px" mx="auto" p="4">
          <Heading as="h1" fontSize="2xl" mb="4">Privacy Policy</Heading>
    
          <Text whiteSpace="pre-line">
            This Privacy Policy describes how Drimnotes ("we", "us", or "our") collects, uses, and discloses personal information when you use our website drimnotes.com (the "Site").
            <br /><br />
            Information We Collect
            <br /><br />
            Personal Information: When you visit our Site, we may collect certain personal information, such as your name, email address, and IP address.
            <br />
            Usage Information: We may also collect information about how you interact with our Site, such as the pages you visit and the actions you take.
            <br /><br />
            How We Use Your Information
            <br /><br />
            We may use the personal information we collect for the following purposes:
            <br />
            To provide and maintain our Site;
            <br />
            To communicate with you about your account or inquiries;
            <br />
            To analyze how our Site is used and improve user experience;
            <br />
            To comply with legal obligations.
            <br /><br />
            Sharing Your Information
            <br />
            <br />
            We may share your personal information with third-party service providers who assist us in operating our Site or providing services to you. We may also disclose your information in response to legal requests or to protect our rights or the rights of others.
            <br /><br />
            Cookies and Tracking Technologies
            <br /><br />
            We may use cookies and similar tracking technologies to track your activity on our Site and personalize your experience. You can manage your cookie preferences through your browser settings.
            <br /><br />
            Your Rights
            <br /><br />
            You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain processing activities. Please contact us if you would like to exercise these rights.
            <br /><br />
            Data Security
            <br /><br />
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
            <br />
            Changes to This Policy
            <br />
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "last updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any changes.
            <br /><br />
            Contact Us
            <br /><br />
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at drimnotes@gmail.com.
            <br />
          </Text>
        </Box>
      );
}