import {
    Text,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    HStack,
    IconButton,
    Checkbox,
    RadioGroup,
    Radio,
  } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react';
import { Button, Input, Box, Icon } from '@chakra-ui/react';
import { useQuery } from "@tanstack/react-query";
import React from 'react';
import { Link } from 'react-router-dom';
//import apis
import { getRecentCategories, getRecentComments, getRecentNotes } from '../../apisntypes/commonapis';
//import types
import { INPack } from '../../apisntypes/packtypes';
import { INote } from '../../apisntypes/notetypes';
import { ICategory } from '../../apisntypes/categorytypes';
import { IComment } from '../../apisntypes/commenttypes';
// import custom
import { customBgColor } from '../../css/customcolor';
import { formatDay, isToday, isNewPostWithin24Hours, getFormattedTime } from '../../lib/Utils';
// import icons
import { FaNewspaper,FaEllipsisV } from 'react-icons/fa';

const Summary = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    // categories
    const {isLoading:isRecentCateLoading, data:recentCateData} = useQuery<ICategory[]>([`recentCategories`], getRecentCategories) ;
    // notes
    const {isLoading:isRecentNoteLoading, data:recentNoteData} = useQuery<INote[]>([`recentNotes`], getRecentNotes) ;
    // comments
    const {isLoading:isRecentCommentLoading, data:recentCommentData} = useQuery<IComment[]>([`recentComments`], getRecentComments) ;

    const handleRadioChange = () => {
        console.log('radio');
    }
    return (
        <>
           {/* <IconButton 
            boxSize={"2em"}
            onClick={onOpen} 
            ref={btnRef} 
            colorScheme='blue'
            aria-label='Summary'
            icon={<FaEllipsisV fontSize={"0.8em"}/>} /> */}
           <Button 
            colorScheme='blue'
            onClick={onOpen} 
            ><FaEllipsisV />
           </Button>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Summary</DrawerHeader>

            <DrawerBody>
                {/* <RadioGroup value={"value"} onChange={handleRadioChange}>
                    <HStack w="100%">
                        <Box w="50%" justifyContent={"center"} textAlign={"center"}>
                            <Radio value="all">
                            <Text>all</Text> 
                            </Radio>
                        </Box>
                        <Box w="50%" justifyContent={"center"} textAlign={"center"}>
                            <Radio value="pack">
                            <Text>in this pack</Text>
                            </Radio>
                        </Box>
                </HStack>
                </RadioGroup> */}
               <Text 
               w="100%" 
               p="2"
               rounded={"4"}
               {...customBgColor.customBgColorBlue}
               > 
                Recent Categories</Text>
               <Box my="4">
                {!isRecentCateLoading && recentCateData && recentCateData.length > 0 ?
                    Object.values(recentCateData).map((cate, index) => (
                        <React.Fragment key={index}>
                        <Link to={`/${cate.addr}/cn/${cate.pk}`} onClick={onClose}>
                            <HStack justifyContent={"space-between"}>
                            <Text fontSize={"14px"}>
                            {cate.title.length > 20 ? cate.title.slice(0, 17) + '...' : cate.title}
                            </Text>
                            <Text fontSize={"12px"}>
                                {isNewPostWithin24Hours(new Date(cate.updated_at)) ? 
                                    <>
                                    <span style={{color: "red", fontSize:"12px"}}>
                                        {formatDay(cate.updated_at)}
                                    </span>
                                    </>
                                    :
                                    <>
                                    {formatDay(cate.updated_at)}
                                    </>
                                }
                            </Text>
                            </HStack>
                        </Link>
                        </React.Fragment>
                    ))
                : null}
               </Box>
               <Text
               w="100%" 
               p="2"
               rounded={"4"}
               {...customBgColor.customBgColorBlue}
               >
                Recent Post</Text>
               <Box my="4">
                {!isRecentNoteLoading && recentNoteData && recentNoteData.length > 0 ?
                    Object.values(recentNoteData).map((note, index) => (
                        <React.Fragment key={index}>
                            <Link to={`/${note.addr}/nd/${note.cate_pk.pk}/${note.pk}`} onClick={onClose}>
                            <HStack justifyContent={"space-between"}>
                                <Text fontSize={"14px"}>
                                {note.title.length > 20 ? note.title.slice(0, 15) + '...' : note.title}

                                    </Text>
                                <Text fontSize={"12px"}>
                                {isNewPostWithin24Hours(new Date(note.updated_at)) ? 
                                    <>
                                    <span style={{color: "red"}}>
                                        {formatDay(note.updated_at)}
                                    </span>
                                    </>
                                    :
                                    <>
                                    {formatDay(note.updated_at)}
                                    </>
                                }
                                </Text>
                            </HStack>
                            </Link>
                        </React.Fragment>
                    ))
                : null}
               </Box>
               <Text
               w="100%" 
               p="2"
               rounded={"4"}
               {...customBgColor.customBgColorBlue}
               >
                Recent Comments</Text>
               <Box my="4">
                {!isRecentCommentLoading && recentCommentData && recentCommentData.length > 0 ?
                    Object.values(recentCommentData).map((comment, index) => (
                        <React.Fragment key={index}>
                            <Link to={`/${comment.addr}/nd/${comment.cate_pk}/${comment.note_pk}`} onClick={onClose}>
                            <HStack justifyContent={"space-between"} >
                            <Text fontSize={"14px"}>
                                {comment.comment.length > 16 ? comment.comment.slice(0, 15) + '...' : comment.comment}
                                </Text>
                                <Text fontSize={"12px"}>
                                {isNewPostWithin24Hours(new Date(comment.updated_at)) ? 
                                    <>
                                    <span style={{color: "red"}}>
                                        {formatDay(comment.updated_at)}
                                    </span>
                                    </>
                                    :
                                    <>
                                    {formatDay(comment.updated_at)}
                                    </>
                                }
                                </Text>
                            </HStack>
                            </Link>
                        </React.Fragment>
                    ))
                : null}
               </Box>
            </DrawerBody>

            <DrawerFooter>

            </DrawerFooter>
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default Summary;
                        