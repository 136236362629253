import dayjs from "dayjs";
import { useState, useEffect } from "react";
import DOMPurify from 'dompurify';

export const formatDay = (date) => {
  const userLanguage = navigator.language;
  const isKorean = userLanguage === "ko-KR"; 

if (isKorean) {
  return dayjs(date).format("MM-DD");
} else {
  return dayjs(date).format("MM-DD");
}
};

export const formatDate = (date) => {
    const userLanguage = navigator.language;
    const isKorean = userLanguage === "ko-KR"; 

  if (isKorean) {
    return dayjs(date).format("YY-MM-DD");
  } else {
    return dayjs(date).format("MMM-DD-YY");
  }
};

export const formatDateTime = (date) => {
    const userLanguage = navigator.language;
    const isKorean = userLanguage === "ko-KR"; 

  if (isKorean) {
    return dayjs(date).format("YY-MM-DD HH:mm:ss");
  } else {
    return dayjs(date).format("MM-DD-YY HH:mm:ss");
  }
};

export const isToday = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return false; // Handle cases where date is not a valid Date object
  }

  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const getFormattedTime = (date) => {
  const updatedAt = new Date(date);
  const hours = updatedAt.getHours().toString().padStart(2, '0');
  const minutes = updatedAt.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

// export const isNewPostWithin24Hours = (postDate) => {
//   const currentDate = new Date();
//   const timeDifference = +currentDate - +postDate; // Explicitly cast to number
//   return timeDifference <= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
// };

export function isNewPostWithin24Hours(updatedDate) {
  const currentDateTime = new Date();
  const twentyFourHoursAgo = new Date(currentDateTime.getTime() - 24 * 60 * 60 * 1000);
  return updatedDate > twentyFourHoursAgo;
}

// export function isNewPostWithin24Hours(updatedAt) {
//   const now = new Date(); // Current date and time
//   const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000); // 24 hours ago

//   console.log('Now:', now);
//   console.log('Updated At:', updatedAt);
//   console.log('24 Hours Ago:', twentyFourHoursAgo);

//   return updatedAt > twentyFourHoursAgo;
// }
export function formatTitle(title, searchStr, color) {
  // If searchStr is empty or not found in title, return the original title
  if (!searchStr || !title.toLowerCase().includes(searchStr.toLowerCase())) {
    return title;
  }

  // Create a regular expression to match the searchStr with case-insensitivity
  const regex = new RegExp(`(${searchStr})`, 'gi');

  // Use String.prototype.split() to split the title into parts based on the searchStr
  const parts = title.split(regex);

  // Use .map() to wrap matched text in <span> with inline style and leave other text as-is
  const formattedTitle = parts.map((part, index) => {
    if (index % 2 === 1) {
      // Apply inline style to matched text
      return `<span style="color: ${color};">${part}</span>`;
    }
    // Keep other text as-is
    return part;
  });

  // Join the parts back together into a single string
  return formattedTitle.join('');
}


export const extractImageUrls = (content) => {
  const imgRegex = /<img.*?src=['"](.*?)['"].*?>/g;
  const matches = content.match(imgRegex) || [];
  return matches.map((match) => {
    const srcRegex = /src=['"](.*?)['"]/;
    const srcMatch = match.match(srcRegex);
    return srcMatch ? srcMatch[1] : null;
  });
};

export const extractFirstImageUrl = (content) => {
  const imgRegex = /<img.*?src=['"](.*?)['"].*?>/i; // Use 'i' flag to match only the first image
  const match = content.match(imgRegex);
  return match ? match[1] : null;
};

export const useIsPhone = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 720);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 720);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs once when the component mounts

  return isSmallScreen;
};

export const convertUrlsToLinks = (text) => {
  // Convert URLs to links
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const withLinks = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
  
  // Convert newline characters to <br /> tags
  const withLineBreaks = withLinks.replace(/\n/g, '<br />');
  
  return withLineBreaks;
};

export const formTitle = (title) => {
  const maxLength = 9; // Adjust the max length as needed
  if (title.length > maxLength) {
    return title.slice(0, maxLength) + "...\n" + title.slice(maxLength);
  }
  return title;
};


export function newTag( updatedDate:string) {
  const updatedAtDate = new Date(updatedDate);
  const currentDateTime = new Date();
  const twentyFourHoursAgo = new Date(currentDateTime.getTime() - 24 * 60 * 60 * 1000);
  let htmlContent = "";

  if (updatedAtDate > twentyFourHoursAgo) {
    htmlContent = '<span style="display: inline-block; background-color: red; color: white; border-radius: 30%; width: 20px; height: 15px; text-align: center; line-height: 15px; font-size:10px; margin-left: 5px;"><strong>new</strong></span>'; 
  } else {
    htmlContent = ''; // gray 'x' string with inline style
  }

  return (
     htmlContent
  );
}
export function newTag2(is_newpost: boolean) {
  let htmlContent = "";

  if (is_newpost) {
    htmlContent = '<span style="display: inline-block; background-color: red; color: white; border-radius: 50%; width: 5px; height: 5px; text-align: center; line-height: 5px; font-size:5px; margin-left: 5px;"><strong></strong></span>';
  } else {
    htmlContent = ''; // gray 'x' string with inline style
  }

  return htmlContent;
}

export function newTagBoolean(value) {
  let htmlContent = "";
  if (value === false) {
    htmlContent = '<span style="display: inline-block; background-color: red; color: gray; border-radius: 30%; width: 15px; height: 15px; text-align: center; line-height: 15px; font-size:8px; margin-left: 5px;">new</span>'; 
    // htmlContent = '<span style="color: red; margin-left: 5px;">new</span>'; // red 'N' string with inline style  
  } else {
    htmlContent = "";
  }
  return (
    htmlContent
  );
}