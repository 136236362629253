import dayjs from 'dayjs';
import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Text, 
  Textarea,
  useToast,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/react";
import { client } from '../../index';
import useUser from '../../lib/useUser';
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
// import icons
import { FaRegHeart, FaRegThumbsUp, FaReply, FaThumbsUp } from "react-icons/fa";
import {FaRegTrashAlt, FaPencilAlt, FaPlus} from "react-icons/fa";
// import apis
import { getCommentAlarm } from '../../apisntypes/commentapis';
import {  getComments, editComment, 
  commentDelete, replyComment, recomComment, getRecomComment } from "../../apisntypes/commentapis";
// import types
import { IEditComment, IGetComment, IReplyComment } from '../../apisntypes/commenttypes'; 
import { formatDateTime, newTag } from '../../lib/Utils';
import { convertUrlsToLinks } from '../../lib/Utils';

export default function Comments({ note_id, cate_pk, pack_pk, addr }) {
  const {user, userLoading} = useUser();
  const isLoggedIn = !!user;
  const [imageUrl, setImageUrl] = useState('');
  const cancelRef = useRef();
  const toast = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // New state for edit modal
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
  const [comment_idToDelete, setcomment_idToDelete] = useState(0);
  const [comment_idToEdit, setcomment_idToEdit] = useState(0); // New state to store the comment ID being edited
  const [comment_idReply, setcomment_idReply] = useState(0);
  const [commentContent, setCommentContent] = useState("");
  const [editedComment, setEditedComment] = useState(''); // New state to store the edited comment
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [originalCreator, setOriginalCreator] = useState("");
  const [comment, setComment] = useState("");

  // const {comments, setComments} = useState(data);
  const commentQuery = useQuery<IGetComment>(
    [`comments`,parseInt(note_id,10)],
     getComments, 
     {staleTime: 0}
     );
    //  console.log(commentQuery.data);
//===============================
const { isLoading:commentAlarmLoading, data:commentAlarmData, refetch:commentAlarmRefech } = useQuery(
  [`getAlarms`],
  () => getCommentAlarm(),
  {
    enabled: !!user?.username, // Only run the query if username is available
  }
);

const deleteCommentMutation = useMutation((comment_id:number) => commentDelete(comment_id),{
  onSuccess: (data:IGetComment) => {
    toast({
        status: "success",
        title: "Comment deleted",
        position: "bottom-right",
    });
     client.refetchQueries(['getNote','commentDelete','getComments']);
     client.invalidateQueries(['comments', parseInt(note_id, 10)]);
  },
  onError: (err: Error) => {
    toast({
        status: "error",
        title: "Error occurred",
        description: err.message,
        position: "bottom-right",
    });
 },
  
});
//==========================================
const editCommentMutation = useMutation(
  ({ comment_id, editedComment }: { comment_id: number; editedComment: string }) =>
    editComment(comment_id, editedComment),
  {
    onSuccess: (data: IEditComment) => {
      toast({
        status: "success",
        title: "Comment Edited",
        position: "bottom-right",
      });
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: "Editing Error",
        description: err.message,
        position: "bottom-right",
      });
    },
  }
);

const recomCommentMutation = useMutation(
  async (comment_id:number) => {
      await recomComment(comment_id); 
    },
  {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Recommended",
        position: "bottom-right",
      });
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: "Recommend Error",
        position: "bottom-right",
      });
    }
  }
);

//===============================post mutation
const replyMutation = useMutation(replyComment, {
  onSuccess: (data) => {
    const repplyData = data as IReplyComment;
    toast({
      status: "success",
      title: "Comment posted",
      position: "bottom-right",
    });
    setComment('');
    client.invalidateQueries(['comments', parseInt(note_id, 10)]);
    client.invalidateQueries(['recentComments', parseInt(note_id, 10)]);
  },
  onError: (error) => {
    toast({
      status: "error",
      title: "Error occurred",
      //description: error.message,
      position: "bottom-right",
    });
  },
});



const handleDelete = (comment_idToDelete) => {
    deleteCommentMutation.mutate(comment_idToDelete);
    setIsDeleteDialogOpen(false);
    commentAlarmRefech();
};

const {register, handleSubmit:handleSubmitData, watch, setValue, formState } = useForm({
  defaultValues: {
    comment:"",
  }
});

const openEditModal = async (comment_id) => {
  setIsEditModalOpen(true);
  setcomment_idToEdit(comment_id);
  // console.log(comment_id);
  if (commentQuery.data) {
    // Typecast commentQuery.data as an array of comments to resolve TypeScript error
    const commentsArray = Array.isArray(commentQuery.data) ? commentQuery.data : [commentQuery.data]; 
    // console.log(commentsArray);
    // Find the comment by its ID in the commentsArray
    const comment = commentsArray.find(comment => comment.id === comment_id);
    // console.log(comment.comment);
    if (comment) {
      setEditedComment(comment.comment);
      setTimeout(() => {
        document.getElementById("editcomment_id").focus();
      }, 0); // Use setTimeout to execute this after the state update
      // console.log(editedComment);
    } else {
      // Handle case where comment with given ID is not found
      // For example, set a default value for editedComment
      setEditedComment("");
    }
  } else {
    // Handle case where commentQuery.data is null or undefined
    setEditedComment(""); 
  }
  // console.log(editedComment);
  
}

const onCloseEditModal = () => {
  setIsEditModalOpen(false);
  // Clear the edited comment state
  setEditedComment('');
};

const onSaveEdit = async (comment_id: number, editedComment:string) => {
  await editCommentMutation.mutateAsync({ comment_id, editedComment });
  setIsEditModalOpen(false);
  commentQuery.refetch();
  } 


const confirmDelete = (comment_id) => {
  setIsDeleteDialogOpen(true);
  setcomment_idToDelete(comment_id);
  
};

const openReplyModal = (comment_id) => {

  setcomment_idReply(comment_id);
  setIsReplyModalOpen(true);
  if (commentQuery.data) {
    // Typecast commentQuery.data as an array of comments to resolve TypeScript error
    const commentsArray = Array.isArray(commentQuery.data) ? commentQuery.data : [commentQuery.data];
    
    // Find the comment by its ID in the commentsArray
    const comment = commentsArray.find(comment => comment.id === comment_id);
    
    if (comment) {
      setCommentContent(comment.comment);
      setOriginalCreator(comment.creator.username);

      setTimeout(() => {
        const replyContentElement = document.getElementById("replyContentId");
        if (replyContentElement) {
          replyContentElement.focus();
        }
      }, 0); // Use setTimeout to execute this after the state update
    } else {
      // Handle case where comment with given ID is not found
      // For example, set a default value for commentContent
      setCommentContent(''); // Set default value
    }
  } else {
    // Handle case where commentQuery.data is null or undefined
    setCommentContent(''); // Set default value
  }
};

const closeReplyModal = () => {
  
  setIsReplyModalOpen(false);
  // Clear the reply content when modal is closed
  setReplyContent('');
};

const handleRecomClick = (comment_id) => {
  recomCommentMutation.mutate(comment_id);
}

const handleReplySubmit = () => {
  if (!replyContent.trim()) {
    toast({
      title: 'Reply content cannot be empty',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
    return;
  }
  const variables:IReplyComment = {
    comment: replyContent,
    comment_pk: comment_idReply,
    original_creator:originalCreator,
  };
  replyMutation.mutate(variables);
  setReplyContent("");
  closeReplyModal();
  commentAlarmRefech();
}

function newTagShow (date) {
return newTag(date)
}

//==========================================
  return (
    <Box>
      <VStack >
        
  {/* //========================================== */}
      
      <Box w="100%">
      {Array.isArray(commentQuery.data) && commentQuery.data.map((comment) => {
        return (
          <Box 
          
          w="95%" key={parseInt(comment.id,10)}>
            <HStack>
            {comment.depth > 0 ?
            <Box w={`${comment.depth * 5}%`}></Box>
            : null }
            <Box w="100%" m="2" p={4} shadow="md"   borderWidth="1px" borderRadius="md" >
            <VStack align="start"  >
              <HStack fontSize="sm" color="gray.500" justify="space-between" w="100%">
                <Text>{comment.creator.username}</Text>
                <Text fontSize="xs"> 
                {formatDateTime(comment.updated_at)}
              <span dangerouslySetInnerHTML={{ __html:newTagShow(comment.updated_at) }} />
             
              </Text>
                {/* <Text>Edit</Text> */}
                <HStack>
                 {isLoggedIn ? 
                  <>
                      <FaRegThumbsUp  onClick={() => handleRecomClick(comment.id)} />
                    <Button size="xs" p="-2" rounded="full"  variant={"ghost"}  onClick={() => openReplyModal(comment.id)}><FaReply /></Button>
                  </>
                : null } 
                { isLoggedIn && comment.creator.username === user.username ?
                <>
                  <Button size="xs" p="-2" rounded="full"  variant={"ghost"}  onClick={() => openEditModal(comment.id)}><FaPencilAlt /></Button>
                  <Button size="xs" p="-2" rounded="full"  variant={"ghost"}  onClick={() => confirmDelete(comment.id)}><FaRegTrashAlt /></Button>
                </>
                : <Text></Text> }
                </HStack>
              </HStack>
              <Box maxW="100%" overflow="hidden">
              <div dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(comment.comment) }} />
              </Box>
            </VStack>
            </Box>
            </HStack>
          </Box>
        );
      })} 
      </Box>
      <AlertDialog
              isOpen={isDeleteDialogOpen}
              leastDestructiveRef={cancelRef}
              onClose={onCloseDeleteDialog}
            >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Note Post
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this note post?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseDeleteDialog}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => handleDelete(comment_idToDelete)} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Edit Modal */}
        <AlertDialog
          isOpen={isEditModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseEditModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Edit Comment</AlertDialogHeader>
              <AlertDialogBody>
                <FormControl>
                <Textarea
                  id="editcomment_id"
                  {...register("comment", {required:true})} 
                  value={editedComment}
                  onChange={(e) => setEditedComment(e.target.value)}
                />
                </FormControl>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseEditModal}>
                  Cancel
                </Button>
                <Button colorScheme="blue" onClick={() => onSaveEdit(comment_idToEdit, editedComment)} ml={3}>
                  Save
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        {/* ==== reply */}
        <AlertDialog
          isOpen={isReplyModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={closeReplyModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Reply to Comment</AlertDialogHeader>
              <AlertDialogBody>
                <HStack>
                <FaReply />
                <Text m="10px" whiteSpace="pre-wrap" style={{ WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 3 }}>
                  {commentContent} /original_creator : {originalCreator}
                </Text>
                </HStack>
                <FormControl>
                  <Textarea
                    id="replyContentId"
                    autoFocus
                    {...register("comment", {required:true})} 
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    placeholder="Enter your reply"
                    required
                    
                  />
                </FormControl>
                <FormControl>
                  <Input type="hidden" name="original_creator" value={originalCreator} />
                </FormControl>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={closeReplyModal}>
                  Cancel
                </Button>
                <Button colorScheme="blue" onClick={handleReplySubmit} ml={3}>
                  Reply
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

      </VStack>
    </Box>
  );
}
