import { Box, Button, Center, Container, HStack, IconButton, VStack, Text, Textarea, Checkbox, FormControl, Radio, RadioGroup, Select, useHighlight, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Icon } from "@chakra-ui/react";
import { Form, Link } from "react-router-dom";
import { useDisclosure, useToast } from '@chakra-ui/react';
import useUser from "../../lib/useUser";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from 'uuid';
import { FaCaretRight, FaExclamationCircle, FaPlus, FaRegSun, FaSourcetree, FaTimes } from "react-icons/fa";
import { RiOpenaiFill } from "react-icons/ri";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// import apis
import { postAutoNoteBycate_pk } from "../../apisntypes/commonapis";
import { getCategory } from "../../apisntypes/categoryapis";
import { getAllPrompt, getCateNotes } from "../../apisntypes/noteapis";
import { updatePrompt } from "../../apisntypes/commonapis";
import { getNPackAddr } from "../../apisntypes/packapis";

// import types
import { IUpdatePrompt } from "../../apisntypes/commontypes";
import { ICategory } from "../../apisntypes/categorytypes";
import { cusColorScheme } from "../../css/customTheme";

// import components
import Categories from "../category/Categories";
import Summary from "./Summary";
import { useState, useEffect } from "react";
import { IAllNotesData } from "../../apisntypes/notetypes";
import SearchWeb from "../notes/SearchWeb";
import { INPack } from "../../apisntypes/packtypes";
import { FaCheck } from "react-icons/fa6";
import { FiSettings } from "react-icons/fi";

interface TopPanelProps {
  packTitle: string;
  subTitle: string;
  addr: string;
  cate_pk: number;
}

const TopPanel: React.FC<TopPanelProps> = ({ packTitle, subTitle, addr, cate_pk }) => {
  // use commons
  const catePkNumber = typeof cate_pk === 'string' ? parseInt(cate_pk, 10) : cate_pk;

  const { isLoggedIn, user } = useUser();
  const isStaff = user && user.is_staff;
  const toast = useToast();
  const [isActive, setIsActive] = useState(true);
  const [isPromptBoxOpen, setIsPromptBoxOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("default");
  const [isAiOptions, setIsAiOptions] = useState(true);
  const [useGoogleImage, setUseGoogleImage] = useState(false);

  const defaultWordCount = "500";
  const defaultTone = "neutral";
  const defaultWritingStyle = "descriptive";

  const [wordCount, setWordCount] = useState(defaultWordCount);
  const [tone, setTone] = useState(defaultTone);
  const [writingStyle, setWritingStyle] = useState(defaultWritingStyle);
  const providedPrompt = 'Give an example or a piece of knowledge of [category] under [title],\n\
        Do not repeat subject in previous titles';
  

  const [textareaValue, setTextareaValue] = useState(providedPrompt);
  const generateRandomHexCode = () => {
    const uuid = uuidv4().replace(/-/g, '').substring(0, 12);
    return uuid;
  };

  const [noteImageId, setNoteImageId] = useState(generateRandomHexCode());
  const queryClient = useQueryClient();

  // Functions

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setUseGoogleImage(isChecked);
};

  const promptBoxToggle = () => {
    setIsPromptBoxOpen(!isPromptBoxOpen);
    setSelectedOption("default");
  };

  // use query
  const { isLoading: isLoadingCategory, data: CategoryData } = useQuery<ICategory>(["category", catePkNumber], getCategory, { enabled: catePkNumber > 0 });
  const {isLoading: isLoadingPack, data: NPackData} = useQuery<INPack>(['npackdata', addr], getNPackAddr);
//   const { isLoading: IsLoadingAllPrompt, data: allPromptData } = useQuery(['allprompt', catePkNumber], () => getAllPrompt(catePkNumber), { enabled: catePkNumber > 0 && !isNaN(catePkNumber) });
  // const { isLoading: isLoadingCateNotes, data: cateNotesData } = useQuery<IAllNotesData>([`cateNotes`, addr, catePkNumber, 1], getCateNotes);
//   const allPrompts = allPromptData ? [textareaValue, ...allPromptData.filter(prompt => prompt && prompt.trim() !== "")] : [textareaValue];

  const [defaultPrompt, setDefaultPrompt] = useState(textareaValue);
  
  const { register: registerEdit, handleSubmit, setValue } = useForm<IUpdatePrompt>({
    defaultValues: {
      prompt: CategoryData?.prompt,
    },
  });

  const mutation = useMutation((formData: IUpdatePrompt) => {
    return updatePrompt(cate_pk, formData.prompt);
  });

  const additionalPrompt = ( wordCount, tone, writingStyle) => {
    return `use aiEngine chatGPT-4, express words count within ${wordCount}, tone in ${tone}, writing style ${writingStyle}`;
  }
  const [aiTextarea, setAiTextarea] = useState("");
  // use custom
  const postAutoNoteMutation = useMutation(
    (data: { 
        cate_pk: number; 
        isAioptions:boolean;
        prompt: string; 
        noteImageId: string, 
        wordCount:string, 
        tone:string, 
        writingStyle:string,
        useGoogleImage:string,
      }) => postAutoNoteBycate_pk(
            data.cate_pk, 
            isAiOptions,
            data.prompt, 
            noteImageId,
            wordCount, 
            tone, 
            writingStyle, 
            String(useGoogleImage),
        ),
    {
      onSuccess: (data) => {
        toast({
          status: "success",
          title: "Note posted by AI",
          position: "bottom-right",
        });
        queryClient.invalidateQueries(["cateNotes", addr, cate_pk, 1]);
      },
    }
  );

  const handleAutoCreate = async (catePk: number, isAiOptions:boolean, prompt: string, noteImageId:string, wordCount:string, tone:string,writingStyle:string) => {
    try {
      await postAutoNoteMutation.mutate({ 
        cate_pk: catePk, 
        isAioptions: isAiOptions,
        prompt:textareaValue, 
        noteImageId:noteImageId,
        wordCount:wordCount,
        tone:tone,
        writingStyle:writingStyle,
        useGoogleImage:String(useGoogleImage),
    });
      setIsPromptBoxOpen(false);
      showAlert();
      queryClient.invalidateQueries(["cateNotes", addr, catePk, 1]);
      setIsActive(true);
    } catch (error) {
      console.error("Error posting auto note", error);
    }
  };

  const showAlert = () => {
    toast({
      title: 'Prompt accepted',
      description: 'It takes a moment to generate data!',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

//   useEffect(() => {
//     setDefaultPrompt(textareaValue);
//   }, [textareaValue]);

  const onSave = async (formData: IUpdatePrompt) => {
    setIsActive(true);
    if (formData.prompt !== undefined) {
      try {
        await mutation.mutateAsync(formData);
        toast({
          title: "Prompt saved",
          description: "Your prompt has been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Mutation error:", error);
        toast({
          title: "Error",
          description: "Failed to save prompt.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      console.error('Prompt data is undefined.');
    }
  };

  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);

  const isFormValid =  wordCount && tone && writingStyle && textareaValue;

  const handleAiOption = () => {
    setIsAiOptions(prevState => !prevState);
    if (isAiOptions) {
    setAiTextarea(additionalPrompt( wordCount, tone, writingStyle));
    } else {
       setAiTextarea("");
    }
  }
  useEffect(() => {
    if (isAiOptions) {
      setAiTextarea(additionalPrompt( wordCount, tone, writingStyle));
    } else {
      setAiTextarea("");
    }
  }, [isAiOptions, wordCount, tone, writingStyle]);

  return (
    <Container maxW="100%" my="2" paddingLeft="0">
      <VStack>
        <VStack w="100%" alignItems={"flex-start"} mx="2" >
          <HStack>
            {cate_pk === 0 ?
            <>
               <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="SourceTree Info"
                    icon={<FaSourcetree />}
                    size="xs"
                    variant="ghost"
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    <Link to={`/${addr}/pd`} >
                      <FaRegSun /> {/* === pack detail */}
                    </Link>
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack align="start" fontSize={"xs"}>
                      <HStack>
                        <HStack >
                          <Text>Public:</Text>
                          <Icon as={NPackData?.pubOpt === "a" ? FaCheck : FaTimes} color={NPackData?.pubOpt === "a" ? "green.500" : "red.500"} />
                        </HStack>
                        <HStack >
                          <Text>Category:</Text>
                          <Icon as={NPackData?.cateOpt === "a" ? FaCheck : FaTimes} color={NPackData?.cateOpt === "a" ? "green.500" : "red.500"} />
                        </HStack>
                      </HStack>
                      <HStack>
                        <HStack spacing={3}>
                          <Text>Note:</Text>
                          <Icon as={NPackData?.noteOpt === "a" ? FaCheck : FaTimes} color={NPackData?.noteOpt === "a" ? "green.500" : "red.500"} />
                        </HStack>
                        <HStack spacing={3}>
                          <Text>Comment:</Text>
                          <Icon as={NPackData?.commentOpt === "a" ? FaCheck : FaTimes} color={NPackData?.commentOpt === "a" ? "green.500" : "red.500"} />
                        </HStack>
                      </HStack>
                      <Box>
                      {NPackData?.desc}
                      </Box>
                      </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>  
            : 
            <FaCaretRight />
            }
            <Box fontSize="lg" textAlign={"center"}>
              <Link to={`/${addr}`}>
                {packTitle}
              </Link>
            </Box>
          </HStack>
          {subTitle !== "" ?
            <HStack fontSize="md" textAlign={"center"}>
              <Link to={`/${addr}/cn/${cate_pk}`}>
                {subTitle}
              </Link>
                <SearchWeb kword={subTitle} />
            </HStack>
            : null}
        </VStack>
        <HStack justifyContent={"space-between"} alignItems="center" w="100%">
          <Box>
            <Categories addr={addr} />
          </Box>
          <HStack m="2">
            <Box>
              <Link to={`/${addr}/pn/${cate_pk}`} >
                <Button colorScheme={cusColorScheme}>
                  +
                </Button>
              </Link>
            </Box>
            {cate_pk !== 0 && isLoggedIn ?
              <Box>
                <Button colorScheme={"cyan"} onClick={promptBoxToggle}>
                  <RiOpenaiFill />
                </Button>
              </Box>
              : null}
            <Summary />
          </HStack>
        </HStack>

        {isPromptBoxOpen ?
          <form onSubmit={handleSubmit(onSave)} style={{ display: 'flex', width: '100%' }} >
            <VStack
              justifyContent={"space-between"}
              alignItems="center"
              w="100%"
              borderRadius={"10px"}
              p="2"
            >

              <HStack alignItems="center" w="100%">
                <Box w="95%" m="2" alignItems={"center"}>
                  {/* ============================prompt */}
                  {/* <HStack mx="4">
                    <Text>
                      {currentPromptIndex + 1}/{allPrompts.length}
                    </Text>
                    <IconButton
                      icon={<FaChevronLeft />}
                      aria-label="Previous Prompt"
                      size="sm"
                      onClick={handlePreviousPrompt}
                      isDisabled={currentPromptIndex === 0}
                    />
                    <IconButton
                      icon={<FaChevronRight />}
                      aria-label="Next Prompt"
                      size="sm"
                      onClick={handleNextPrompt}
                      isDisabled={allPrompts && currentPromptIndex >= allPrompts.length - 1}
                    />
                  </HStack> */}
                  <Textarea
                    defaultValue={providedPrompt}
                    w="100%"
                    my="4"
                    fontSize={"sm"}
                    h="100px"
                    resize={"vertical"}
                    bgColor="gray.200"
                    {...registerEdit("prompt")}
                    onChange={(e) => setTextareaValue(e.target.value)}
                  ></Textarea>
                {isAiOptions ? 
                    <>
                    <Button onClick={handleAiOption} size="xs" variant={"ghost"}>
                    use Ai Options
                    </Button>
                    </>
                    :
                    <>
                    <Button onClick={handleAiOption} size="xs" variant={"ghost"}>
                    No Ai Options
                    </Button>
                    </>}
                {/* <Checkbox checked={true} onChange={handleAiOption}>Ai</Checkbox> */}
                {isAiOptions ? <>
                <Textarea 
                name="aiTextarea" 
                value={aiTextarea} 
                fontSize={"sm"}
                readOnly
                ></Textarea>
                  <HStack align="left" w="90%" fontSize="xs" m="2" spacing="4">

                    <FormControl>
                      <Text fontSize="xs">Word Count</Text>
                      <Select
                        placeholder="Select word count"
                        size="sm"
                        value={wordCount}
                        onChange={(e) => setWordCount(e.target.value)}
                      >
                        <option value="300">100 words</option>
                        <option value="300">200 words</option>
                        <option value="300">300 words</option>
                        <option value="300">400 words</option>
                        <option value="500">500 words</option>
                        <option value="500" disabled>600 words</option>
                        <option value="500" disabled>700 words</option>
                        <option value="800" disabled>800 words</option>
                        <option value="800" disabled>2000 words</option>
                        <option value="800" disabled>4000 words</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Text fontSize="xs">Tone</Text>
                      <Select
                        placeholder="Select tone"
                        size="sm"
                        value={tone}
                        onChange={(e) => setTone(e.target.value)}
                      >
                        <option value="formal">Formal</option>
                        <option value="informal">Informal</option>
                        <option value="neutral">Neutral</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Text fontSize="xs">Writing Style</Text>
                      <Select
                        placeholder="Select style"
                        size="sm"
                        value={writingStyle}
                        onChange={(e) => setWritingStyle(e.target.value)}
                      >
                        <option value="descriptive">Descriptive</option>
                        <option value="narrative">Narrative</option>
                        <option value="expository">Expository</option>
                        <option value="persuasive">Persuasive</option>
                        <option value="argumentative">Argumentative</option>
                        <option value="creative">Creative</option>
                        <option value="reflective">Reflective</option>
                        <option value="analytical">Analytical</option>
                        <option value="technical">Technical</option>
                        <option value="journalistic">Journalistic</option>
                        <option value="critical">Critical</option>
                        <option value="scientific">Scientific</option>
                      </Select>
                    </FormControl>
                  </HStack>
                  <HStack>
                  {/* <FormControl>
                    <Text>Image Insert</Text>
                    <Checkbox 
                        isChecked={useGoogleImage}
                        onChange={handleCheckboxChange}
                    >
                        Use Google Search Image
                    </Checkbox>
                </FormControl> */}
                  </HStack>
                </>:null }

                  <HStack w="100%" justifyContent="space-between">
                    {cate_pk !== 0 ?
                      <>
                        {isActive && textareaValue !== "" ?
                          <Button colorScheme={"cyan"}
                            onClick={() => handleAutoCreate(cate_pk, isAiOptions, textareaValue, noteImageId, wordCount, tone, writingStyle)}
                            size="sm"
                            w="100%"
                            isDisabled={!isFormValid || !isActive}
                          ><RiOpenaiFill />generate
                          </Button>
                          :
                          <Button size={"sm"} colorScheme="cyan"
                            variant="ghost">generate</Button>
                        }
                      </>
                      : null}
                  </HStack>
                </Box>
                {/* ============================prompt */}
              </HStack>
              <input type="hidden" name="noteImageId" value={noteImageId} />
            </VStack>
          </form>
          : null}
      </VStack>
    </Container>
  )
}

export default TopPanel;
