import { 
    Button, 
    Container, 
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    HStack,
    Box,
    Text,
    Center,
    VStack,
    Flex,
} from "@chakra-ui/react";
// import general
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import apis
import {getAllCategoriesByAddr } from "../../apisntypes/categoryapis";
import { getNPack, getNPackAddr } from "../../apisntypes/packapis";
// import user
import useUser from "../../lib/useUser";
// import types
import { ICategory } from "../../apisntypes/categorytypes";
import { IUser } from "../../apisntypes/logintypes";
import { INPack } from "../../apisntypes/packtypes";
//import icons
import {AiOutlineMenu} from 'react-icons/ai';
import { FiSettings } from "react-icons/fi";
// import component
import Category from "./Category";
// import custom
import { customBgColor } from "../../css/customcolor";
import { boxBlue } from "../../css/customBox";

// interface CategoriesProps {
//   addr:string;
//   isOpen?: boolean;
//   onClose?: () => void;
//   onOpen?: () => void;
//   cusMessage?: string;
// }

// const Categories: React.FC<CategoriesProps> = ({  isOpen, onOpen, onClose, cusMessage }) => {
const Categories = ({addr}) => {
  // static 
  const [pack_pk, setpack_pk] = useState("");
  const {isLoggedIn, user} = useUser();
  const [isMoving, setIsMoving] = useState(false);
  // usequery
  const { isLoading:isLoadingNPack, data:NPackData } = useQuery<INPack[]>([`npackitem`, addr], getNPackAddr);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const { isLoading, data } = useQuery<ICategory[]>([`allCategoriesByAddr`, addr], getAllCategoriesByAddr);
  // dynamic 
  const total = data ? data.length : 0 ;

// func
  const onMoveClick = (pk) => {
    setIsMoving(!isMoving);
  }
  const onDropClick = (pk) => {
    setIsMoving(!isMoving);
  }
  return (
      <Container maxW="container.lg" paddingLeft="0">
      <Button  fontSize="xs" colorScheme='blue' onClick={onOpen} >
        <Flex justifyContent="flex-start" >
          <AiOutlineMenu /> 
          <Text marginLeft={"1"}> Category</Text>
        </Flex>
      </Button>
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>
              <HStack>
                <Box mr="2">
                  Category
                </Box>
                <Box>
                  {isLoggedIn && user && NPackData && NPackData['creator'].username === user.username ?
                  <Link to={`/${addr}/cm`} >
                    <FiSettings />
                  </Link>
                  :
                  <>
                    <FiSettings />
                  </> 
                   }
                  
                </Box>
              </HStack>
            </DrawerHeader>
          <DrawerBody onClick={()=>onClose()}>
            <DrawerCloseButton />
            <Text fontSize={"xl"} my="4">
              
              <Link to={`/${addr}`} >
              {NPackData && NPackData['title']}
              </Link>
            </Text>
            {data?.length ===0  ?
            <>
              <Center {...boxBlue}   >
                <VStack>
                    <HStack>
                        <Text color="gray.800">Click  [ </Text>
                        <Text color="gray.800">
                          { NPackData && user.username === NPackData['creator'].username ?
                          <Link to={`/${addr}/cm`}><FiSettings /></Link>
                          :<FiSettings />}
                          </Text>
                        <Text color="gray.800"> ]  button </Text>
                    </HStack>
                  
                    <Text color="gray.800">
                      to add category
                    </Text>
                </VStack>
              </Center>
            </>
            :
            null
            }
            
          <Text fontSize={"sm"} color="GrayText">
            Please select sub category to post.
          </Text>
          {data && data.map((cate) => (
            <Category
              key={cate?.pk}
              title={cate?.title}
              creator={cate?.creator}
              upperPk={cate?.upperPk}
              pk={cate?.pk}
              postCount={cate?.postCount}
              cateCount={cate?.cateCount}
              cLength={total}
              depth={cate?.depth}
              sub={cate?.sub}
              addr={cate?.addr}
              pack_pk={parseInt(pack_pk,10)}
              prompt={cate?.prompt}
              updated_at={cate?.updated_at}
              isMoving={isMoving}
              onMoveClick={onMoveClick}
              onDropClick={onDropClick}
            />
            ))}
          </DrawerBody>
          </DrawerContent>
      </Drawer>
        
    </Container>
   
  );
}

export default Categories;