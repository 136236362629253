import { createBrowserRouter, useParams } from "react-router-dom";
import Root from "./routes/Root";
import Home from "./routes/Home";
import NoteDetail from "./routes/notes/NoteDetail";
import NotFound from "./routes/NotFound";
import PostNote from "./routes/notes/PostNote";
import Categories from "./routes/category/Categories";
import CategoryManager from "./routes/category/CategoryManager";
import CateNotes from "./routes/notes/CateNotes";
import CreatePack from "./routes/pack/CreatePack";
import NotesSearch from "./routes/notes/NoteSearch";
import { FaSearch } from "react-icons/fa";
import LoginPage from "./routes/login/LoginPage";
import MyPacks from "./routes/pack/MyNPacks";
import PackDetail from "./routes/pack/PackDetail";
import Prompt from "./routes/category/Prompt";
// import AllNotes from "./routes/notes/AllNotes";
import PackFavList from "./routes/pack/PackFavList";
import { PrivacyPolicy } from "./routes/common/PrivacyPolicy";
import { GroupPage } from "./routes/pack/GroupPage";
import GroupPacks from "./routes/pack/GroupPacks";
import PackSearch from "./routes/pack/PackSearch";
import MainNotes from "./routes/notes/MainNotes";
import Profile from "./routes/login/Profile";
import MyPost from "./routes/notes/MyPost";
import MyFavorites from "./routes/notes/Favorites";
import About from "./routes/common/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement:<NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path:"/:addr",
        element: <MainNotes />
      },   
      {
        path: "/:addr/pn/:cate_pk",
        element: <PostNote  />
      },     
      {
        path: "/:addr/cn/",
        element: <CateNotes />
      },    
      {
        path: "/:addr/cn/:cate_pk",
        element: <CateNotes />
      },  
      {
        path: "/:addr/c",
        element: <Categories addr="" />
      },      
      {
        path:"/:addr/nd/:cate_pk/:note_pk",
        element:<NoteDetail />
      },
      {
        path:"/:addr/cm",
        element:<CategoryManager  />
      },
      {
        path:"/createpack/",
        element: <CreatePack />
      },
      {
        path:"/mypacks/",
        element: <MyPacks />
      },
      {
        path:"/favs",
        element: <PackFavList />
      },
      {
        path:"/groups",
        element: <GroupPacks />
      },
      {
        path:"/:addr/pd",
        element: <PackDetail />
      },
      {
        path:"/sn/:searchStr",
        element: <NotesSearch  />
      },
      {
        path:"/sp/:searchStr",
        element: <PackSearch  />
      },
      {
        path:"/prompt/:cate_pk",
        element: <Prompt />
      },
      {
        path:"/login",
        element: <LoginPage />
      },
      {
        path:"*",
        element: <NotFound />,
      },
      {
        path:"/pp",
        element: <PrivacyPolicy />
      },
      {
        path:"/:addr/gr",
        element:<GroupPage  />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/mypost",
        element: <MyPost />
      },
      {
        path: "/myfavorites",
        element: <MyFavorites />
      },
      {
        path: "/about",
        element: <About />
      },

    ],
  },
]);

export default router;