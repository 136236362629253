import { extendTheme } from "@chakra-ui/react";

export const customTheme = extendTheme({
    colors: {
      darkColor: 'gray.800',
      lightColor: 'gray.300',
    },
  });

  export const grayColorScheme = "gray";
  export const blueColorScheme = "blue";
  export const redColorScheme = "red";
  export const yellowColorScheme = "yellow";
  export const greenColorScheme = "green";
  export const tealColorScheme = "teal";
  export const orangeColorScheme = "orange";
  export const cyanColorScheme = "cyan";
  export const purpleColorScheme = "purple";
  export const pinkColorScheme = "pink";
  export const cusColorScheme = blueColorScheme;