import React from "react";
import { HStack,  Button, Container, Box, Text, useDisclosure, IconButton, Collapse, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { cusColorScheme } from "../../css/customTheme";
import { customTheme } from "../../css/customTheme";
import Summary from "../common/Summary";
import useUser from "../../lib/useUser";
import { FaPlus, FaRegFolderOpen, FaRegStar } from "react-icons/fa";
import { useState, ChangeEvent, KeyboardEvent } from "react";
import { cyanColorScheme } from "../../css/customTheme";
import { FaUserGroup } from "react-icons/fa6";
import { SearchIcon } from "@chakra-ui/icons";
import { FaRegUser } from "react-icons/fa";

export const PackMenu = ({nPackPage}) => {
    const navigate = useNavigate();
    const {userLoading, isLoggedIn, user } = useUser();
    const [inputVisible, setInputVisible] = useState(false);
    const { isOpen, onToggle } = useDisclosure();
    const [searchStr, setSearchStr] = useState<string>("");

    const handleClick = () => {
        setInputVisible(true);
        onToggle();
      }; 

    const handlePackSearch = () => {
        if (searchStr.trim() === "") {
            return;
        }
        navigate(`/sp/${searchStr}`);
    }
    const handleSearchStrInput = (event: ChangeEvent<HTMLInputElement>) => {
        const str = event.target.value;
        setSearchStr(str);
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          handlePackSearch();
        }
      };

    return (
        <Container maxW="container.lg" py={"6"}>
        <HStack 
        w="98%"
        alignItems="center" 
        justifyContent={"space-between"}
        >
        <Link to="/">
            <Button
                colorScheme={nPackPage === "pub" ? cyanColorScheme : cusColorScheme}
                title="All Note Packs"
            >
                <FaRegFolderOpen />
            </Button>
        </Link>
            {/* ====login only */}
        <Link to="/groups">
        <Button
                colorScheme={nPackPage === "group" ? cyanColorScheme : cusColorScheme}
                title="Group Note Packs"
            >
                <FaUserGroup />
            </Button>
        </Link>
        {isLoggedIn ? 
        <>
        <Link to="/mypacks">
            <Button
                colorScheme={nPackPage === "priv" ? cyanColorScheme : cusColorScheme}
                title="My Note Packs"
                >
                <FaRegUser />
            </Button>
        </Link>
        </>
        :
        <>
            <Button
                colorScheme={nPackPage === "hid"  ? cyanColorScheme : cusColorScheme}
            >
                <FaRegUser />
            </Button>
        </>
        }
            {/* ====login only */}
        {isLoggedIn ? 
        <>
        <Link to="/favs">
            <Button
                colorScheme={nPackPage === "fav"  ? cyanColorScheme : cusColorScheme}
                title="Favorites Note Packs"
            >
                <FaRegStar />
            </Button>
        </Link>
        </>
        :
        <>
            
            <Button colorScheme={cusColorScheme} onClick={()=> navigate("/login")}
                >
                <FaRegStar />
            </Button>
        </>
        }
       
            {/* ====login only */}
        {isLoggedIn ? 
        <>
        <Link to="/createpack">
            <Button
                colorScheme={cusColorScheme}
                title="Create a Pack"
            >
                <FaPlus />
            </Button>
        </Link>
        </>
        :
        <>
            <Button colorScheme={cusColorScheme}><FaPlus /></Button>
        </>
        }
        <Box pl="1" title="Summary">
            <Summary  /> 
        </Box>

        </HStack>

        <Box display="flex" alignItems="center" m="2" justifyContent={"space-between"}>
            <HStack>
                <IconButton
                    icon={<SearchIcon />}
                    onClick={handleClick}
                    aria-label="Search"
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: 'transparent' }}
                    _focus={{ boxShadow: 'none' }}
                />
                <Collapse in={isOpen} animateOpacity>
                    <HStack>
                        <Input 
                            placeholder="Search Packs" 
                            variant="outline" 
                            name="searchStr" 
                            value={searchStr} 
                            onKeyDown={handleKeyPress}
                            onChange={handleSearchStrInput}
                        />
                        <Button onClick={handlePackSearch}>Search</Button>
                    </HStack>
                </Collapse>      
            </HStack>
            {/* <HStack>
                <Button size="xs" variant="ghost">Newest</Button>
                <Button size="xs" variant="ghost">popular</Button>
            </HStack> */}

    </Box>
        </Container>
    )
}