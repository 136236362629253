import React from 'react';
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Footer from './routes/common/Footer';
import { customTheme } from './css/customTheme';
import { HelmetProvider } from 'react-helmet-async';

export const client = new QueryClient(); // 캐싱기능

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
     <ChakraProvider theme={customTheme}>
      <HelmetProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <RouterProvider router={router} />
        <Footer />
      </HelmetProvider>
    </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
