import { INote } from './notetypes';
import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})

export const getNote = ({ queryKey }: QueryFunctionContext) => {
    const [_, note_pk] = queryKey;
    return instance.get(`notes/${note_pk}`)
    .then((response) => response.data)
    .catch((error) => {
        if (error.response && error.response.status === 404) {
            throw new Error("Note not found");
        } else {
            throw new Error('Failed to fech note');
        }
    });
    };


export const getCateNotes = ({ queryKey }: QueryFunctionContext) => {
    
    const [_, addr, cate_pk, currentPage] = queryKey;
    // console.log("cate_pk:", cate_pk, "Type of cate_pk:", typeof cate_pk);
    const validCatePk = isNaN(Number(cate_pk)) ? null : Number(cate_pk);
    if (validCatePk === null) {
        // console.error("Invalid cate_pk:", cate_pk);
        return null
        // return Promise.reject(new Error("Invalid cate_pk value"));
    }
    return instance.get(`notes/catenotes/${addr}/${validCatePk}?page=${currentPage}`)
    .then((response) => response.data);
    };

export const getAllNotesUnderPack = ({ queryKey }: QueryFunctionContext) => {
    const [_, pack_pk, currentPage] = queryKey;
    return instance.get(
        `notes/allnotes/${pack_pk}?page=${currentPage}`,
        {
            headers: {
                "X-CSRFToken" : Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    };

export const getAllNotesByAddr = ({ queryKey }: QueryFunctionContext) => {
    const [_, addr, currentPage] = queryKey;
    return instance.get(
        `notes/allnotes/${addr}?page=${currentPage}`,
        {
            headers: {
                "X-CSRFToken" : Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    };
    
export const postNote = (variables: INote) => {
    const formData = new FormData();
    formData.append('title', variables.title);
    formData.append('cate_pk', variables.cate_pk);
    formData.append('recoms', String(variables.rec_count));
    formData.append('addr', String(variables.addr));
    formData.append('noteimageid', String(variables.noteimageid));
    formData.append('content', variables.content);
    formData.append('is_secret', String(variables.is_secret));
    variables.imageURLs.forEach(url => {
        formData.append('imageURLs[]', url);
    });
    // for (let i = 0; i < variables.imgurl.length; i++) {
    //     formData.append('imgurl', variables.imgurl[i]);
    //   } 
    const entries = Array.from(formData.entries());
    // entries.forEach(([key, value]) => {
    //     console.log(key, value);
    // });

    return instance.post(
    `notes/post/`,
    formData, // important
    {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        "Content-Type": "multipart/form-data"  
    },
    })
.then((response) => response.data);
}

//---- post view - from notedetail.tsx
export const postView = (variables:{pk:number} ) => {
    return instance.post(
        `notes/hit/${variables.pk}`,
        variables,
        {
            headers: {
                "X-CSRFToken" : Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}

export const updateNote = ( pk:number, title:string, content:string, noteimageid:string, imageids: string[], imageURLs:string[], is_secret:string) => {
    return instance.put(
        `notes/update/${pk}`,
        {pk, title, content, noteimageid, imageids, imageURLs, is_secret},
        {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
        })
    .then((response) => response.data);
    }



export const deleteNote =  (note_pk:number) => {
    return instance.delete(
        `notes/delete/${note_pk}`,
    {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then(response =>  response.data)
}


//---- post recom from notedetail.tsx button
export const increaseRecom = (variables:{pk:number} ) => {
    return instance.post(
        `notes/recom/${variables.pk}`,
        variables,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}

export const unRecom = (variables:{pk:number} ) => {
    return instance.post(
        `notes/unrecom/${variables.pk}`,
        variables,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    }

export const getMyRecom = (variables:{pk:number} ) => {
    return instance.get(
        `notes/getmyrecom/${variables.pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    }


//---- post recom from notedetail.tsx button
export const doFav = (variables:{pk:number} ) => {
    return instance.post(
        `notes/dofav/${variables.pk}`,
        variables,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}

export const unFav = (variables:{pk:number} ) => {
    return instance.post(
        `notes/unfav/${variables.pk}`,
        variables,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    }

export const getMyFav = (variables:{pk:number} ) => {
    return instance.get(
        `notes/getmyfav/${variables.pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
    }


export const getImages = (noteimageid) => {
    // console.log(noteimageid);
    return instance.get(
        `medias/photos/${noteimageid}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}

export const getAllPrompt = (cate_pk:number) => {

    return instance.get(
        `notes/getallprompt/${cate_pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
};

export const getMyPosts = () => {
        return instance.get(`notes/getmyposts`,
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                }
            })
        .then((response) => response.data);
        };

export const secretChange = (note_pk) => {
    return instance.post(
        `notes/secretchange/${note_pk}`,
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}