import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const isKorean = (text) => {
    // Check if the text contains any Korean characters
    const koreanRegex = /[\u3131-\uD79D]/ugi;
    return koreanRegex.test(text);
};

const SearchWeb = ({ kword }) => {
    const [query, setQuery] = useState(kword || "");
    const [showDropdown, setShowDropdown] = useState(false);
    const [isKoreanText, setIsKoreanText] = useState(false);

    useEffect(() => {
        setQuery(kword);
        setIsKoreanText(isKorean(kword));
    }, [kword]);

    const handleSearch = (engine) => {
        let url = "";
        switch (engine) {
            case "google":
                url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
                break;
            case "images":
                url = `https://www.google.com/search?tbm=isch&q=${encodeURIComponent(query)}`;
                break;
            case "dictionary":
                url = `https://www.dictionary.com/browse/${encodeURIComponent(query)}`;
                break;
            case "youtube":
                url = `https://www.youtube.com/results?search_query=${encodeURIComponent(query)}`;
                break;
            case "wikipedia":
                url = isKoreanText
                    ? `https://ko.wikipedia.org/wiki/Special:Search?search=${encodeURIComponent(query)}`
                    : `https://en.wikipedia.org/wiki/Special:Search?search=${encodeURIComponent(query)}`;
                break;
            case "namuwiki":
                url = isKoreanText
                  ? `https://namu.wiki/w/${encodeURIComponent(query)}`
                  : `https://en.namu.wiki/w/${encodeURIComponent(query)}`;
                break;
            default:
                url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
        }
        window.open(url, "_blank");
    };

    return (
        <Box>
            <Menu size="sm">
                <Tooltip label="search on other sites">
                <MenuButton
                  variant={"ghost"}
                  as={IconButton}
                  icon={<SearchIcon />}
                  aria-label="Select search engine"
                  size="sm"
                />
                </Tooltip>
                <MenuList>
                    <Text px="4" fontSize="xs" bgColor={"blue.700"}>
                        Search by '{kword}' ({isKoreanText ? "Korean" : "English"})
                    </Text>
                    <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("google")}>Google</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("images")}>Image Search</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("dictionary")}>Dictionary</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("youtube")}>Youtube</MenuItem>
                    <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("wikipedia")}>Wikipedia</MenuItem>
                    {isKoreanText && (
                        <MenuItem style={{ fontSize: '12px' }} onClick={() => handleSearch("namuwiki")}>Namuwiki</MenuItem>
                    )}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default SearchWeb;
