import { Box, Container, Stack, Center, Text,
    useMediaQuery, Heading, HStack, Button, VStack, useToast, Flex } from "@chakra-ui/react";
 import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/react";
 import { useQuery } from "@tanstack/react-query";
 import { useState, useRef, useEffect} from "react";
 import { useMutation } from "@tanstack/react-query";
 import { getSearch } from "../../apisntypes/commonapis";
 import Pagination from "../common/Pagination";
 import { INote } from "../../apisntypes/notetypes"; 
 import Categories from "../category/Categories";
 import { Grid, GridItem } from "@chakra-ui/react";
 import useUser from "../../lib/useUser";
 import { Link, useNavigate, useParams } from "react-router-dom";
 import { FaRegHeart, FaStar, FaRegCommentDots, FaRegEye, FaRegTrashAlt, FaRegSun} from "react-icons/fa";
 import {client} from "../../index";
 import { customBgColor } from "../../css/customcolor";
 import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
 import {FaSearch, FaLongArrowAltLeft} from "react-icons/fa";
 import CateNote from "../notes/CateNote";
 import { PageSize } from "../../lib/PageSize";
// import Adsense from "../common/Adsense";

 export default function NotesSearch() {
    // static
    const { searchStr } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLargeScreen] = useMediaQuery("(min-width: 768px)"); // Change the breakpoint as needed
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const cancelRef = useRef();
    const [selectedcate_pk, setSelectedcate_pk] = useState(null);
    const {userLoading, isLoggedIn, user} = useUser();
    const toast = useToast();
    const navigate = useNavigate();
    // usequery
    const { isLoading, data } = useQuery([`searchcontent`, searchStr], getSearch);
    // const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
    // let nextPage = "";
    // let count = 0;
    // let totalPages =0;
    // let orderNumber = 0;
    let orderNumberSearch =0;
    
    const searchNoteCount = data?.results.length;
    orderNumberSearch = searchNoteCount - (currentPage-1)*PageSize;

    const handleGoBack = () => {
        navigate(-1); // Go back by one step in navigation
    };


     return (
     <>
     <Container maxW="container.lg">
        {/* =============== 타이틀 */}
         <Box  py="20px" w="100%" >
             <HStack  alignItems="center" justifyContent={"space-between"}>
                 <Box w="20%"
                    display={"flex"} 
                    alignItems={"center"} 
                    onClick={handleGoBack}
                    cursor={"pointer"}
                 >
                    <FaLongArrowAltLeft /> 
                    <Box marginLeft={"2"}>
                        go back
                    </Box>
                </Box>
                 <Box textAlign={"center"} w="60%" >
                     <Heading >Search</Heading>
                 </Box>
                 <Box w="20%"></Box>
             </HStack>
         </Box>
        {/* ====== 검색 내용 */}
        {!isLoading ?   (
            <>
            {data.results.length >0 ?  (
            data.results.map((note,index) =>
                <CateNote 
                key={note?.pk}
                title={note?.title}
                content={note?.content}
                creator={note?.creator}
                link={note?.link}
                view_count={note?.view_count}
                rec_count={note?.rec_count}
                updated_at={note?.updated_at}
                pk={note?.pk}
                addr={note?.addr}
                pack_pk={note?.pack_pk}
                cate_pk={note?.cate_pk}
                prompt={note?.prompt}
                is_secret={note?.is_secret}
                count={searchNoteCount}
                orderNumber={orderNumberSearch--}
                searchStr={searchStr}
                />
            )
            ):(
                <Flex justifyContent={"center"} alignItems={"center"} height="30vh" >
                <Box  fontSize={"lg"} width="100%"  textAlign={"center"}>
                    No results
                </Box>
            </Flex>
            )
            }
            </>
        ):null    
    }    
    </Container>

    </>
     )
 }