import React, { useState, useEffect } from 'react';
import NPackItem from "./NPackItem";
import { useQuery } from "@tanstack/react-query";
import useUser from "../../lib/useUser";
import { Box, Grid, VStack, Center, Container, useMediaQuery, 
  CircularProgress, Heading , useToast} from "@chakra-ui/react";
// import apis
import { getPackFavList, deleteFavPack } from "../../apisntypes/packapis"; 
import { INPack } from "../../apisntypes/packtypes"; 
import { PackMenu } from './PackMenu';
import { useNavigate } from 'react-router-dom';
import ProtectedPage from '../ProtectedPage';

interface NPacksApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: INPack[]; // Define INPack interface according to the structure of your data
}

export default function PackFavList() {
  
  const [pageNum, setPageNum] = useState(1);
  const { userLoading, user, isLoggedIn } = useUser();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Track total number of items
  const [data, setData] = useState<NPacksApiResponse>({ count: 0, next: null, previous: null, results: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);
  const [renderCount, setRenderCount] = useState(1);
  const [isLargeScreen] = useMediaQuery("(min-width: 768px)");
  const toast = useToast();
  const [favoritePacks, setFavoritePacks] = useState<  number []>([]);
  const [noDataMessageDisplayed, setNoDataMessageDisplayed] = useState(true);
  useEffect(() => {
    setNoDataMessageDisplayed(false); // Reset the flag whenever pageNum changes or component mounts
  }, [pageNum]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn]);

  const fetchNPacksFavList = async (pageNum: number) => {
    setIsLoading(true);

    try {
      const response = await getPackFavList(pageNum);
      if (response && response.results) { // Ensure response and results are not null or undefined
        const newData = pageNum === 1 ? response.results : [...data.results, ...response.results];
        
        // Extract the list of favorite packs from the response
        const favoritePackIds = response.results
          .filter(pack => pack.isFavorite === 'y')
          .map(pack => pack.id);
        setFavoritePacks(favoritePackIds);
    
        setData(prevData => ({
          ...prevData,
          results: newData,
        }));
    
        setPage(prevPage => prevPage + 1);
        setTotalItems(response.count);
        setCurrentItemsCount(prevCount => prevCount + response.results.length);
        setNoDataMessageDisplayed(false);
      } else {
        // No data returned from the server
        if (!noDataMessageDisplayed) {
          
          setNoDataMessageDisplayed(prevState => {
            return true; // Setting the state to true
          });
        toast({
          status: "info",
          title: "No data found.",
          position: "bottom-right",
        });
      }
      }
    } finally {
      setIsLoading(false);
    }
    
  }    
  // console.log(data.results);

  useEffect(() => {
    if (isLoggedIn) {
    fetchNPacksFavList(page);
    }
  }, [isLoggedIn]); // Fetch initial data when component mount


  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.body.scrollHeight;
    if (scrollPosition >= pageHeight - 20 && !isLoading && currentItemsCount < totalItems) {
      fetchNPacksFavList(page);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, data.results.length, totalItems]);

  return (
    <ProtectedPage>
    <Container maxW="container.lg">
      <VStack m={6}>
        <Box w="100%" textAlign="center">
          <Heading pr="25px">Favorites</Heading>
        </Box>
        <PackMenu nPackPage="fav" />
      </VStack>
      
      <Container maxW="container.lg">
      {isLoading && data.results.length === 0 && (
          <Center>
            <CircularProgress isIndeterminate color="green.300" />
          </Center>
        )}
        {!isLoading && data.results.length > 0 ? (
        <Grid
        templateColumns={{
          base: "repeat(2, 1fr)", // 2 columns for small screens
          sm: "repeat(2, 1fr)",  // 3 columns for small to medium screens
          md: "repeat(3, 1fr)",  // 4 columns for medium to large screens
          lg: "repeat(4, 1fr)",  // 5 columns for large screens and above
        }}
        gap={4}
        alignItems="stretch"
        my="2"
      >
          {data.results.map((mcate, index) => (
            <NPackItem
              key={index}
              pk={mcate.id}
              pack_pk={mcate.id}
              title={mcate.title}
              addr={mcate.addr}
              desc={mcate.desc}
              inputString=""
              creator={mcate.creator}
              prompt={mcate.prompt}
              postCount={mcate.postCount}
              cateCount={mcate.cateCount}
              pubOpt={mcate.pubOpt}
              isGroupOpen={mcate.isGroupOpen}
              cateOpt={mcate.cateOpt}
              noteOpt={mcate.noteOpt}
              commentOpt={mcate.commentOpt}
              updated_at={mcate.updated_at}
              renderCount={renderCount+index}
              nPackPage="fav"
              isFavorite={mcate.isFavorite}
              favoritePacks={favoritePacks}
              language={mcate.language}
              setFavoritePacks={setFavoritePacks}
              is_newpost={mcate.is_newpost}
              />
          ))}
          {isLoading && <CircularProgress isIndeterminate color="green.300" />}
        </Grid>
      ) : (
        !isLoading && (
        <Center>No data available.</Center>
        )
      )}
    </Container>
    </Container>
    </ProtectedPage>
  );

}