import React from "react";
import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FaUserNinja, FaLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usernameLogIn } from "../../apisntypes/loginapis";
import { useNavigate } from "react-router-dom";
import SignUpPage from "./SignUpPage";
import SocialLogin from "./SocialLogin";

interface IForm {
  username: string;
  password: string;
}

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(usernameLogIn, {
    onMutate: () => {
      // console.log("mutation starting");
    },
    onSuccess: (data) => {
      toast({
        title: "Welcome back!",
        status: "success",
      });
      // Handle success, maybe redirect or show a message
      navigate('/');
    },
    onError: (error) => {
      // console.log("mutation has an error");
    },
  });

  const onSubmit = ({ username, password }: IForm) => {
    mutation.mutate({ username, password });
  };

  return (

    <Center maxW="container.lg"
        >
        <VStack>
            <Text my="6" fontSize={"40"}>Drimnotes</Text>
            <InputGroup size={"md"}>
                <InputLeftElement
                children={
                    <Box color="gray.500">
                    <FaUserNinja />
                    </Box>
                }
                />
                <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                    required: "Please write a username",
                })}
                variant={"filled"}
                placeholder="Username"
                w="300px"
                />
            </InputGroup>
            <InputGroup>
                <InputLeftElement
                children={
                    <Box color="gray.500">
                    <FaLock />
                    </Box>
                }
                />
                <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                    required: "Please write a password",
                })}
                variant={"filled"}
                placeholder="Password"
                type="password"
                w="300px"
                />
            </InputGroup>
            <Button
                isLoading={mutation.isLoading}
                type="submit"
                mt={4}
                colorScheme={"red"}
                w="100%"
                onClick={handleSubmit(onSubmit)}
            >
                Log in
            </Button>
            
        {/*     <SocialLogin /> */}
            
            <SignUpPage />

      </VStack>
    </Center>
  );
};

export default LoginPage;
