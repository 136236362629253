import { useQuery } from '@tanstack/react-query';
import {getMe} from "../apisntypes/loginapis"
import { IUser } from '../apisntypes/logintypes'; 
import { useState } from 'react';

export default function useUser(){
    const {isLoading, data, isError} = useQuery<IUser>(['me'], getMe, {
        retry:false,
    }) ;
    const [isLoggedIn, setIsLoggedIn] = useState(!isError);

    const updateLoggedInStatus = (status: boolean) => {
        setIsLoggedIn(status);
    };
    
    return {
        userLoading: isLoading, 
        user: data,
        isLoggedIn: !!data,
        updateLoggedInStatus,
    };
}


