import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import { IUploadRoomVariables } from "./commontypes";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})

instance.interceptors.request.use((config) => {
    const csrfToken = Cookie.get("csrftoken") || "";
    config.headers["X-CSRFToken"] = csrfToken;
    return config;
  });

export const getSearchCates = ({queryKey}:QueryFunctionContext) => {
    const [_,searchText] = queryKey;
    return instance.get(`categories/searchcates/${searchText}`)
    .then((response) => { 
        return response.data 
    });
}

export const getSearch = ({queryKey}: QueryFunctionContext) => {
    const [_,searchStr] = queryKey;
    return instance.get(`notes/search/${searchStr}`, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => {
        return response.data
    })
}

export const getRecentCategories = () => {
    // const csrfToken = Cookie.get("csrftoken") || "";
    return instance.get(`categories/recent`, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => {
        return response.data
    })
}

export const getRecentNotes = () => {
    return instance.get(`notes/recent`, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => {
        return response.data
    })
}

export const getRecentComments = () => {
    return instance.get(`comments/recent`,  {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => {
        return response.data
    })
}



export const postAutoNote = () => {
    return instance.post(
        `notes/create_auto_notes/`,
        {},
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
}

export const postAutoNoteBycate_pk = (
    cate_pk: number, 
    isAioptions: boolean,
    prompt: string, 
    noteImageId: string, 
    wordCount:string, 
    tone:string, 
    writingStyle:string, 
    useGoogleImage:string,
    ) => {
    return instance.post(
        `notes/autonotebycate_pk/${cate_pk}`,
        {   cate_pk, 
            isAioptions,
            prompt, 
            noteImageId, 
            wordCount, 
            tone, 
            writingStyle,
            useGoogleImage,
         },
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            }
        }
    )
}

export const updatePrompt = ( pk: number, prompt: string) => {

    return instance.put(
    `categories/updateprompt/${pk}`, {prompt:prompt}, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
.then((response) => response.data);}

export const moveCategory = ( prevPk: number,newPk: number) => {
    return instance.put(
    `categories/move/${prevPk}/${newPk}`,{prevPk:prevPk, newPk:newPk},  {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
.then((response) => response.data);}


export const getUploadURL = () => {
  return instance
    .post(`medias/photos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
}
export interface IUploadImageVariables {
    file: File[];
    uploadURL : string;
}

export const uploadImage = async ({ file, uploadURL }: IUploadImageVariables) => {
    if (!file || file.length === 0) {
        throw new Error("No files provided for upload");
      }
    
    const form = new FormData();
    form.append("file", file[0]);
    
    try {
        const response = await axios.post(uploadURL, form, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error; // Re-throw error to be handled by onError in useMutation
    }
  };

  export interface ICreatePhotoVariables {
    noteImageId: string;
    files: string;
  }
  
  export const createPhoto = ({
      noteImageId,
      files,
  }: ICreatePhotoVariables) => {
    return instance
      .post(
        `notes/${noteImageId}/photos`,
        {  files },
        {
          headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
          },
        }
      )
      .then((response) => response.data);
    }

    export const deleteImage = (imageId: string) => {
        return instance.delete(`medias/photos/delete/${imageId}`, {
            headers: {
              "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
          })
          .then((response) => response.data);
      };

