import {  Box, IconButton, useColorMode } from "@chakra-ui/react";
import { deleteFavPack, postFavPack } from "../../apisntypes/packapis";
import { FaStar } from "react-icons/fa";

export const StarButton = ({
    pack_pk, favOrNot, favoritePacks, updateFavoriteStatus, setFavoritePacks}) => {
    const { colorMode } = useColorMode();

    const handleDeleteFavPack = () => {
        deleteFavPack(pack_pk)
            .then(data => {
                updateFavoriteStatus(favoritePacks.filter(id => id !== pack_pk));
                // console.log('Pack removed from favorites:', data);
                // Handle any additional actions after removing the pack from favorites
            })
            .catch(error => {
                // console.error('Error removing pack from favorites:', error);
                // Handle error scenarios
            });
    };
    
    const handleFavPost = (pack_pk) => {
        postFavPack(pack_pk)
        .then(() => {
            // Update favorite status after successfully marking the pack as favorite
            updateFavoriteStatus([...favoritePacks, pack_pk]);
        })
        .catch(error => {
            // Handle error if the POST request fails
            console.error("Error marking pack as favorite:", error);
        });
    };

    return (
      <Box position="absolute" top={-2} right={-2}>
        <IconButton
          aria-label="Star button"
          icon={<FaStar />}
          size="md"
          variant="ghost"
          color={favoritePacks.includes(pack_pk) ? 'yellow.700' : (colorMode === 'dark' ? 'gray' : 'gray.800')}
          _hover={{ color: 'yellow.400' }}
          onClick={favoritePacks.includes(pack_pk)  ? handleDeleteFavPack : handleFavPost.bind(null, pack_pk)}
        />
      </Box>
    );
  };
