
export const customBgColor = {
    customBgColorPink: {
        bgColor : "lightpink",
        //_hover: {bgColor: 'pink'},
    },
    
    customBgColorBlue: {
        bgColor : "blue.300",
        _hover: {bgColor: 'blue.400'},
    },
    customBgColorLightBlue: {
        bgColor : "lightblue.300",
        _hover: {bgColor: 'lightblue.400'},
    },
    
    customBgColorOrange: {
        bgColor : "orange.300",
        //_hover: {bgColor: 'lightOrange'},
    },
    
    customBgColorPurple: {
        bgColor : "purple.3",
        //_hover: {bgColor: 'lightblue'},
    },
    
    customBgColorTeal: {
        bgColor : "teal.300",
        _hover: {bgColor: 'teal.500'},
    },
};