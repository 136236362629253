import { Container } from "@chakra-ui/react";
import React from "react";

const MyFavorites = () => {
    return (
        <Container>
        My Favorites
        </Container>
    )
}
export default MyFavorites;