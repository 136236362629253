import { Badge, Box, Button, Container, Flex, HStack, Table, Tbody, Td, Text, Toast, Tr, VStack, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getNPackAddr, getGroupMember, acceptGroupMember, cancelGroupMember, deleteGroupMember } from "../../apisntypes/packapis";
import { useQueryClient } from "@tanstack/react-query"; 
import { useEffect, useState } from "react";

export const GroupAdminPage = ({addr}) => {
    const [groupData, setGroupData] = useState(null);
    const { data, isLoading, isError, refetch } = useQuery(['getGroupMember',addr], () => getGroupMember(addr));
    const tableSize = useBreakpointValue({ base:"sm", md:"md"})
    const queryClient = useQueryClient();
    const toast = useToast();

    const handleAcceptGroupMember = async (addr, memberPk) => {
        try {
            const response = await acceptGroupMember(addr, memberPk);
            setGroupData(prevData => {
                const updatedMembers = prevData.members.map(member => {
                    if (member.pk === memberPk) {
                        return { ...member, isAccepted: true };
                    }
                    return member;
                });
                return { ...prevData, members: updatedMembers };
            });
            await refetch();
            queryClient.invalidateQueries(["applyGroupMember"]);
            toast({
                title: "Success",
                description: "Group member is accepted",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to accept group member.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        }
    }

    const handleCancelGroupMember = async (addr, memberPk) => {
        try {
            const response = await cancelGroupMember(addr, memberPk);
            setGroupData(prevData => {
                const updatedMembers = prevData.members.map(member => {
                    if (member.pk === memberPk) {
                        return { ...member, isAccepted: false };
                    }
                    return member;
                });
                return { ...prevData, members: updatedMembers };
            });
            await refetch();
            queryClient.invalidateQueries(["cancelGroupMember"]);
            toast({
                title: "Success",
                description: "Group member is canceled",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to cancel group member.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        }
    }

    const handleDeleteGroupMember = async (addr, memberPk) => {
        try {
            const response = await deleteGroupMember(addr, memberPk);
            setGroupData(prevData => {
                const updatedMembers = prevData.members.filter(member => member.pk !== memberPk);
                return { ...prevData, members: updatedMembers };
            });
            await refetch();
            queryClient.invalidateQueries(["deleteGroupMember"]);
            toast({
                title: "Success",
                description: "The group member is deleted",
                status: "success",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete group member.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position:"bottom-right",
            });
        }
    }
    useEffect(() => {
        setGroupData(data);
    }, [data])

    return (
        <Container >
            <Text>Group Admin Page ({groupData ? groupData?.members.length : 0})</Text>
            
            <Table variant="simple" mt={2} size={tableSize}>
                <Tbody>
                    <Tr>
                        <Td>user</Td>
                        <Td>Applied</Td>
                        <Td>Accepted</Td>
                        <Td>manage</Td>
                    </Tr>
            {data && data.members.map(member => (
                <>
              <Tr>
                <Td>{member.username}</Td>
                <Td>{member.isApplied ? 
                        <>
                        {!member.isGroupAdmin || !member.isAccepted  ? 
                            <>
                            { !member.isAccepted ?  // 억렙트 한 사람
                                <Button size="xs" colorScheme="green" onClick={() => handleAcceptGroupMember(addr, member.pk)}>Accept</Button>
                            :
                                'Accepted'
                            }
                            </>
                            : <Badge colorScheme="blue" variant={"outline"}>Admin</Badge> }
                        </> : 
                    '-'}
                    </Td>
                <Td>{member.isAccepted ? 
                    <>
                    {!member.isGroupAdmin ?
                        <Button size="xs" colorScheme="red" onClick={() => handleCancelGroupMember(addr, member.pk)}>Cancel</Button>
                        : '-' }
                    </> 
                    : '-'}</Td>
                <Td>
                    {!member.isGroupAdmin ?
                    <Button size="xs" colorScheme="orange" onClick={() => handleDeleteGroupMember(addr, member.pk)}>delete</Button>
                    : '-' }
                </Td>
              </Tr>
              </>
            ))}
            </Tbody>
          </Table>
          
        </Container>
    )
}