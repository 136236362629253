import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  VStack,
  Grid,
  Heading,
  Center,
  CircularProgress,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import NPackItem from "./NPackItem";
import { getSearchPacks } from "../../apisntypes/packapis";
import { INPack } from "../../apisntypes/packtypes";
import { PackMenu } from "./PackMenu";

interface NPacksApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: INPack[]; // Define INPack interface according to the structure of your data
  message?: string;  // Add optional message field
}

export default function PackSearch() {
  const { searchStr } = useParams<{ searchStr: string }>();
  const [page, setPage] = useState(1);
  const [data, setData] = useState<NPacksApiResponse>({ count: 0, next: null, previous: null, results: [] });
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);
  const [renderCount, setRenderCount] = useState(1);
  const toast = useToast();
  const [isLargeScreen] = useMediaQuery("(min-width: 768px)");
  const [favoritePacks, setFavoritePacks] = useState<number[]>([]);

  const fetchSearchPacks = async (searchStr: string) => {
    setIsLoading(true);
    try {
      const response = await getSearchPacks(searchStr);
      if (response) {
        if (response.message) {
          toast({
            status: response.count === 0 ? "info" : "success",
            title: response.message,
            position: "bottom-right",
          });

          setData({
            count: response.count,
            next: null,
            previous: null,
            results: response.results,
          });
          setTotalItems(response.count);
          setCurrentItemsCount(response.results.length);
          setPage(2);
          return;
        }

        const newData = page === 1 ? response.results : [...data.results, ...response.results];
        const favoritePackIds = response.results
          .filter(pack => pack.isFavorite === 'y')
          .map(pack => pack.id);

        setFavoritePacks(favoritePackIds);

        setData((prevData) => ({
          ...prevData,
          results: newData,
        }));

        setPage((prevPage) => prevPage + 1);
        setTotalItems(response.count);
        setCurrentItemsCount((prevCount) => prevCount + response.results.length);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
      // toast({
      //   status: "error",
      //   title: "Error fetching data",
      //   position: "bottom-right",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchStr) {
      fetchSearchPacks(searchStr);
    }
  }, [searchStr]);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.body.scrollHeight;
    if (scrollPosition >= pageHeight - 20 && !isLoading && currentItemsCount < totalItems) {
      fetchSearchPacks(searchStr);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, data.results?.length, totalItems]);

  return (
    <Container maxW="container.lg" py={"6"}>
      <VStack>
        <Box w="100%" textAlign={"center"}>
          <Heading pr="25px">Searched Packs : {searchStr}</Heading>
        </Box>
        <PackMenu nPackPage="pub" />
      </VStack>
      <Container maxW="container.lg">
        {data.results && data.results.length > 0 ? (
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)", 
              sm: "repeat(2, 1fr)",  
              md: "repeat(3, 1fr)",  
              lg: "repeat(4, 1fr)",  
            }}
            gap={4}
            alignItems="stretch"
            my="4"
          >
            {data.results.map((mcate, index) => (
              <NPackItem
                key={index}
                pk={mcate.id}
                pack_pk={mcate.id}
                title={mcate.title}
                addr={mcate.addr}
                desc={mcate.desc}
                inputString=""
                creator={mcate.creator}
                postCount={mcate.postCount}
                cateCount={mcate.cateCount}
                prompt={mcate.prompt}
                pubOpt={mcate.pubOpt}
                isGroupOpen={mcate.isGroupOpen}
                cateOpt={mcate.cateOpt}
                noteOpt={mcate.noteOpt}
                commentOpt={mcate.commentOpt}
                updated_at={mcate.updated_at}
                renderCount={renderCount + index}
                nPackPage="pub"
                isFavorite={mcate.isFavorite}
                favoritePacks={favoritePacks}
                language={mcate.language}
                setFavoritePacks={setFavoritePacks}
                is_newpost={mcate.is_newpost}
              />
            ))}
            {isLoading && <CircularProgress isIndeterminate color="green.300" />}
          </Grid>
        ) : (
          <Center>No packs found for "{searchStr}".</Center>
        )}
      </Container>
    </Container>
  );
}
