import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton, Icon, ButtonGroup, Button, Box, Popover,
  PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton,
  PopoverBody, Grid, GridItem, Tooltip, Select, Spinner, Text, Input,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { deleteImage, uploadImage } from "../../apisntypes/commonapis";
import { getUploadURL } from "../../apisntypes/commonapis";
import { getImages } from "../../apisntypes/noteapis";

import { MdFormatBold, MdFormatColorText, MdFormatColorFill, 
  MdFormatAlignLeft, MdFormatAlignCenter, MdFormatAlignRight, 
  MdContentCopy, MdContentCut, MdContentPaste, MdVideoLibrary, 
  MdFormatIndentIncrease, MdFormatIndentDecrease, 
  MdInsertPhoto, MdImageSearch,
  MdMovie} from 'react-icons/md';

import { CloseIcon, LinkIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import { FiGrid, FiUpload } from 'react-icons/fi'; // Example using a square icon from react-icons library
import { FaSquare } from 'react-icons/fa'; // Import FaSquareFull from react-icons/fa5
import ImageSlide from './ImageSlide';

interface HtmlEditorProps {
  value: string;
  onChange: (newValue: string) => void;
  
  isImageStatus,
  noteimageid, 
  uploadedImgs, 
  setUploadedImgs,
  imageids,
  setImageids,
  onImageInsert,
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({ value, onChange,
  
  isImageStatus,
  noteimageid, 
  uploadedImgs, 
  setUploadedImgs,
  imageids,
  setImageids,
  onImageInsert,

 }) => {
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [bgColorPickerOpen, setBgColorPickerOpen] = useState<boolean>(false);
  const [selectedFont, setSelectedFont] = useState<string>('Arial'); // Default font
  const [selectedFontSize, setSelectedFontSize] = useState<string>('16'); // Default font size
  const [links, setLinks] = useState([]);
  const editorRef = useRef<HTMLDivElement | null>(null);
  // const savedSelection = useRef<Range | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const queryClient = useQueryClient();
  const { data: images, isLoading, isError } = useQuery(["images",noteimageid],() => getImages(noteimageid));
  const [showImages, setShowImages] = useState(false);
  const inputRef = useRef(null);
  const [showSlide, setShowSlide] = useState(false);
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState(null);
  const [savedSelection, setSavedSelection] = useState(null);

  const pastelColors = [
    { name: 'Pastel Pink', color: '#FFB7B2' },
    { name: 'Pastel Orange', color: '#FFDAB9' },
    { name: 'Pastel Yellow', color: '#FFFFB5' },
    { name: 'Pastel Green', color: '#B9E2D2' },
    { name: 'Pastel Blue', color: '#A7C7E7' },
  ];

  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      if (!imageids.includes(result.id)) {
        setUploadedImgs((prevImgs) => [...prevImgs, result.variants[0]]);
        setImageids((prevImgIds) => [...prevImgIds, result.id]);
        onImageInsert(result['variants'][0]);
        // console.log(result['variants'][0]);
        
      }
    },
    onError: (error: any) => {
      console.error("Upload failed:", error);
    }
  });

  const toast = useToast();


  const getURLmutation = useMutation(getUploadURL, {
    onSuccess: (data: any) => {
      return data;
    },
  });

  useEffect(() => {
    if (images && images.length > 0) {
      const newUploadedImg = images.map((image) => image.imageurl);
      const newImageids = images.map((image) => image.imageid);
      setUploadedImgs((prevImgs) => {
        const newImgs = [...prevImgs];
        newUploadedImg.forEach((img, idx) => {
          if (!prevImgs.includes(img)) {
            newImgs.push(img);
          }
        });
        return newImgs;
      });
      setImageids((prevImgIds) => {
        const newIds = [...prevImgIds];
        newImageids.forEach((id) => {
          if (!prevImgIds.includes(id)) {
            newIds.push(id);
          }
        });
        return newIds;
      });
    }
  }, [images, setUploadedImgs, setImageids, onImageInsert]);

  const deleteImageMutation = useMutation(deleteImage, {
    onSuccess: (data, imageId) => {
    console.log("Delete successful:");
    queryClient.invalidateQueries(["images", noteimageid]);
      // setUploadedImgs((prevImgs) => prevImgs.filter((_, idx) => idx !== context.index));
  },
  onError: (error: any) => {
    console.error("Delete failed:", error);
    // Additional error handling
  }
});
  const toggleImages = () => {
      setShowImages(!showImages);
    };

    
  const handleFileChange = async  (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
  
    const fileArray: File[] = Array.from(files) as File[];// Convert FileList to Array
    // url 받아오기
    for (const file of fileArray) {
      try {
        // Get the upload URL
        const url = await getURLmutation.mutateAsync();
        // Upload the image
        await uploadImageMutation.mutateAsync({ file: [file], uploadURL: url.uploadURL });

    } catch (error) {
        console.error("Error uploading file:", error);
    }
    }

  };

  const removeImageFile = (index:number) => {
    const updatedFiles = [...uploadedImgs];
    const updatedImgIds = [...imageids];
    
    const imageId = updatedImgIds[index];

    updatedFiles.splice(index, 1); // Remove the file at the specified index
    updatedImgIds.splice(index, 1); // Remove the imageId at the specified index
    
    // Update the state
    setUploadedImgs(updatedFiles);
    setImageids(updatedImgIds);

    // Call the mutation to delete the image from the server
    deleteImageMutation.mutate(imageId);
  }

  const handleClick = () => {
    inputRef.current.click();
  };
  // console.log(uploadedImgs);

  const handleCloseFullscreen = () => {
    setFullscreenImageIndex(null);
  };  
  const handleImageClick = (index) => {
    setFullscreenImageIndex(index);
  };

  // ==================================

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSavedSelection(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (savedSelection) {
      selection.removeAllRanges();
      selection.addRange(savedSelection);
    }
  };

  const placeCursorAtEnd = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(editorRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    saveSelection();
  };

  const handleContentChange = () => {
    if (editorRef.current) {
      onChange(editorRef.current.innerHTML);
    }
  };

  const executeCommand = (command: string, value?: string) => {
    restoreSelection();
    document.execCommand(command, false, value);
    saveSelection();
  };

  const handleBackgroundColor = (color: string) => {
    // console.log('Applying background color:', color);
    setBgColorPickerOpen(false);
    document.execCommand('hiliteColor', false, color);
    handleContentChange();
  };

  const handleBold = () => {
    executeCommand('bold');
    handleContentChange();
  };

  const handleColor = (color: string) => {
    let commandValue = color;
    if (color === 'none') {
      commandValue = 'initial';
    } else if (color === 'none-bg') {
      // Handle 'none-bg' color case
      commandValue = 'transparent';
    } else {
      commandValue = color;
    }
    executeCommand('foreColor', color);
    handleContentChange();
  };


  const handleAlignLeft = () => {
    executeCommand('justifyLeft');
    handleContentChange();
  };

  const handleAlignCenter = () => {
    executeCommand('justifyCenter');
    handleContentChange();
  };

  const handleAlignRight = () => {
    executeCommand('justifyRight');
    handleContentChange();
  };

  const handleFontChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFont(event.target.value);
    executeCommand('fontName', event.target.value);
  };

  const handleInsertYouTubeClip = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (!editorRef.current.contains(range.commonAncestorContainer)) {
        placeCursorAtEnd();
      }
    } else {
      placeCursorAtEnd();
    }

    // <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; background: #000;">
    // </div>
    const url = prompt("Enter YouTube URL");
    if (url) {
      const videoId = extractYouTubeVideoId(url);
      if (videoId) {
        const embedCode = `
    <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; background: #000;">
          <iframe 
            src="https://www.youtube.com/embed/${videoId}" 
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" 
            title="YouTube video" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
          </iframe>
    </div>
        `;
        restoreSelection();
        // Save current selection
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
  
          // Create a temporary div to hold the embed code
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = embedCode.trim();
          
          const iframeDiv = tempDiv.firstElementChild;

          // Insert the temporary div into the document at the current selection
          range.deleteContents();
          range.insertNode(iframeDiv);
  
          // Restore the selection
          selection.removeAllRanges();
          const newRange = document.createRange();
          newRange.selectNodeContents(tempDiv);
          selection.addRange(newRange);
  
          // Trigger the content change callback
          handleContentChange();
        }
      } else {
        toast({
          title: "Invalid URL",
          description: "Please enter a valid YouTube URL.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  

  const extractYouTubeVideoId = (url) => {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  const colorOptions = [
 
    // Red
    { color: '#800000', title: 'Very Dark Red' },
    { color: '#BF0000', title: 'Darker Red' },
    { color: '#FF0000', title: 'Red' },
    { color: '#FF3333', title: 'Dark Red' },
    { color: '#FF6666', title: 'Light Red' },
    { color: '#FF9999', title: 'Very Light Red' },
    { color: '#FFCCCC', title: 'Extremely Light Red' },
    
    // Green
    { color: '#008000', title: 'Very Dark Green' },
    { color: '#00BF00', title: 'Darker Green' },
    { color: '#00FF00', title: 'Green' },
    { color: '#33FF33', title: 'Dark Green' },
    { color: '#66FF66', title: 'Light Green' },
    { color: '#99FF99', title: 'Very Light Green' },
    { color: '#CCFFCC', title: 'Extremely Light Green' },
    
    // Blue
    { color: '#000080', title: 'Very Dark Blue' },
    { color: '#0000BF', title: 'Darker Blue' },
    { color: '#0000FF', title: 'Blue' },
    { color: '#3333FF', title: 'Dark Blue' },
    { color: '#6666FF', title: 'Light Blue' },
    { color: '#9999FF', title: 'Very Light Blue' },
    { color: '#CCCCFF', title: 'Extremely Light Blue' },
    
    // Yellow
    { color: '#808000', title: 'Very Dark Yellow' },
    { color: '#BFBF00', title: 'Darker Yellow' },
    { color: '#FFFF00', title: 'Yellow' },
    { color: '#FFFF33', title: 'Dark Yellow' },
    { color: '#FFFF66', title: 'Light Yellow' },
    { color: '#FFFF99', title: 'Very Light Yellow' },
    { color: '#FFFFCC', title: 'Extremely Light Yellow' },
    
    // Magenta
    { color: '#800080', title: 'Very Dark Magenta' },
    { color: '#BF00BF', title: 'Darker Magenta' },
    { color: '#FF00FF', title: 'Magenta' },
    { color: '#FF3399', title: 'Dark Magenta' },
    { color: '#FF66B2', title: 'Light Magenta' },
    { color: '#FF99CC', title: 'Very Light Magenta' },
    { color: '#FFCCE6', title: 'Extremely Light Magenta' },
    
    // Cyan
    { color: '#008080', title: 'Very Dark Cyan' },
    { color: '#00BFBF', title: 'Darker Cyan' },
    { color: '#00FFFF', title: 'Cyan' },
    { color: '#33FFFF', title: 'Dark Cyan' },
    { color: '#66FFFF', title: 'Light Cyan' },
    { color: '#99FFFF', title: 'Very Light Cyan' },
    { color: '#CCFFFF', title: 'Extremely Light Cyan' },
    
    // Orange
    { color: '#804000', title: 'Very Dark Orange' },
    { color: '#BF5F00', title: 'Darker Orange' },
    { color: '#FF7F00', title: 'Orange' },
    { color: '#FF6600', title: 'Dark Orange' },
    { color: '#FF9933', title: 'Light Orange' },
    { color: '#FFB366', title: 'Very Light Orange' },
    { color: '#FFDAB3', title: 'Extremely Light Orange' },

    { color: 'black', title: 'black'},
    { color: 'white', title: 'white'},
    { color: 'none', title: 'No Color' },        // None color
    { color: 'none-bg', title: 'No Background' }, // None background color
  
  ];
  
  

  const fontOptions = [
    'Arial', 'Times New Roman', 'Verdana', 'Courier New', 'Georgia', 'Tahoma'
  ];

  const handleFontSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    saveSelection();
    setSelectedFontSize(event.target.value);
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.extractContents();
    const span = document.createElement('span');

    // Remove existing font size styles within the selected range
    const walker = document.createTreeWalker(selectedText, NodeFilter.SHOW_ELEMENT, null);
    while (walker.nextNode()) {
      (walker.currentNode as HTMLElement).style.fontSize = '';
    }

    // Apply the new font size
    span.style.fontSize = `${event.target.value}px`;
    span.appendChild(selectedText);
    range.insertNode(span);

    // Reselect the modified content
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(span);
    selection.addRange(newRange);

    restoreSelection();
    handleContentChange();
  };

  const fontSizeOptions = [
    '12', '14', '16', '18', '20', '24', '28', '32', '36', '48'
  ];

const handleInsertLink = () => {
  const url = window.prompt("Enter URL");
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    if (!editorRef.current.contains(range.commonAncestorContainer)) {
      placeCursorAtEnd();
    }
  } else {
    placeCursorAtEnd();
  }

  if (url) {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (range) {
      const selectedText = range.toString();
      const linkMarkup = `<a href="${url}" target="_blank">${selectedText || url}</a>`;

      // Create a new span element with the link
      const linkElement = document.createElement("span");
      linkElement.innerHTML = linkMarkup;

      // Delete the selected text (if any)
      range.deleteContents();

      // Insert the new link element
      range.insertNode(linkElement);

      // Move the cursor after the inserted link
      range.setStartAfter(linkElement);
      range.setEndAfter(linkElement);
      selection.removeAllRanges();
      selection.addRange(range);

      // Trigger the onChange callback with the updated content
      const content = document.querySelector('[contenteditable]').innerHTML;
      onChange(content);
    }
  }
};

const handleImageLink = () => {
  const imageUrl = window.prompt("Enter Image URL");
    if (imageUrl && editorRef.current) {
      const customCode = `
      <br>
      <img src="${imageUrl}" alt="Inserted Image" style="max-width: 100%;" class="custom-class"/>
      <br>
      `;
      const editor = editorRef.current;
      const selection = window.getSelection();
      let range;

      if (selection && selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
        const selectedNode = range.commonAncestorContainer;

        if (!editor.contains(selectedNode)) {
          range = document.createRange();
          range.selectNodeContents(editor);
          range.collapse(false); // Collapse to the end of the content
        }
      } else {
        range = document.createRange();
        range.selectNodeContents(editor);
        range.collapse(false); // Collapse to the end of the content
      }

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = customCode;

      while (tempDiv.firstChild) {
        const node = tempDiv.firstChild;
        range.insertNode(node);
        range.setStartAfter(node);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      // Update the state with the new content
      const updatedContent = editor.innerHTML;
      onChange(updatedContent);
    }
};

const handleInsertTitleBox = (color) => {
  const tableMarkup = `
     <div style="text-align: center;">
      <table style="border-radius: 10px; width: 100%; background-color: ${color};">
        <tbody>
          <tr>
            <td style="padding: 8px; color: gray; height: 50px;"></td>
          </tr>
        </tbody>
      </table>
    </div>
  `;
  
  const editor = editorRef.current;
  if (!editor) return;
  
  editor.focus();
  let selection = window.getSelection();
  if (!selection.rangeCount) {
    // If no selection, place the cursor at the end
    selection.collapse(editor, editor.childNodes.length);
  }
  const range = selection.getRangeAt(0);
  range.deleteContents();
  
  // Create a document fragment to hold the table markup
  const fragment = range.createContextualFragment(tableMarkup);
  const lastNode = fragment.lastChild;

  range.insertNode(fragment);
  
  // Move the cursor to the end of the inserted table
  const newRange = document.createRange();
  range.setStartAfter(lastNode);
  range.setEndAfter(lastNode);
  selection.removeAllRanges();
  selection.addRange(newRange);
  // Call onChange with the new value
  onChange(editor.innerHTML);
};


  const handleIndentIncrease = () => {
    executeCommand('indent');
    handleContentChange();
  };
  
  const handleIndentDecrease = () => {
    executeCommand('outdent');
    handleContentChange();
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Enter key code is 13
    if (event.key === 'Enter') {
      const selection = window.getSelection();
      if (selection) {
        const range = selection.getRangeAt(0);
        const container = range.startContainer;
        if (container.nodeType === Node.ELEMENT_NODE && (container as Element).tagName === 'IFRAME') {
          event.preventDefault();
        }
      }
    }
  };

  
  return (
    <div>
      {/* Toolbar */}
      <div>
        <Box pb="1" mb="1">
          {/* Font Type Dropdown */}
          <ButtonGroup variant="outline" spacing="0" marginRight="10px">
            <Select
              size="sm"
              value={selectedFont}
              onChange={handleFontChange}
              maxWidth="120px"
            >
              {fontOptions.map((font, index) => (
                <option key={index} value={font}>{font}</option>
              ))}
            </Select>
            <Select
              size="sm"
              value={selectedFontSize}
              onChange={handleFontSizeChange}
              maxWidth="80px"
            >
              {fontSizeOptions.map((size, index) => (
                <option key={index} value={size}>{size}</option>
              ))}
            </Select>
          </ButtonGroup>

          <Box display="inline-block" borderLeft="1px solid #ccc" height="12px" margin="0 10px" />

          <ButtonGroup variant="outline" spacing="0" marginRight="10px">
            <Tooltip label="Bold">
              <IconButton size="sm" variant="ghost" icon={<Icon as={MdFormatBold} />} aria-label="Bold" onMouseDown={saveSelection} onClick={handleBold} />
            </Tooltip>
          {/* text color ======================================== */}
            <Popover
              isOpen={colorPickerOpen}
              onOpen={() => setColorPickerOpen(true)}
              onClose={() => setColorPickerOpen(false)}
              placement="bottom"
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <Tooltip label="Text Color">
                  <IconButton
                    size="sm"
                    variant="ghost"
                    icon={<Icon as={MdFormatColorText} onMouseDown={saveSelection} />}
                    aria-label="Text Color"
                    onClick={() => setColorPickerOpen(true)}
                  />
                </Tooltip>
              </PopoverTrigger>
              <PopoverContent  pt={6} pb={2}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Grid templateColumns="repeat(7, 1fr)" gap={1}>
                    {colorOptions.map((option, index) => (
                      <GridItem key={index} display="flex" justifyContent="center" alignItems="center" >
                        <Button
                          variant="unstyled"
                          width="10px"
                          height="10px"
                          borderRadius={"md"}
                          onClick={() => handleColor(option.color)}
                          title={option.title}
                          style={{
                            backgroundColor: option.color === 'none' || option.color === 'none-bg' ? 'transparent' : option.color,
                            cursor: 'pointer',
                            padding: 0,
                            margin: 0,
                            border: option.color === 'none' || option.color === 'none-bg' ? '1px dashed #ccc' : '1px solid',
                             borderColor: '#ccc', // Light border color to make the button stand out
                            boxShadow: '0 0 2px rgba(0,0,0,0.3)' // Light shadow for additional contrast
                          }}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          {/* background color  ======================================== */}
            <Popover
              isOpen={bgColorPickerOpen}
              onOpen={() => setBgColorPickerOpen(true)}
              onClose={() => setBgColorPickerOpen(false)}
              placement="bottom"
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <Tooltip label="Background Color">
                  <IconButton
                    size="sm"
                    variant="ghost"
                    icon={<Icon as={MdFormatColorFill} />}
                    aria-label="Background Color"
                    onMouseDown={saveSelection}
                    onClick={() => setBgColorPickerOpen(true)}
                  />
                </Tooltip>
              </PopoverTrigger>
              <PopoverContent pt="4" pb="2">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Grid templateColumns="repeat(7, 1fr)" gap={1}>
                    {colorOptions.map((option, index) => (
                      <GridItem key={index} display="flex" justifyContent="center" alignItems="center">
                        <Button
                          variant="unstyled"
                          width="10px"
                          height="10px"
                          borderRadius="md"
                          onClick={() => handleBackgroundColor(option.color)}
                          style={{
                            backgroundColor: option.color,
                            cursor: 'pointer',
                            padding: 0,
                            margin: 0,
                            border: '1px solid',
                            borderColor: '#ccc', // Light border color to make the button stand out
                            boxShadow: '0 0 2px rgba(0,0,0,0.3)' // Light shadow for additional contrast
                          }}
                          
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </ButtonGroup>

          {/* Alignment Buttons */}
          <Box display="inline-block" borderLeft="1px solid #ccc" height="12px" margin="0 10px" />

          <ButtonGroup variant="outline" spacing="0">
            <Tooltip label="Align Left">
              <Button size="sm" variant="ghost" onMouseDown={saveSelection} onClick={handleAlignLeft}>
                <Icon as={MdFormatAlignLeft} />
              </Button>
            </Tooltip>
          {/* ======================================== */}
            <Tooltip label="Align Center">
              <Button size="sm" variant="ghost" onMouseDown={saveSelection} onClick={handleAlignCenter}>
                <Icon as={MdFormatAlignCenter} />
              </Button>
            </Tooltip>
          {/* ======================================== */}
            <Tooltip label="Align Right">
              <Button size="sm" variant="ghost" onMouseDown={saveSelection} onClick={handleAlignRight}>
                <Icon as={MdFormatAlignRight} />
              </Button>
            </Tooltip>
          {/* ======================================== */}
            <Tooltip label="Indent Increase">
              <IconButton size="sm" variant="ghost" aria-label="Indent" icon={<MdFormatIndentIncrease onClick={handleIndentIncrease} />} />
            </Tooltip>
          {/* ======================================== */}
            <Tooltip label="Indent Decrease">
              <IconButton size="sm" variant="ghost" icon={<Icon as={MdFormatIndentDecrease} />} aria-label="Indent Decrease" onClick={handleIndentDecrease} />
            </Tooltip>
          </ButtonGroup>
          <Box display="inline-block" borderLeft="1px solid #ccc" height="12px" margin="0 10px" />
          <Tooltip label="Insert Youtube Clip">
            <IconButton size="sm" variant="ghost" icon={<Icon as={MdVideoLibrary} />} aria-label="Insert YouTube Clip" onClick={handleInsertYouTubeClip} color="red.500" />
          </Tooltip>
          {/* ======================================== */}
          <Tooltip label="insert url">
            <IconButton
              size="sm"
              variant={"ghost"}
              icon={<LinkIcon />}
              aria-label="Insert Link"
              onClick={handleInsertLink}
            />
          </Tooltip>
          {/* ======================================== */}
          <Tooltip label="Insert Table">
            <Menu>
              <MenuButton
                as={IconButton}
                size="sm"
                variant="ghost"
                icon={<FaSquare />}
                aria-label="Insert Table"
              />
              <MenuList>
                {pastelColors.map((colorOption) => (
                  <MenuItem
                    key={colorOption.name}
                    onClick={() => handleInsertTitleBox(colorOption.color)}
                  >
                    <Box
                      width="20px"
                      height="20px"
                      bg={colorOption.color}
                      borderRadius="50%"
                      mr="8px"
                    />
                    {colorOption.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Tooltip>
          <Input 
            id="linkText" 
            // {...register("imgurl", {required:false})}
            type='file'
            multiple
            accept='image/jpeg,image/png,image/gif'
            onChange={handleFileChange}
            size="sm"
            style={{ display: 'none' }}
            ref={inputRef}
              />

        <Tooltip label="Image Link">
          <IconButton
          variant={"ghost"}
          icon={<Icon as={MdImageSearch} />}
          aria-label="images link"
          onClick={handleImageLink}
          size="sm"
          title="image link"
          />
        </Tooltip>
        <Tooltip label="Image Upload">
          <IconButton
          variant={"ghost"}
          icon={<Icon as={MdInsertPhoto} />}
          aria-label="Upload images"
          onClick={handleClick}
          size="sm"
          title="image upload"
          />
        </Tooltip>
        <Tooltip label="Movie Upload">
          <IconButton
            variant={"ghost"}
            icon={<MdMovie />}
            aria-label="Upload Movie"
            size="sm"
          />
        </Tooltip>
        </Box>

      </div>

      {/* ==================================== Editor content */}
      <div
        ref={editorRef}
        contentEditable={true}
        onMouseUp={saveSelection}
        onKeyUp={saveSelection}
        style={{
          border: '1px solid #ccc',
          minHeight: '400px',
          padding: '2px',
          borderRadius: "5px",
          outline: "none",
          margin: "4px",
          fontSize: '18px',  // Match font size
          lineHeight: '1.2', // Optional: line height for better text appearance
          whiteSpace: 'pre-wrap' // To preserve whitespaces and line breaks
        }}
        dangerouslySetInnerHTML={{ __html: value }}
        // onInput={(e) => onChange((e.target as HTMLDivElement).innerHTML)}
        onBlur={(e) => onChange(e.currentTarget.innerHTML)}
      />
      {/* ==================================== Editor content */}
    {uploadedImgs.length > 0 ?
      <Button onClick={toggleImages} mb="4" size="xs" m="2">
        {showImages ? 'Hide Images' : `Show Images (${uploadedImgs.length})`}
      </Button>
        : null }

      {showImages ? 
          <Flex flexWrap="wrap">
            {uploadedImgs.map((imgUrl, index) => (
              <Box key={index} m="2" position="relative">
                  <img 
                    key={index} 
                    src={imgUrl} 
                    width="150px" height="150px" 
                    alt={`Uploaded ${index}`} 
                    title={imageids[index]}
                    onClick={() => handleImageClick(index)}
                  />
                  {isImageStatus !== "show" ?
                <IconButton
                  position="absolute"
                  top="0"
                  right="0"
                  aria-label="Remove Image"
                  icon={<CloseIcon />}
                  size="xs"
                  onClick={() => removeImageFile(index)}
                />:null }
              </Box>
            ))}
          </Flex>
          :null}
          {fullscreenImageIndex !== null && (
          <ImageSlide 
            images={uploadedImgs} 
            initialIndex={fullscreenImageIndex}
            onClose={handleCloseFullscreen}
          />
      )}
    </div>
    
  );
};

export default HtmlEditor;