import React, { useEffect, useState } from "react";
import { INote } from "../../apisntypes/notetypes"; 
import axios from "axios";
import { Box, Button, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

type PaginatedData = {
    data: INote[];
    total: number;
    currentPage: number;
    totalPages: number;
    count:number;
    nextPage:any;
  };


function Pagination({
  pageSize,
  count, 
  currentPage, 
  totalPages, 
  handlePageChange,
  nextPage,
}) {
  // console.log('totalPages'+totalPages);
    const total = Math.ceil(count/pageSize)
    const pageNumber = [];
    for (let i=1;i<total+1;i++) {
        pageNumber.push(i);
    }
    return (
        <>
        <IconButton 
          mr="2" 
          aria-label="Left" 
          size="sm" 
          icon={<ChevronLeftIcon />} 
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        />
        {pageNumber.map((n) => (
            <Button
                key={n}
                mr="2" 
                size="sm" 
                onClick={() => handlePageChange(n)} 
                disabled={currentPage === n}
                variant={currentPage === n ? "solid" : "outline"}
            >
                {n}
            </Button>
        ))}
        <IconButton 
          mr="2" 
          size="sm" 
          aria-label="Right" 
          icon={<ChevronRightIcon />} 
          onClick={() => nextPage && handlePageChange(currentPage + 1)} 
          disabled={!nextPage}
        />
      </>
    );
  }

  export default Pagination;