import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";


interface IProtectedPageProps {
  children: React.ReactNode;
}

export default function ProtectedPage({ children }: IProtectedPageProps) {
  const { userLoading, user, isLoggedIn } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
      if (!isLoggedIn && !userLoading) {
        navigate("/login");
      }
  }, [isLoggedIn]);
  return <>{children}</>;
}