import React from "react";
import { Box, CircularProgress, Flex } from "@chakra-ui/react";

const CenteredCircularProgress = () => {
  return (
    <Flex>
    <Box
        width={"100%"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="20vh" // Adjust this value as needed
    >
      <CircularProgress isIndeterminate color="green.300" />
    </Box>
    </Flex>
  );
};

export default CenteredCircularProgress;
