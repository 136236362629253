import { Box, Container, Heading, IconButton, Input, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React, { useRef } from "react";
import ProtectedPage from "../ProtectedPage";
import useUser from '../../lib/useUser';
import { FaUpload } from "react-icons/fa";


const Profile = () => {
  const {userLoading, user, isLoggedIn} = useUser(); 


    const inputRef = useRef(null);
  
    const handleUploadClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        // Handle file upload logic here
        console.log('Selected file:', file);
      }
    };

  return (
        <ProtectedPage>
        
        <Container>
            <Heading as="h1" size="xl" textAlign="center" my={4}>
            {user.username}'s Profile
            </Heading>
            <VStack>

            <Box width="100%" overflowX="auto">
            <Table variant="simple">

            <Tbody>
              <Tr>
                <Td>Email</Td>
                <Td>{user.email}</Td>
              </Tr>
              <Tr>
                <Td>Username</Td>
                <Td>{user.username}</Td>
              </Tr>
              <Tr>
                <Td>Avatar</Td>
                <Td>
                {user.avatar ? (
                    <img src={user.avatar} alt="avatar" width="50px" height="50px" />
                  ) : (
                    ''
                  )}

                </Td>
              </Tr>
              <Tr>
                <Td>Upload Avatar</Td>
                <Td>
                  <IconButton
                    size="sm"
                    variant={"ghost"}
                    aria-label="Upload Avatar"
                    icon={<FaUpload />}
                    onClick={handleUploadClick}
                  />
                  <Input
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
            </VStack>
        </Container>
        </ProtectedPage>
    )
}
export default Profile;