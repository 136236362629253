// chakra ui
import { Box, Button, Container, HStack, VStack, Center, Text, IconButton } from "@chakra-ui/react";
// general
import { useQuery,QueryFunctionContext,QueryKey  } from "@tanstack/react-query";
import React, { useState, useEffect }from 'react';
import { Link, useParams } from "react-router-dom";
// import user
import useUser from "../../lib/useUser";
// import apis
import { getAllNotesByAddr} from "../../apisntypes/noteapis";
import { getCateNotes } from "../../apisntypes/noteapis";
import { deleteFavPack, postFavPack } from "../../apisntypes/packapis";
import { checkPackGroup } from "../../apisntypes/packapis";
import { getNPack, getNPackAddr, openGroup, closeGroup } from "../../apisntypes/packapis";
// import component
import CateNote from "./CateNote";
import Pagination from "../common/Pagination";
import TopPanel from "../common/TopPanel";
// import custom
import { customBgColor } from "../../css/customcolor";
import { boxBlue } from "../../css/customBox";
// import types
import { INPack } from "../../apisntypes/packtypes";
import { INote } from "../../apisntypes/notetypes";
import { GroupPage } from "../pack/GroupPage";
import { FaUserGroup } from "react-icons/fa6";
import { FaStar } from "react-icons/fa"; 
import { useNavigate } from "react-router-dom";
import { GroupCheck } from "../pack/GroupCheck";

interface IAllNotesData {
  count:number;
  next:string | null;
  previous: string | null;
  results: {
    count:number;
    results:INote[];
  }
}

export default function MainNotes() {
  // static
  const { addr } = useParams<{ addr:string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const {userLoading, isLoggedIn, user} = useUser();
  const navigate = useNavigate();
  // usequery
  const { isLoading:isLoadingNPack, data:nPackData } = useQuery<INPack[]>([`npackaddritem`, addr], getNPackAddr);
  const { data, isLoading, isError } = useQuery(['checkGroup',addr], () => checkPackGroup(addr));
    // const [nPackTitle, setNPackTitle] = useState(nPackData?.title);
  const [catePk, setCatePk] = useState("0");
  const [packPk, setPackPk] = useState("0");
  const [isGroup, setIsGroup] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(nPackData && nPackData['isGroupOpen']);
  let nPackTitle = "";
  let creatorusername = "";
  // if (!isLoadingNPack) {console.log(nPackData);}
  //  if (!isLoading) {console.log(data?.isAccepted);}

  useEffect(() => {
  if (!isLoadingNPack && nPackData ) {
    // console.log(nPackDatacreator.username);

    setPackPk(nPackData['id']);
    creatorusername = nPackData['creator'].username;
    nPackTitle = nPackData['title'];
    if (nPackData['pubOpt'] === 'g') {
        setIsGroup(true);
      } else {
        setIsGroup(false);
    }
    setIsGroupOpen(nPackData['isGroupOpen']);
    if (creatorusername === user['username']) {
        setIsGroupAdmin(true);
      } else {
        setIsGroupAdmin(false);
    }
    
  }
}, [nPackData, packPk, isGroup, isGroupOpen, isGroupAdmin]);

  const { isLoading:isLoadingAllNotes, data:allNotesData } = useQuery<IAllNotesData>([`allNotes`, addr, currentPage], getAllNotesByAddr);

  let nextPage = allNotesData?.next || "";
  let count = 0;
  let totalPages =0;
  let orderNumber = 0;
  let orderNumberCateAll =0;

  const allNotesCount = allNotesData?.count || 0;

  orderNumber = allNotesCount - (currentPage-1)*pageSize +1;
  orderNumberCateAll = allNotesCount - (currentPage-1)*pageSize;
  totalPages = Math.ceil(allNotesData?.count / pageSize);
  // func
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  
  // 그룹이고, 어드민이 아니고, 멤버가 아니고, 그룹이 오픈되어 있으면
// 오픈이면 통과
if (isGroup && isLoggedIn) {
    if (data && !isGroupAdmin && !data?.isAccepted ) {
      navigate(`/${addr}/gr`);
    } 
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2289684856548089";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  
    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

return (
  <Container  maxW="container.lg">
    {isGroup && isLoggedIn ?
    <GroupCheck addr={addr} />
    : null }
      {!isGroup || (isGroup && isGroupAdmin || data?.isAccepted) ?
      <>
        <TopPanel 
        packTitle={nPackData && nPackData['title'] || ""} 
        subTitle={""} 
        addr={addr}  cate_pk={parseInt(catePk,10)} 
        />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2289684856548089"
          crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center' }}
          data-ad-client="ca-pub-2289684856548089"
          data-ad-slot="2262906768"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
          <>
            {!isLoadingAllNotes &&  Array.isArray(allNotesData?.results.results) ? (
              <>
                {allNotesData?.results.results.map((note, index) => (
                  <React.Fragment key={note?.pk}>
                    <CateNote
                      key={index}
                      title={note?.title}
                      content={note?.content}
                      creator={note?.creator}
                      link={note?.link}
                      view_count={note?.view_count}
                      rec_count={note?.rec_count}
                      updated_at={note?.updated_at}
                      pk={note?.pk}
                      addr={addr}
                      pack_pk={parseInt(packPk, 10)}
                      cate_pk={note?.cate_pk}
                      prompt={note?.prompt}
                      is_secret={note?.is_secret}
                      count={allNotesCount}
                      orderNumber={--orderNumber}
                      searchStr=""
                    />
                  </React.Fragment>
                ))}
              </>
            ) : null}
            {allNotesCount === 0  ? 
              <Center {...boxBlue} w="40%">
                <VStack>
                  <Text color="gray.700">
                    Please select category 
                  </Text>
                  <Text color="gray.700">
                    first to post a note!
                  </Text>
                </VStack>
              </Center>
            : null }
          </>
          {totalPages > 0 ?
            <Center m="5">
                <Pagination
                  pageSize={pageSize}
                  handlePageChange={handlePageChange}
                  count={allNotesCount}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  nextPage={nextPage}
                  />
              </Center>
          : null }
      </>
      :null}
  </Container>
  
  );
  }