import React from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FaUserNinja, FaLock, FaEnvelope, FaUserSecret } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { userSignUp } from "../../apisntypes/loginapis"; 
import SocialLogin from "./SocialLogin"; // Make sure to provide a proper implementation for this component
import { useNavigate } from "react-router-dom";

interface IForm {
  name: string;
  username: string;
  password: string;
  email: string;
}

export default function SignUpPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForm>();
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = async ({ name, username, password, email }: IForm) => {
    try {
      // Call your signup API function here
      await userSignUp({ name, username, password, email });

      toast({
        status: "success",
        title: "Welcome!",
        description: "You have successfully signed up.",
        position: "bottom-right",
      });

      navigate("/");
    } catch (error) {
      toast({
        status: "error",
        title: "Error!",
        description: "Email is already in use.",
        position: "bottom-right",
      });
    }
  };

  return (
    <VStack w="300px">
        <HStack my={8} w="100%" >
        <Divider borderColor="gray.300" flex="1" w="30%" />
            <Text textTransform={"uppercase"} color="gray.500" fontSize="xs" as="b">
            Or
            </Text>
            <Divider borderColor="gray.300" flex="1" />
      </HStack>
      <InputGroup>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaUserSecret />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.name?.message)}
          required
          {...register("name", {
            required: "Please put your name",
          })}
          variant={"filled"}
          placeholder="Name"
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaEnvelope />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.email?.message)}
          required
          {...register("email", {
            required: "Please put an email",
          })}
          variant={"filled"}
          placeholder="Email"
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaUserNinja />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.username?.message)}
          required
          {...register("username", {
            required: "Please make a username",
          })}
          variant={"filled"}
          placeholder="Username"
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          children={
            <Box color="gray.500">
              <FaLock />
            </Box>
          }
        />
        <Input
          isInvalid={Boolean(errors.password?.message)}
          required
          {...register("password", {
            required: "Please put a password!",
          })}
          variant={"filled"}
          type="password"
          placeholder="Password"
        />
      </InputGroup>
      <Button type="submit" mt={4} colorScheme={"red"} w="100%" onClick={handleSubmit(onSubmit)}>
        Sign Up
      </Button>
      {/* Render SocialLogin component here */}
    </VStack>
  );
}
