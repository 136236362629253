import { Helmet } from 'react-helmet-async';
import { FaFileSignature, FaMoon, FaSignInAlt, FaSignOutAlt, FaSignature, FaSun  } from "react-icons/fa";
import { ImBlogger } from "react-icons/im";
import {
  Avatar,
  Box,
  Text,
  Button,
  HStack,
  IconButton,
  LightMode,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
  Flex,
  Center,
  Container,
  Divider,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
// import LoginModal from "../login/LoginModal";
// import SignUpModal from "../login/SignUpModal";
import { getCommentAlarm, updateCommentAlarm } from "../../apisntypes/commentapis";
//
import { useQuery } from "@tanstack/react-query";
import useUser from "../../lib/useUser";
import { logOut } from "../../apisntypes/loginapis";
import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {FaSearch} from "react-icons/fa";
import { customBgColor } from "../../css/customcolor";
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { Heading, Spacer, Collapse, Stack } from "@chakra-ui/react";
import { AiFillBell } from "react-icons/ai";
import { newTagBoolean } from "../../lib/Utils";
import { motion } from "framer-motion";
const MotionHeading = motion(Heading);

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const [searchString, setSearchString] = useState('');
  const {userLoading, user, isLoggedIn} = useUser();
  const [isAlarm, setIsAlarm] = useState(false);


  const { toggleColorMode } = useColorMode();
  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const onLogOut = async () => {
    const toastId = toast({
      title: "Logging out...",
      description: "See you later...",
      status: "loading",
      position: "bottom-right",
      duration: 1000,
      isClosable: true
    });
  
    try {
      if (isLoggedIn) {
        // Only log out if the user is logged in
        await logOut();
        // user.updateLoggedInStatus(false);
        // setIsLoggedIn(false); // Update isLoggedIn state to false
        toast.update(toastId, {
          status: "success",
          title: "Logged out",
          description: "See you later!",
        });
        navigate("/"); // Redirect to homepage after logging out
      } else {
        console.log("Already logged out");
      }
    } catch (error) {
      console.error("Error logging out:", error.message);
      toast.update(toastId, {
        status: "error",
        title: "Error",
        description: "An error occurred while logging out. Please try again later.",
      });
    }
  };
  
    // usequery
    const { isLoading, data, refetch } = useQuery(
        [`getAlarms`],
        () => getCommentAlarm(),
        {
          enabled: !!user?.username, // Only run the query if username is available
        }
    );

    
  const handleSearch = () => {
    if (searchString.trim() !=="") {
      navigate(`/sn/${searchString}`);
      // refetch();
    }
  } 


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleLink = async (addr,cate_pk,note_pk, comment_pk) => {
    navigate(`/${addr}/nd/${cate_pk}/${note_pk}`);
    // call api updatecommentalarm
    try {
      const data = await updateCommentAlarm(note_pk);
      // console.log("Comment alarm updated successfully", data);
  
      // Refetch data here
      refetch();
    } catch (error) {
      console.error("Error updating comment alarm", error);
    }
  }

  const trimComment = (comment) => {
    return comment.length > 30 ? comment.substring(0, 20) + '...' : comment;
  };

  useEffect(() => {
    if (data) {
      setIsAlarm(data.some(alarm => !alarm.is_read));
    }
  }, [data]);

  return (
    <>
    <Helmet>
        <title>Drimnotes - Your Organized Blogging Platform</title>
        <meta name="description" content="Drimnotes is an organized blog platform driven by ChatGPT with categories to explore and manage content efficiently." />
        <meta name="keywords" content="Blog, Notes, ChatGPT, Drimnotes, Categories, Organized Content" />
        <meta property="og:title" content="Drimnotes" />
        <meta property="og:description" content="Explore and manage your notes with Drimnotes, driven by ChatGPT." />
        <meta property="og:url" content="https://drimnotes.com" />
        <meta property="og:type" content="website" />
         {/* Korean description meta tags  */}
      <meta
        name="description"
        content="드림노트는 ChatGPT로 구동되는 카테고리 기반의 체계적인 블로그 플랫폼으로, 콘텐츠를 효율적으로 탐색하고 관리할 수 있습니다."
      />
      <meta
        property="og:description"
        content="ChatGPT로 구동되는 드림노트에서 노트를 탐색하고 관리하세요."
      />
      </Helmet>
    <Box 
    // {...customBgColor.customBgColorBlue}
    bgColor={"gray.500"}
    alignItems="center"
    bgSize="cover" // Optional: Adjust this based on your image size
    minHeight="120px"
    backgroundImage="url('/logo.jpg')"
    >
      <Flex
        maxW="100%"
        mx="auto"
        py={8}
        px={{ base: 4, md: 8 }}
        alignItems="center"
      >
        <MotionHeading
            as="h1"
            size="lg"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
          >
          <Link to={"/"} className="header-link">
            Drimnotes
          </Link>
        </MotionHeading>
        <Spacer />
        <HStack spacing={2}>
        <InputGroup justifyItems={"right"}>
              <InputLeftElement
                  pointerEvents="none"
                  children={<FaSearch color="gray.500" />}
              />
              <Input 
              type="text" 
              w="10em"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Search content" 
              _placeholder={{ color: "gray.400" }}
              />
          </InputGroup>

           {!user && !userLoading ? (
              <>
                <IconButton
                  onClick={handleLogin}
                  variant={"ghost"}
                  aria-label="Toggle dark mode"
                  icon={<FaSignInAlt />}
                />
                
              </>
            ): (
              <>
              <Menu>
                <MenuButton position="relative">
                  <AiFillBell size="1.5em" />
                  {isAlarm ? (
                    <Flex
                      position="absolute"
                      top="0"
                      right="0"
                      width="10px"
                      height="10px"
                      backgroundColor="red"
                      borderRadius="50%"
                    />
                  ):null}
              </MenuButton>
              <MenuList fontSize={"sm"} maxHeight="300px" overflowY={"auto"}>
                {data && data.length > 0 ? (
                  data.map((alarm, index) => (
                      <MenuItem 
                      key={index} 
                      onClick={()=>handleLink(alarm.addr, alarm.cate_pk, alarm.note_pk, alarm.comment_pk)}
                      _hover={{bg:"gray.100"}}
                      borderBottom="1px solid gray.300" 
                      >
                            {trimComment(alarm.comment)}
                            <span dangerouslySetInnerHTML={{__html:newTagBoolean(alarm.is_read)}} />
                      </MenuItem>
                  ))
                ) : (
                  <MenuItem>No notifications</MenuItem>
                )}
              </MenuList>
            </Menu>

            <Menu>
                  <MenuButton>
                    <Avatar name={user?.name} src={user?.avatar} size={"sm"} />
                  </MenuButton>
                  <MenuList fontSize={"sm"}>
                    <MenuItem textAlign={"center"} title="Toggle dark/light mode">
                      <Center>
                        <IconButton
                          size={"sm"}
                          onClick={toggleColorMode}
                          variant={"ghost"}
                          aria-label="Toggle dark mode"
                          icon={<FaMoon />}
                        />
                      </Center>
                    </MenuItem>
                    <Link to="/profile">
                      <MenuItem>{user?.username} Profile</MenuItem>
                    </Link>
                    <Link to="/mypost">
                      <MenuItem>My Post</MenuItem>
                    </Link>
                    <Link to="/myfavorites">
                      <MenuItem>Favorites</MenuItem>
                    </Link>
                    <Divider />
                    <Link to="/">
                      <MenuItem>Note Packs</MenuItem>
                    </Link>
                    <Link to="/groups">
                      <MenuItem>Group Packs</MenuItem>
                    </Link>
                    <Link to="/mypacks">
                      <MenuItem>My Packs</MenuItem>
                    </Link>
                    <Divider />
                    
                    <Link to="/about">
                      <MenuItem>About</MenuItem>
                    </Link>
                    <MenuItem onClick={onLogOut}>Log out</MenuItem>
                  </MenuList>
                </Menu>
              </>
            )
             } 
       
        </HStack>
      </Flex>
      <HStack pb="4" mx="4" justifyContent={"center"}>
             <Text>Drimnotes is the organized blog model driven by chatGPT with categories</Text>
      </HStack>
      
    </Box>
    </>

  );
}