import { Box } from "@chakra-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools/build/lib/devtools";
import { Outlet} from "react-router-dom";
import Header from "./common/Header";

export default function Root() {
  return (
    <Box>
      <Header />
      <Outlet />
      {/* <ReactQueryDevtools  initialIsOpen={false} /> */}
    </Box>
  );
}