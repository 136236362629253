import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  effect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
// import general
import ProtectedPage from "../ProtectedPage";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from '../../index';
import { useEffect, useRef, useState, useCallback } from 'react';
import useUser from "../../lib/useUser";
// import api
import { createNPack, checkPackAddrExist, getLanguage } from "../../apisntypes/packapis";
// import types
import { INPack } from "../../apisntypes/packtypes";
import {v4 as uuidv4} from 'uuid';
import debounce from 'lodash.debounce';
interface ILanguage {
  id: number;
  language: string;
}

export default function CreatePack() {
  const {register, handleSubmit, formState:{errors}} = useForm();
  const navigate = useNavigate();  
  const toast = useToast();
  const {userLoading, user, isLoggedIn} = useUser();
  const inputRef = useRef(null);
  const [publicOption, setPublicOption] = useState("a"); // Initial option
  const [cateOption, setCateOption] = useState("a"); // Initial option
  const [noteOption, setNoteOption] = useState("a"); // Initial option
  const [commentOption, setCommentOption] = useState("a"); // Initial option
  const [autoCategories, setAutoCategories] = useState(false); // State to track auto categories checkbox
  const [inputValue, setInputValue] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const noSpacePattern = /^\S*$/;
  const [isAddressExists, setAddressExists] = useState(false);
  const [languageSelection, setLanguageSelection] = useState("English");
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(false);
  const [selectedOption3, setSelectedOption3] = useState(false);
  const [isSaveBtnPressed, setIsSaveBtnPressed] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");

  const generateRandomHexCode = () => {
    const uuid = uuidv4().replace(/-/g,'').substring(0,16);
    setInputValue(uuid);
    return uuid;
  };

  const {isLoading:languageLoading, error, data:languageData} = useQuery<ILanguage[], Error>(['getlanguage'], getLanguage);
  const languageArray = ['English', 'Korean', ...(languageData ?? []).map(item => item.language)];

  let providedPrompt = "give me categories by lines \
    only without any other comments when i study given [title] \
    express in [language] ";
  
  useEffect(() => {
    if (!userLoading && !isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // Update the value of "pubOpt" in the form data whenever "publicOption" changes
    setPublicOption(publicOption);
    setCateOption(cateOption);
    setNoteOption(noteOption);
    setCommentOption(commentOption);
  }, [publicOption,cateOption, noteOption, commentOption]);

  useEffect(() => {
    setInputValue(generateRandomHexCode()); // Call generateRandomHexCode to set initial value
  }, []);

  useEffect(() => {
  },[isAddressExists,inputErrorMessage])


  useEffect(() => {

  }, [autoCategories, selectedOption1, selectedOption2, selectedOption3])  

  useEffect(() => {
    if (inputValue.length < 5 && inputValue !== '') {
      setInputErrorMessage('too short');
    }
  },[inputValue])

  const checkAddressExists = async (addr:string) => {
    try {
      const response = await checkPackAddrExist(addr);
      setAddressExists(response.result);
      if (isAddressExists) {
        setInputErrorMessage(response.message);
        console.log(response.message);
      } else {
        setInputErrorMessage("");
      }
    } catch (error) {
        console.error('Error checking address:', error);
        // Handle error appropriately, maybe show a message to the user
    }
  };

  const debouncedCheckAddressExists = useCallback(debounce(checkAddressExists, 300), []);

  const handleInputChange = (event) => {
    let { value } = event.target;
    const hasSpace = /\s/.test(value);

    if (hasSpace) {
      setInputErrorMessage("No space allowed!");
    } else if (value.length < 5) {
      setInputErrorMessage("Too short!");
    } else if (value.length > 50) {
      value = value.slice(0, 50);
      setInputErrorMessage("Too long!");
    } else if (!/^[a-zA-Z0-9]*$/.test(value)) {
      setInputErrorMessage("Only English letters and numbers are allowed!");
      value = value.replace(/[^a-zA-Z0-9]/g, '');
    } else {
      setInputErrorMessage(""); // Clear error message if all checks pass
    }

    const lowercaseValue = value.toLowerCase();
    setInputValue(lowercaseValue);

    if (lowercaseValue.length >= 5 && !hasSpace && /^[a-zA-Z0-9]*$/.test(lowercaseValue)) {
      // debouncedCheckAddressExists(lowercaseValue);
      checkAddressExists(value);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

const mutation = useMutation(createNPack, {
    onSuccess: (data:INPack) => {
        toast({
            status: "success",
            title: "NotePack is created",
            position: "bottom-right",
        });
        // generate categories
        data.isAutoCategories = autoCategories
        navigate("/mypacks");
    },
  });


 const onSubmit = async (formData:INPack) => {
  setIsSaveBtnPressed(true);
    formData.pubOpt = publicOption;
    formData.noteOpt = noteOption;
    formData.cateOpt = cateOption;
    formData.commentOpt = commentOption;
   if (selectedOption1 && selectedOption2) {
     formData.prompt = providedPrompt;
   } 
   if (selectedOption1 && selectedOption3) {
    formData.prompt = customPrompt;
   }
    formData.isAutoCategories = autoCategories;
    formData.language = languageSelection;
    mutation.mutate(formData);
 }

const handlePublicOptChange = (event) => {
  setPublicOption(event);
};

const handleCateOptChange = (event) => {
  setCateOption(event);
};

const handleNoteOptChange = (event) => {
  setNoteOption(event);
};

const handleCommentOptChange = (event) => {
  setCommentOption(event);
};

const handleLanguageSelect = (event) => {
  setLanguageSelection(event.target.value);
}

const handleAutoCategoriesChange1 = (event) => {
  const value = event.target.checked;
  setSelectedOption1(value);
  if (value === true) {
    setAutoCategories(true);
    setSelectedOption2(value);
    setSelectedOption3(false);
  } else {
    setAutoCategories(false);
    setSelectedOption2(false);
    setSelectedOption3(false);
  }
  
};

const handleAutoCategoriesChange2  = (event) => {
  const value = event.target.checked;
  console.log(value);
  if (value === true) {
    console.log('2');
    setSelectedOption1(true);
    setSelectedOption2(true);
    setSelectedOption3(false);
  } else {
    setSelectedOption1(true);
    setSelectedOption2(false);
    setSelectedOption3(true);
  }
  
};

const handleAutoCategoriesChange3 = (event) => {
  const value = event.target.checked;
  if (value === true) {
    setSelectedOption1(true);
    setSelectedOption2(false);
    setSelectedOption3(true);
  } else {
    setSelectedOption1(true);
    setSelectedOption2(true);
    setSelectedOption3(false)
  }
};

const handleCustomPrompt = (event) => {
  const newValue = event.target.value;
  setCustomPrompt(newValue);
}

useEffect(()=>{

},[languageSelection])


  return (
        <Container mt="5">
          <form onSubmit={handleSubmit(onSubmit)}>
          <Heading textAlign={"center"}>Create Pack </Heading>
          <VStack 
              spacing={4}
              mt={5}
          >
            <FormControl>
              <FormLabel>Subject</FormLabel>
              <Input  
                autoFocus
                type="text" 
                {...register("title", {required:true})}
                required 
                />
            </FormControl> 
              {/* ==== addr */}
            <FormControl>
              <FormLabel>Addr</FormLabel>
              <Text>
                https://drimnotes.com/{inputValue}
              </Text>
              <Input  
                ref={inputRef}
                type="text" 
                value={inputValue}
                {...register("addr", {
                  required:true, 
                  minLength: 4, 
                  maxLength: 50,
                })}
                // onFocus={handleInputFocus}
                onChange={handleInputChange}
                required 
                />
                <Text fontSize={"xs"} color="pink">{inputErrorMessage}</Text>
            </FormControl> 

            <FormControl>
              <FormLabel> Language</FormLabel>
              <Select
              onChange={handleLanguageSelect}
              >
                <option value="English">English</option>
                <option value="Korean">Korean</option>
                {languageData && languageData.map(language => (
                <option key={language.id} value={language.language}>
                  {language.language}
                </option>
              ))}
              </Select>
            </FormControl>

            <FormControl> 
            <HStack w="100%" h="150px">
                <Text w="50%" textAlign="center">description</Text>
                <Textarea  w="400px" h="100px" borderRadius="5px" 
                {...register("desc", {
                  required:true, 
                })}
                >

                </Textarea>
            </HStack>
            </FormControl> 

            <FormControl>
              <HStack w="100%">
              <Text w="180px" textAlign="center" >
                <FormLabel as="legend">Choose display option:</FormLabel>
              </Text>
              <RadioGroup
                {...register("pubOpt")} 
                name="pubOpt"
                value={publicOption}
                onChange={setPublicOption}
                ml="20"
                style={{ alignItems: 'left' }}
              >
                <HStack justifyContent={'space-evenly'}>
                  <Radio value="a" >Public</Radio>
                  <Radio value="g">Group</Radio>
                  <Radio value="n">Private</Radio>
                </HStack>
              </RadioGroup>
            </HStack>
            </FormControl>

            { publicOption === 'a' || publicOption === "g" ?
            <>
            <FormControl>
              <HStack w="100%">
              <Text w="180px" textAlign="center" >
              <FormLabel as="legend">
                Allow post category:
              </FormLabel>
              </Text>
              <RadioGroup
                {...register("cateOpt")} 
                name="cateOpt"
                value={cateOption}
                onChange={setCateOption}
                ml="20"
                style={{ alignItems: 'left' }}
              >
                <Stack direction="row" spacing={10}>
                  <Radio value="a">Enable</Radio>
                  <Radio value="n">Disable</Radio>
                </Stack>
              </RadioGroup>
            </HStack>
            </FormControl>

            <FormControl>
              <HStack w="100%">
              <Text w="180px" textAlign="center" >
                <FormLabel as="legend">
                    Allow Post a note:
                </FormLabel>
                </Text>
              <RadioGroup
                {...register("noteOpt")} 
                name="noteOpt"
                value={noteOption}
                onChange={setNoteOption}
                ml="20"
                style={{ alignItems: 'left' }}
              >
                <Stack direction="row" spacing={10}>
                  <Radio value="a">Enable</Radio>
                  <Radio value="n">Disable</Radio>
                </Stack>
              </RadioGroup>
              </HStack>
            </FormControl>

            <FormControl>
              <HStack w="100%">
                <Text w="180px" textAlign="center" >
                  <FormLabel as="legend">
                      Allow post a comment:
                  </FormLabel>
                  </Text>
              <RadioGroup
                {...register("commentOpt")} 
                name="commentOpt"
                value={commentOption}
                onChange={setCommentOption}
                ml="20"
                style={{ alignItems: 'left' }}
              >
                <Stack direction="row" spacing={10}>
                  <Radio value="a">Enable</Radio>
                  <Radio value="n">Disable</Radio>
                </Stack>
              </RadioGroup>
            </HStack>
            </FormControl>
              </>
              : null 
            }
            {/* ============================prompt */}
            <FormControl>
              <HStack w="100%" justifyContent={"space-between"}>
                  <Checkbox name="autocate1" isChecked={selectedOption1} onChange={handleAutoCategoriesChange1}>Catetories by AI</Checkbox>
                  {/* <Checkbox name="autocate1"  isChecked={selectedOption2}  onChange={handleAutoCategoriesChange2}>provided</Checkbox> */}
                  {/* <Checkbox name="autocate1"  isChecked={selectedOption3} onChange={handleAutoCategoriesChange3}>Custom prompt</Checkbox> */}
              </HStack>
            </FormControl> 
                {selectedOption1 && selectedOption2 &&
                <Textarea w="100%" h="100px" readOnly>{providedPrompt}</Textarea>
                }
                {selectedOption1 && selectedOption3 && (
                <Textarea w="100%" h="100px" onChange={handleCustomPrompt}>{customPrompt}</Textarea>
                )}

            {/* ============================prompt */}
            <HStack w="100%" mt={5}>
              {isAddressExists ?
              <>
                <Button
                  colorScheme={"blue"} 
                  size="lg" 
                  w="100%"
                  onClick={scrollToTop}
                  >
                  Create Pack
                </Button>
              </>
              :
              <>
              {!isSaveBtnPressed ?
                <>
                  <Button 
                    colorScheme={"blue"} 
                    size="lg" 
                    w="100%"
                    type="submit"
                    >
                    Create Pack
                  </Button>
                </>
                :
                <>
                  <Button
                    colorScheme="blue"
                    size="lg"
                    w="100%"
                    variant={"ghost"}>
                      Create Pack
                    </Button>
                </>
                }
              </>}
            
            </HStack>
          </VStack>
          </form>
        </Container>
  );
}