import { Button, HStack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaUserGroup } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { checkPackGroup } from "../../apisntypes/packapis";
import { INPack } from "../../apisntypes/packtypes";
import { getNPackAddr } from "../../apisntypes/packapis";
import useUser from "../../lib/useUser";

export const GroupCheck = ({addr}) => {
    const {isLoggedIn, user} = useUser();
    const { data, isLoading, isError } = useQuery(['checkGroup',addr], () => checkPackGroup(addr));
  const { isLoading:isLoadingNPack, data:nPackData } = useQuery<INPack[]>([`npackaddritem`, addr], getNPackAddr);
  const [isGroup, setIsGroup] = useState(false);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);

    useEffect(() => {
        if (!isLoadingNPack && nPackData ) {
          // console.log(nPackDatacreator.username);
      
          if (nPackData['pubOpt'] === 'g') {
              setIsGroup(true);
            } else {
              setIsGroup(false);
          }
          if (nPackData['creator']?.username === user['username']) {
              setIsGroupAdmin(true);
            } else {
              setIsGroupAdmin(false);
          }
          
        }
      }, [nPackData,  isGroup,  isGroupAdmin]);

  return (
      <>
      <HStack>
        {/* {isLoggedIn && nPackData} */}
      {isGroup  ?   
      <>
        <FaUserGroup />
        <Link to={`/${addr}/gr`}>
            {isGroupAdmin ? 
              <>
              <Button size="xs">
                  Admin
              </Button> 
              </>:
              <Button size="xs">
                  Group page
              </Button>
              }
          </Link>
          {!isGroupAdmin && data?.isAccepted ? 
            <>
              <Text size="2xs" color="gray">You are a member</Text>
            </>
            :null
          }
        </>
        :null}      

      </HStack>
      </>
  )
}