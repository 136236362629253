import React, { useState, useEffect } from "react";
import { Container, VStack, Text, Textarea, Button, Center, HStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query"; // Import the useMutation hook
import { useNavigate } from 'react-router-dom';
// import apis
import { ICategory } from "../../apisntypes/categorytypes";
import { IUpdatePrompt } from "../../apisntypes/commontypes";
import { getCategory} from "../../apisntypes/categoryapis";
import { updatePrompt } from "../../apisntypes/commonapis"; 

const Prompt = () => {
    const { cate_pk } = useParams();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const { isLoading, data } = useQuery<ICategory>(["category", parseInt(cate_pk, 10)], getCategory);

    const { register: registerEdit, handleSubmit, setValue } = useForm<IUpdatePrompt>({
        defaultValues: {
            prompt: data?.prompt,
        },
    });
    const handleCancelButtonClick = () => {
        // Go back to the previous page
        navigate(-1);
      };
    const mutation = useMutation((formData: IUpdatePrompt) => {
        return updatePrompt(parseInt(cate_pk, 10), formData.prompt); // Implement updatePrompt in your API module
    });

    const [latestPrompt, setLatestPrompt] = useState(data?.prompt);

    useEffect(() => {
        // Update the latest prompt when the API data changes
        setLatestPrompt(data?.prompt);
    }, [data]);

    const onSave = async (formData: IUpdatePrompt) => {
        // Check if formData.prompt is not undefined
        if (formData.prompt !== undefined) {
            try {
                await mutation.mutateAsync(formData);
                setIsEditing(false); // Exit edit mode after successful mutation
            } catch (error) {
                console.error("Mutation error:", error);
            }
        } else {
            console.error('Prompt data is undefined.');
            // Handle the error or display a message to the user
        }
    };
    return (
        <Container w="container.lg" mt="6">
            <Center>
                <Text fontSize={"xl"}>
                    {data?.title}
                </Text>
            </Center>
            {!isEditing ? (
                <>
                    <Center m="8">{latestPrompt}</Center>
                    <HStack justifyContent={"space-between"}>
                        <Button onClick={() => setIsEditing(true)}>Edit</Button>
                        <Button onClick={handleCancelButtonClick}>Cancel</Button>
                    </HStack>
                </>
            ) : (
                <form onSubmit={handleSubmit(onSave)}>
                    <Center m="8">
                        <Textarea
                            name="prompt"
                            {...registerEdit("prompt")} // Register the "prompt" field
                            value={latestPrompt}
                            onChange={(e) => setLatestPrompt(e.target.value)}
                        />
                    </Center>
                    <HStack justifyContent={"space-between"}>
                        <Button type="submit">Save</Button>
                        <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                    </HStack>
                </form>
            )}
        </Container>
    );
};

export default Prompt;
