import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  VStack,
  Grid,
  Heading,
  HStack,
  Center,
  Button,
  CircularProgress,
  useToast, useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import NPackItem from "./pack/NPackItem";
// import apis
import { getNPacks } from "../apisntypes/packapis";
import { INPack } from "../apisntypes/packtypes";
import { cusColorScheme } from "../css/customTheme";
import { PackMenu } from "./pack/PackMenu";

interface NPacksApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: INPack[]; // Define INPack interface according to the structure of your data
}
export default function Home() {
  
  const [page, setPage] = useState(1);
  const [data, setData] = useState<NPacksApiResponse>({ count: 0, next: null, previous: null, results: [] });
  const [totalItems, setTotalItems] = useState(0); // Track total number of items
  const [isLoading, setIsLoading] = useState(false);
  const [currentItemsCount, setCurrentItemsCount] = useState(0); // Track current number of items displayed
  const [renderCount, setRenderCount] = useState(1); // Counter for component render count
  const toast = useToast();
  const [isLargeScreen] = useMediaQuery("(min-width: 768px)"); // Change the breakpoint as needed
  const [favoritePacks, setFavoritePacks] = useState<  number []>([]);


  const fetchNPacks = async (pageNum: number) => {
    setIsLoading(true);
    try {
      const response = await getNPacks(pageNum);
      if (response) {
        // Clear existing results if it's the initial fetch (page === 1)
        const newData = pageNum === 1 ? response.results : [...data.results, ...response.results];
        // Extract the list of favorite packs from the response
        const favoritePackIds = response.results
        .filter(pack => pack.isFavorite === 'y')
        .map(pack => pack.id);

        setFavoritePacks(favoritePackIds);

        setData((prevData) => ({
          ...prevData,
          results: newData,
        }));

        setPage((prevPage) => prevPage + 1);
        setTotalItems(response.count); 
        setCurrentItemsCount(prevCount => prevCount + response.results.length); // Update current items count
      } else {
        console.error("Empty response received from server");
        toast({
          status: "error",
          title: "Empty response received from server",
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        status: "error",
        title: "Error fetching data",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // if (!isLoading) {
  //   console.log(data);
  // }
  useEffect(() => {
    fetchNPacks(page);
  }, []); // Fetch initial data when component mounts
  

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.body.scrollHeight;
    if (scrollPosition >= pageHeight - 20 && !isLoading && currentItemsCount < totalItems) {
      fetchNPacks(page);
    }
  };

  // useEffect(() => {
  //   // Extract the list of favorite packs from the current data results
  //   const favoritePackIds = data.results.filter(pack => pack.isFavorite === 'y').map(pack => pack.id);
  //   setFavoritePacks(favoritePackIds);
  // }, [data.results]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, data.results.length, totalItems]); // Add/remove scroll event listener based on loading state

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2289684856548089";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  
    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container maxW="container.lg" py={"6"}>
      <VStack>
        <Box w="100%" textAlign={"center"} >
          <Heading pr="25px">All Note Packs</Heading>
        </Box>
        <PackMenu nPackPage="pub" />
      </VStack>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2289684856548089"
          crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center' }}
          data-ad-client="ca-pub-2289684856548089"
          data-ad-slot="2262906768"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
      <Container maxW="container.lg">
        {data && Array.isArray(data.results) ? (
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)", // 2 columns for small screens
            sm: "repeat(2, 1fr)",  // 3 columns for small to medium screens
            md: "repeat(3, 1fr)",  // 4 columns for medium to large screens
            lg: "repeat(4, 1fr)",  // 5 columns for large screens and above
          }}
          gap={4}
          alignItems="stretch"
          my="2"
      >
          {data.results.map((mcate, index) => (
            <NPackItem
              key={index}
              pk={mcate.id}
              pack_pk={mcate.id}
              title={mcate.title}
              addr={mcate.addr}
              desc={mcate.desc}
              inputString=""
              creator={mcate.creator}
              postCount={mcate.postCount}
              cateCount={mcate.cateCount}
              prompt={mcate.prompt}
              pubOpt={mcate.pubOpt}
              isGroupOpen={mcate.isGroupOpen}
              cateOpt={mcate.cateOpt}
              noteOpt={mcate.noteOpt}
              commentOpt={mcate.commentOpt}
              updated_at={mcate.updated_at}
              renderCount={renderCount+index}
              nPackPage="pub"
              isFavorite={mcate.isFavorite}
              favoritePacks={favoritePacks}
              language={mcate.language}
              setFavoritePacks={setFavoritePacks}
              is_newpost={mcate.is_newpost}
            />
          ))}
          {isLoading && <CircularProgress isIndeterminate color="green.300" />}
        </Grid>
      ) : (
        <Center>Loading...</Center>
      )}

    </Container>

    </Container>
  );
}