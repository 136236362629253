import { ICategory } from './categorytypes';
import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})


export const getAllCategoriesBypack_pk = ({queryKey}:QueryFunctionContext) => {
    const [_,pack_pk] = queryKey;
    return instance.get(`categories/all/${pack_pk}`)
    .then(response => response.data);
    }

    export const getAllCategoriesByAddr = ({queryKey}:QueryFunctionContext) => {
        const [_,addr] = queryKey;
        return instance.get(`categories/all/${addr}`)
        .then(response => response.data);
        }
    

export const getMyCates = () => 
    instance.get("categories/mycates/")
    .then(response => response.data);


export const postCategory = (variables: ICategory) => {
    return instance.post(
    `categories/rootpost`,
    variables,
    {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then((response) => response.data);
}

export const updateCategory = ( pk: number, title: string) => {
    return instance.put(
    `categories/update/${pk}`, {title:title}, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
.then((response) => response.data);}

export const categoryDelete =  (cate_pk:number) => {
    return instance.delete(
        `categories/delete/${cate_pk}`,
    {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then(response =>  response.data)
}


export const getCategory = ({queryKey}:QueryFunctionContext) => {
    const [_,cate_pk] = queryKey;
    return instance.get(`categories/getcategory/${cate_pk}`)
    .then(response => response.data); 
    }


export const createAutoSubCate = (variables) => {
    return instance.post(
        `categories/postautosubcate`,
        variables,
        {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
        })
        .then((response) => response.data);
    }