// import Quill from 'quill';
import {v4 as uuidv4} from 'uuid';
import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Center,
    Checkbox,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Text,
    Textarea,
    useToast,
    VStack,
  } from "@chakra-ui/react";
  import ProtectedPage from "../ProtectedPage";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import { useNavigate, useParams } from "react-router-dom";
  import { useForm } from "react-hook-form";
  import DOMPurify from "dompurify";
  import { useQueryClient } from "@tanstack/react-query";
  import useUser from '../../lib/useUser';
  // import apies
  import { postNote } from "../../apisntypes/noteapis";
  import { getCategory } from '../../apisntypes/categoryapis';
  import { getAllCategoriesByAddr } from '../../apisntypes/categoryapis';
  import {  uploadImage } from '../../apisntypes/commonapis';
  import { secretChange } from '../../apisntypes/noteapis';
  // import types
  import { INote } from '../../apisntypes/notetypes'; 
  import { ICategory } from '../../apisntypes/categorytypes';
  import "../../css/font.css";
import HtmlEditor from './HtmlEditor';
import { FaLock } from 'react-icons/fa';
  
export default function PostNote() {
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'image',
  ];
  const {userLoading, user, isLoggedIn} = useUser(); 
  const {addr,cate_pk} = useParams();
  const [updatedcate_pk, setUpdatedcate_pk] = useState(parseInt(cate_pk,10));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isLoading:allCateLoading, data:allCateData } = useQuery<ICategory[]>([`allCategoriesByAddr`, addr], getAllCategoriesByAddr);
  const options: ICategory[] = allCateData;
  const cate_pkNumber = parseInt(cate_pk, 10);
  const [editingMode, setEditingMode] = useState(true); 
  const [isButtonActive, setIsButtonActive] = useState(true);
  const contentRef = useRef(null);
  const [content, setContent] = useState('');
  const [safeContent, setSafeContent] = useState("");
  const {register, handleSubmit, setValue, reset, formState} = useForm<INote>();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImgs, setUploadedImgs] = useState([]);
  const [imageids, setImageids] = useState([]);
  const [isSecret, setIsSecret] = useState(false);
  const toast = useToast();

  const generateRandomHexCode = () => {
    const uuid = uuidv4().replace(/-/g,'').substring(0,12);
    return uuid;
  };
  const [noteImageId, setNoteImageId] = useState(generateRandomHexCode());

  const secretMutation = useMutation(secretChange);
  
  useEffect(() => {
    setIsSecret(isSecret);
  }, [isSecret])

  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      setUploadedImgs((prevImgs) => [...prevImgs, result.variants[0]]);
      setImageids((prevImgIds) => [...prevImgIds, result.id]);

    },
    onError: (error: any) => {
      console.error("Upload failed:", error);
    }
  });

  const postNoteMutation = useMutation(postNote, {
    onSuccess: (data:INote) => {
      setIsButtonActive(true); 
        toast({
            status: "success",
            title: "Note posted",
            position: "bottom-right",
        });
        queryClient.invalidateQueries(["category", cate_pk]);
        queryClient.invalidateQueries(["allCategoriesByAddr", addr]);
        queryClient.invalidateQueries(["cateNotes", addr, updatedcate_pk,1]);
        queryClient.invalidateQueries(["cateNotes", addr, updatedcate_pk]);
        navigate(`/${addr}/cn/${cate_pk}`);
    },
    onError: () => {
      setIsButtonActive(true);
      toast({
        status: "error",
        title: "Failed to post note",
        position: "bottom-right",
      });
    }
});


  // usequery
  const { isLoading, data } = useQuery<ICategory>(
    [`category`, cate_pk],
    getCategory,
    {
      enabled: cate_pk && cate_pkNumber > 0, // Enable the query only when cate_pk is greater than 0
    }
  );
  
  // func
  function handleTabKeyPress(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (event.key === "Tab") {
      event.preventDefault();
      const currentInput = event.target as HTMLInputElement | HTMLTextAreaElement;
      const formInputs = Array.from(document.querySelectorAll('input, textarea')) as Array<HTMLInputElement | HTMLTextAreaElement>;
      const currentIndex = formInputs.indexOf(currentInput);
      const nextIndex = (currentIndex + 1) % formInputs.length;
      formInputs[nextIndex].focus();
    }
  }

  const handlecate_pkChange = (event) => {
    const newcate_pk = event.target.value;
    setUpdatedcate_pk(newcate_pk);
  };

  useEffect(() => {
    setUpdatedcate_pk( updatedcate_pk);
  }, [updatedcate_pk]);

  useEffect(() => {
    setNoteImageId(noteImageId); // Call generateRandomHexCode to set initial value
  }, [noteImageId]);

  
  // 에디터 내용이 변경될 때마다 state 업데이트
  const handleChange = (value: string) => {
    setContent(value);
    setValue('content', value, {shouldDirty: true});

  };
  
  
  useEffect(()=> {
    setIsButtonActive(true);
  }, [updatedcate_pk])



  const onSubmit = (data:INote) => {
    setIsButtonActive(false);

    const imgData = new FormData();

    const partialNoteData: Partial<INote> = {
      title: data.title,
      content: content,
      cate_pk: updatedcate_pk || options[0]?.pk, // Assuming options is defined somewhere
      rec_count: 0,
      addr: addr,
      is_secret:isSecret,
      noteimageid: noteImageId,
      imageURLs: uploadedImgs,
    };
  
    const notePostData: INote = partialNoteData as INote;

    postNoteMutation.mutate(notePostData);
    // Append image files to the FormData object

  }

  const handleTextareaChange = (event) => {
    const userInput = event.target.value;
    // Use DOMPurify to sanitize the input
    const sanitizedContent = DOMPurify.sanitize(userInput);
    setContent(userInput);
    setSafeContent(sanitizedContent);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? true:false ;
    setEditingMode(newValue);
  };

  const handleImageInsert = (imgUrl) => {
    // Create an image HTML tag and insert it into the editor's content
    const imgTag = `<p><img src="${imgUrl}" alt="Image" style="width:100%;margin-bottom:10px;" /></p><p><br></p>`;
    setContent((prevContent) => prevContent + imgTag);
    setValue('content', content + imgTag, {shouldDirty: true});
  };
  // console.log('post upload :'+uploadedImgs);

  const handleScret = ( )=> {
    setIsSecret(!isSecret);
  }

  return (
    <ProtectedPage>
      <Box
        maxW={"container.lg"} 
        mx="auto"
        px="6"
        pt={10}
      >
      <div>
    <Checkbox
      id="editModeCheckbox"
      className="toggleCheckbox"
      onChange={handleCheckboxChange}
      isChecked={editingMode === true}
    >
      Html
    </Checkbox>
  </div>
          <Center>
            
            <Text fontSize="lg" mr="4">Note Post to </Text>
            <Text fontSize="2xl">{data?.title}</Text>
          </Center>
          <VStack 
              onSubmit={handleSubmit(onSubmit)}
              spacing={4} 
              as="form" 
              mt={5}
          >
            <FormControl>
              <Select 
              onChange={handlecate_pkChange}
              placeholder="Select a category"
              defaultValue={updatedcate_pk}
              // {...register("cate_pk", {required:true})} 
              >
                {options && options.map((category) => (
                    <option 
                    value={category.pk} 
                    key={category.pk}
                    >
                      {`\u00A0`.repeat(category.depth * 2)}{category.title}
                    </option>
                  
                ))}
              </Select>
            </FormControl>   
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input onKeyDown={handleTabKeyPress} {...register("title", {required:true})} required type="text" />
            </FormControl>

            <FormControl>
              <FormLabel>Content</FormLabel>
              {editingMode === true ? (
                <>
                  <HtmlEditor 
                  value={content} 
                  onChange={handleChange}
                  isImageStatus="post" 
                  noteimageid={noteImageId}
                  uploadedImgs={uploadedImgs}
                  setUploadedImgs={setUploadedImgs}
                  imageids={imageids}
                  setImageids={setImageids}
                  onImageInsert={handleImageInsert}
                  />
                </>
              ):(
                <>
                  <Textarea 
                    style={{ 
                      height: '350px', 
                      fontSize:"18px",
                      lineHeight: '1.2',
                      resize:"both",
                      marginBottom: '30px' ,
                      whiteSpace: "pre-wrap"
                    }}
                    value={content} 
                    onChange={handleTextareaChange} 
                    mb="10"
                    >
                  </Textarea>
                </>
              ) }
              </FormControl>
            
              <FormControl>
              <HStack mx="2">
                <input type="checkbox" 
                  {...register('is_secret', {required:false})} 
                  onChange={handleScret}
                  />
                <FaLock size="12px" />
                </HStack>
              </FormControl>

              <FormControl>
                <input type="hidden" {...register("cate_pk", {required:true})} value={updatedcate_pk} />
              </FormControl>

              <FormControl>
                <input type="hidden" {...register("rec_count", {required:true})} value="0"  />
              </FormControl>

              <FormControl>
                <input type="hidden" {...register("addr", {required:true})} value={addr}  />
              </FormControl>

          <Button 
              isLoading={uploadImageMutation.isLoading}
              type="submit"
              colorScheme={"green"} 
              size="lg" 
              w="100%"
              isDisabled={!isButtonActive  || updatedcate_pk === 0} // Disable the button when isButtonActive is false
          >
            {isButtonActive ? 'Post' : 'Posting...'}
          </Button>
          </VStack>
      </Box>
    </ProtectedPage>
  );
}