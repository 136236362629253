// chakra ui
import { Box, Button, Container, HStack, VStack, Center, Text } from "@chakra-ui/react";
// general
import { useQuery,QueryFunctionContext,QueryKey, QueryClient  } from "@tanstack/react-query";
  import { useQueryClient } from "@tanstack/react-query";
  import React, { useState, useEffect }from 'react';
import { Link, useParams } from "react-router-dom";
// import user
import useUser from "../../lib/useUser";
// import apis
import { getCateNotes } from "../../apisntypes/noteapis";
import { getCategory } from "../../apisntypes/categoryapis";
import { getNPackAddr } from "../../apisntypes/packapis";
// import component
import CateNote from "./CateNote";
import Pagination from "../common/Pagination";
import TopPanel from "../common/TopPanel";
import Categories from "../category/Categories";
// import custom
import { customBgColor } from "../../css/customcolor";
import { boxBlue } from "../../css/customBox";
// import types
import { INPack } from "../../apisntypes/packtypes";
import { INote } from "../../apisntypes/notetypes";
import { ICategory } from "../../apisntypes/categorytypes";
import { IAllNotesData } from "../../apisntypes/notetypes";
import { GroupPage } from "../pack/GroupPage";
import { GroupCheck } from "../pack/GroupCheck";
// import Adsense from "../common/Adsense";


export default function CateNotes() {
  // static
  const { addr, cate_pk } = useParams<{ addr:string; cate_pk: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pack_pk, setpack_pk] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const {userLoading, isLoggedIn, user} = useUser();
  // usequery
  const { isLoading:isLoadingNPack, data:NPackData } = useQuery<INPack[]>([`npackitem`, addr], getNPackAddr);
  const { isLoading:isLoadingCate, data:CateData } = useQuery<ICategory[]>([`cateitem`, cate_pk], getCategory);
  const { isLoading:isLoadingCateNotes, data:cateNotesData } = useQuery<IAllNotesData>([`cateNotes`, addr, cate_pk, currentPage], getCateNotes);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (NPackData && NPackData.length > 0) {
      setpack_pk(NPackData['id']);
      
    }
  }, [NPackData]);

  useEffect(() => {
    queryClient.invalidateQueries(["cateNotes", addr, cate_pk,1]);
    
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []); 

  // dynamic
 let subTitle = "";
  if (!isLoadingNPack) {
    // Assuming NPackData is an array
    const firstNPackItem = NPackData.length > 0 ? NPackData[0] : null;
    // Accessing the creator property directly if the first item exists
  }
  if (!isLoadingCate) {
    // Log CateData to the console to inspect its structure
    // console.log("CateData:", CateData);
  
    if (Array.isArray(CateData)) {
      // Check if CateData is an array and not empty
      if (CateData.length > 0) {
        // Access the title property from the first item in the array
        subTitle = CateData[0]?.title || '';
  
        // Use the title as needed
      } else {
        // Handle the case where CateData is an empty array
        console.error("CateData is an empty array.");
      }
    } else {
      // Use type assertion to inform TypeScript about the expected type
      subTitle = (CateData as ICategory)?.title || '';
  
    }
  }
  
  let nextPage = cateNotesData?.next || "";
  let count = 0;
  let totalPages =0;
  let orderNumber = 0;
  let orderNumberCateAll =0;
  const cateNotesCount = cateNotesData?.count || 0;


  orderNumber = count - (currentPage-1)*pageSize;
  orderNumberCateAll = cateNotesCount - (currentPage-1)*pageSize;
  totalPages = Math.ceil(cateNotesData?.count / pageSize);

  // func
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
    <Container  maxW="container.lg">
      <GroupCheck addr={addr}  />
      <TopPanel 
      packTitle={NPackData && NPackData['title'] || ""} 
      subTitle={CateData && CateData['title'] || ""} 
      addr={addr}
      cate_pk={parseInt(cate_pk,10)} />
      
        {!isLoadingCateNotes &&  Array.isArray(cateNotesData?.results.results)   ? 
          <>
          {cateNotesData?.results.results.map((note, index) => (
            <React.Fragment key={note?.pk}>
            <CateNote 
              key={index}
              title={note?.title}
              content={note?.content}
              creator={note?.creator}
              link={note?.link}
              view_count={note?.view_count}
              rec_count={note?.rec_count}
              updated_at={note?.updated_at}
              pk={note?.pk}
              addr={addr}
              pack_pk={note?.pack_pk}
              cate_pk={note?.cate_pk}
              prompt={note?.prompt}
              is_secret={note?.is_secret}
              count={cateNotesCount}
              orderNumber={orderNumberCateAll--}
              searchStr=""
              />
            </React.Fragment>
          ))}
          </> : null }
        {cateNotesCount === 0 && pack_pk === cate_pk ? 
          <Center {...boxBlue} w="40%">
              <VStack>
            <Text color="gray.700">
              Please select category 
            </Text>
            <Text color="gray.700">
              first to post a note!
            </Text>
            </VStack>
          </Center>
        : null }          
        {cateNotesCount > pageSize ?
          <Center m="5">
              <Pagination
                pageSize={pageSize}
                handlePageChange={handlePageChange}
                count={cateNotesCount}
                currentPage={currentPage}
                totalPages={totalPages}
                nextPage={nextPage}
                />
            </Center>
        : null }
      </Container>
      </>
  );
      }