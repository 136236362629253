import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})


export interface IUsernameLoginVariables {
    username: string;
    password: string;
}
export interface IUsernameLoginSuccess {
    ok: string;
}
export interface IUsernameLoginError {
    error: string;
}

export interface IUserSignUpVariables {
    name: string;
    username: string;
    password: string;
    email: string;
}

export const getMe = async () => {
    try {
        const response = await instance.get(`users/me`);
        return response.data;
        } catch (error) {
            // Handle 403 Forbidden error
            if (error.response && error.response.status === 403) {
                // console.log("User is not logged in.");
                return false; // Or return an empty object {}
            } else {
                // Handle other errors
                // console.error("Error fetching user data:", error);
                throw error; // Optionally rethrow the error if you want to handle it elsewhere
            }
        }
};


export const logOut = () => {
    return instance.post(`users/log-out`,null, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    })
    .then((response) => response.data);
}

export const usernameLogIn = ({
    username,
    password,
    }: IUsernameLoginVariables) =>
    instance
        .post(
        `/users/log-in`,
        { username, password },
        {
            headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
        )
        .then((response) => response.data)
        .catch((error) => {
            if (error.response) {
              // Handle the error response from the server
            //   console.error('Server Error:', error.response.data);
              throw new Error(error.response.data.error || 'Server Error');
            } else if (error.request) {
              // Handle no response from the server
            //   console.error('No Response:', error.request);
              throw new Error('No Response from Server');
            } else {
              // Handle other errors
            //   console.error('Error:', error.message);
              throw new Error(error.message);
            }
          });

      
export const userSignUp = ({
    name,
    username,
    password,
    email,
}: IUserSignUpVariables) =>
    instance
        .post(
            `users/`,
            { name, username, password, email },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            }
        )
        .then((response) => response.data);
