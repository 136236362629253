import { FaRegHeart,  FaRegCommentDots, FaRegEye, FaRegThumbsUp, 
  FaThumbsUp, FaHeart,
  FaLock} from "react-icons/fa";
import dayjs from 'dayjs';
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { INote } from "../../apisntypes/notetypes";
import { postView } from "../../apisntypes/noteapis";
import { getComments } from "../../apisntypes/commentapis";
import { getMyRecom, getMyFav } from "../../apisntypes/noteapis"; 
import { useQuery } from "@tanstack/react-query";
import {formatDate, formatTitle} from "../../lib/Utils";
import useUser from "../../lib/useUser";
import { useEffect, useState } from "react";
import "../../css/general.css";
import { newTag } from "../../lib/Utils";

export default function CateNote({
  title,
  content,
  creator,
  view_count,
  rec_count,
  updated_at,
  cate_pk,
  pk,
  pack_pk,
  addr,
  is_secret,
  count,
  orderNumber,
  searchStr,
}: INote) {
  const tag = newTag(updated_at);
  const { isLoggedIn, user, userLoading } = useUser();
  const isCreator = user && user.username === creator.username;
  const canClick = !is_secret || (is_secret && isCreator);
  const navigate = useNavigate();
  const bgColor = useColorModeValue( "gray.300","gray.700");
  const { isLoading:isLoadingComments, data: commentsData, error: commentsError } = useQuery<any>(
    ["comments", pk],
    getComments
  );
  const { isLoading: isLoadingMyRecom, data: myRecomData } = useQuery(['myRecom', pk], () => getMyRecom({pk:pk}), {
    enabled: isLoggedIn
  });
  const [isRecommended, setIsRecommended] = useState(myRecomData); 

  const { isLoading: isLoadingMyFav, data: myFavData } = useQuery(['myFav', pk], () => getMyFav({pk:pk}), {
    enabled: isLoggedIn
  });
  
  const [isFaved, setIsFaved] = useState(myFavData); 

  useEffect(() => {
    if (myRecomData !== undefined) {
      setIsRecommended(myRecomData); // Update isRecommended when myRecomData is available
    }
  }, [myRecomData]);

  useEffect(() => {
    if (myFavData !== undefined) {
      setIsFaved(myFavData); // Update isFavmended when myFavData is available
    }
  }, [myFavData]);

  const handleHitIncrement = async () => {
    // Call your hit function here, e.g., using an API request
    try {
      // Call the postView function to increment the hit count
      await postView({ pk });
    } catch (error) {
      // console.error("Error incrementing hit count:", error);
    }
  };
  const handleClick = (pack_pk, pk) => {
    handleHitIncrement();
    navigate(`/${addr}/nd/${cate_pk.pk}/${pk}`);
  }

  const formatTitle = (title, searchStr, highlightColor) => {
    // Assuming this function returns a formatted HTML string
    // Highlighting searchStr within title with the highlightColor
    return title.replace(
      new RegExp(searchStr, "gi"),
      (match) => `<span style="color:${highlightColor};">${match}</span>`
    );
  };
  const TruncatedTitle = ({ title, searchStr }) => {
    return (
      <Text
        as="b"
        my="2"
        overflow="hidden"
        fontSize={"lg"}
        // whiteSpace={{ base: 'nowrap', md: 'normal' }} // No wrapping on small screens, wrapping on larger screens
        textOverflow="ellipsis"
      >
        {searchStr !== "" ? (
          <div dangerouslySetInnerHTML={{ __html: formatTitle(title, searchStr, "teal") }} />
        ) : (
          <>{title}</>
        )}
      </Text>
    );
  };
  return (
    <>
    <VStack 
      bg={bgColor}       // Light gray background color
      boxShadow="md"      // Medium shadow
      rounded="md"        // Rounded corners
      p={1}               // Padding
      w="100%" 
      my={"2"}>
        <HStack w="100%" >
          <Box w="10%" mx="6" fontSize={'xs'} color="gray.500">
            {orderNumber}
          </Box>
          <Box fontSize={"xs"} 
          style={{
              overflow: "hidden",
              whiteSpace: "normal",
              textOverflow: "ellipsis"
          }}
           width="90%" 
           mr="2"
           title={cate_pk.name}
           >
            
            <Link to={`/${addr}/cn/${cate_pk.pk}`} >
              {cate_pk.title} 
            </Link>
          </Box>
        </HStack>
        <HStack 
          w="100%"
          mx={2} 
          justifyContent={"space-between"}
          cursor={canClick ? "pointer" : "default"}
          onClick={() => {
            if (canClick) {
              handleClick(pack_pk, pk);
            }
          }}
          >
           
          <HStack mx="6">

          <Text>
            {searchStr !=="" ? 
              <>
              <HStack>
              <div dangerouslySetInnerHTML={{ __html: formatTitle(title, searchStr, "teal") }} />
              <span dangerouslySetInnerHTML={{ __html: tag }} />
              </HStack>
              </>
              :
              <>
                {is_secret ?
                <>
                  <HStack>
                    <div dangerouslySetInnerHTML={{ __html: formatTitle(title, searchStr, "teal") }} />
                    <span dangerouslySetInnerHTML={{ __html: tag }} /> 
                    <FaLock size="12px" />
                  </HStack>
                </>
                :
                <>
                 <HStack>
                  <div>{TruncatedTitle({title, searchStr})}</div>
                  <span dangerouslySetInnerHTML={{ __html: tag }} />
                  </HStack>
                </>
                }
              </> 
              }
          </Text>
          
          {commentsData && commentsData?.length >0 &&
          <>
            <Box 
            fontSize={"sm"}
            color="gray.300" ml="2">
            <FaRegCommentDots />
            </Box>
            
            <Box 
            fontSize={"sm"}
            fontWeight={"bold"}
              color="gray.500"
            >
            ({commentsData?.length})
            </Box> 
            </>
            }

        </HStack>

        </HStack>
      <Center w="100%" mx="4">
        <HStack w="100%" ml="20" justifyContent={"space-between"}>
        
          <Box w="34%" as="i" color="gray.400">
              {creator.username}
          </Box>
          
          <Box w="16%"
              style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
            title={formatDate(updated_at)}>
              <Text fontSize="xs"> 
              {formatDate(updated_at)}
              </Text>
            </Box>
          <Box fontSize={"xs"} color="gray.500"  w="15%" >

          </Box>
          <Box fontSize={"xs"} color="gray.500"  w="15%" >
            <HStack>
              <Box>
                <FaRegEye />
              </Box>
              <Box>
                ({view_count})
              </Box>
            </HStack>
          </Box>
          <Box fontSize={"xs"} color="gray.500"  w="15%" >
            <HStack>
              <Box>
              {isLoggedIn && isRecommended ? 
                  <FaThumbsUp /> :
                  <FaRegThumbsUp />
              }
              </Box>
              <Box>
              ({rec_count})
              </Box>
            </HStack>
            </Box>
            <Box fontSize={"xs"} color="gray.500"  w="15%" >
              {isLoggedIn && isFaved ? 
              <FaHeart /> 
              :
              <FaRegHeart />  
            }
            </Box>
          
        </HStack>
      </Center>
    </VStack>
      </>

  );
}