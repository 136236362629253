import { INPack } from "./packtypes";
import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
    baseURL: 
      process.env.NODE_ENV ==="development" 
      ? "http://127.0.0.1:8000/api/v1/" 
      :  "https://backend.drimnotes.com/api/v1/",
    withCredentials: true, // 쿠키를 백앤드로 보내주는 역할
})

        
//pack
export const getNPack = ({queryKey}: QueryFunctionContext) => {
    const [_,pack_pk] = queryKey;
    return instance.get(`packs/npack/${pack_pk}`)
    .then((response) => {
        return response.data
    })
    }

export const getNPackAddr = ({queryKey}: QueryFunctionContext) => {
    const [_,addr] = queryKey;
    return instance.get(`packs/${addr}`)
    .then((response) => {
        return response.data
    })
}

export const getNPacks = (page) => {
    return instance.get(`packs/npacks/?page=${page}`)
    .then((response) => {
        return response.data
    })
}

export const getMyNPacks = (page) => {
    return instance.get(`packs/mynpacks/?page=${page}`)
    .then((response) => {
        return response.data
    })
}

export const getLanguage = () => {
    return instance.get(`packs/getlanguage/`)
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        console.error("Error deleting favorite pack:", error);
        throw error; // Rethrow the error to be caught by the caller
    });
}

export const getGroupPacks = (page) => {
    return instance.get(`packs/grouppacks/?page=${page}`)
    .then((response) => {
        return response.data
    })
}

export const getPackFavList = (page) => {
    return instance.get(`packs/packfavlist/?page=${page}`)
    .then((response) => {
        return response.data
    })
}

export const getSearchPacks = async (searchStr:string) => {
    try {
        const response = await instance.get(`packs/searchpack/${encodeURIComponent(searchStr)}`);
        return response.data;
      } catch (error) {
        
        console.error("Unexpected error:", error);
        throw new Error("An unexpected error occurred.");
        
    }
}

export const createNPack = (variables: INPack) => {
    return instance.post(
        `packs/npack/create`,
        variables,
        {
            headers: {
                "X-CSRFToken" : Cookie.get("csrftoken") || "",
            }
        }
    )
    .then((response) => response.data);
}

export const postFavPack = (pack_pk:string) => {
    return instance.post(
        `packs/postfavpack/${pack_pk}`,
        {},
        {
            headers: {
                "X-CSRFToken" : Cookie.get("csrftoken") || "",
            },
        })
    .then((response) => response.data)
}

export const deleteFavPack = (pack_pk:number) => {
    return instance.delete(
        `packs/deletefavpack/${pack_pk}`,
        {
            headers: {
                "X-CSRFToken":Cookie.get("csrftoken") || "",
            },
        })
    .then((response) => response.data)
    .catch((error) => {
        console.error("Error deleting favorite pack:", error);
        throw error; // Rethrow the error to be caught by the caller
    });
}


export const updateNPack = (pack_pk, title, desc, pubOpt, cateOpt, noteOpt, commentOpt) => {
    return instance.put(
        `packs/update/${pack_pk}`,
        {title, desc, pubOpt, cateOpt, noteOpt, commentOpt},
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken") || "",
            },
        }
    )
    .then((response) => response.data);
}

export const deletePack =  (pack_pk:number) => {
    return instance.delete(
        `packs/delete/${pack_pk}`,
    {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
    })
    .then(response =>  response.data)
}

export const checkPackAddrExist = (addr:string) => {
    return instance.get(`/packs/checkaddr/${addr}`)
    .then((response) => {
        return response.data
    })
}

export const checkPackGroup = (addr:string) => {
    return instance.get(`/packs/checkgroup/${addr}`)
    .then((response) => {
        return response.data
    })
}

export const applyGroup = (addr:string) => {
    return instance.post(`/packs/applygroup/${addr}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}

export const openGroup = (addr:string) => {
    return instance.put(`/packs/opengroup/${addr}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}
export const closeGroup = (addr:string) => {
    return instance.put(`/packs/closegroup/${addr}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}

export const cancelGroup = (addr:string) => {
    return instance.put(`/packs/cancelgroup/${addr}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}

export const getGroupMember = (addr:string) => {
    return instance.put(`/packs/getgroupmember/${addr}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}

export const acceptGroupMember = (addr:string, memberPk:number) => {
    return instance.put(`/packs/acceptgroupmember/${addr}/${memberPk}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}
export const cancelGroupMember = (addr:string, memberPk:number) => {
    return instance.put(`/packs/cancelgroupmember/${addr}/${memberPk}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}
export const deleteGroupMember = (addr:string, memberPk:number) => {
    return instance.put(`/packs/deletegroupmember/${addr}/${memberPk}`,
    {},
    {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
    )
    .then((response) => {
        return response.data
    })
}