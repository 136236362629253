import { Box, Heading, Text, VStack, HStack, Icon } from '@chakra-ui/react';
import { FaBrain, FaMousePointer, FaBolt, FaLayerGroup, FaClock } from 'react-icons/fa';

const About = () => {
    return (
        <Box p={8} bg="gray.100" minH="100vh">
            <VStack spacing={8} align="start" maxW="800px" mx="auto">
                <Heading as="h1" size="2xl" color="teal.600">About Drimnotes.com</Heading>
                <Text fontSize="lg">
                    Welcome to <strong>Drimnotes.com</strong>, your go-to platform for organized, AI-driven blogs.
                </Text>
                <Text fontSize="lg">
                    At Drimnotes, we believe that knowledge should be easily accessible and beautifully organized. Our AI-powered system curates content to help you build up knowledge with just a few clicks.
                </Text>
                <HStack spacing={4} align="center">
                    <Icon as={FaBrain} w={8} h={8} color="teal.500" />
                    <Text fontSize="lg">
                        AI-Driven Blogs: Our AI curates and organizes content tailored to your interests.
                    </Text>
                </HStack>
                <HStack spacing={4} align="center">
                    <Icon as={FaClock} w={8} h={8} color="teal.500" />
                    <Text fontSize="lg">
                        Efficiency: Build up knowledge with just a couple of clicks.
                    </Text>
                </HStack>
                <HStack spacing={4} align="center">
                    <Icon as={FaLayerGroup} w={8} h={8} color="teal.500" />
                    <Text fontSize="lg">
                        Multi-Level Categories: Explore content through our organized, multi-level categories.
                    </Text>
                </HStack>
                <HStack spacing={4} align="center">
                    <Icon as={FaBolt} w={8} h={8} color="teal.500" />
                    <Text fontSize="lg">
                        Powered by <strong>Drimtech</strong>: Innovating the way you learn and grow.
                    </Text>
                </HStack>
            </VStack>
        </Box>
    );
};

export default About;
