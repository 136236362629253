import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const ImageSlide = ({images, initialIndex, onClose}) => {
    
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState(0);
  
  useEffect(() => {
    setFullscreenImageIndex(initialIndex);
  }, [initialIndex]);

  const handleCloseFullscreen = () => {
    onClose();
  };

    const handlePreviousImage = () => {
    setFullscreenImageIndex((prevIndex) => (prevIndex - 1 + images.length) === -1 ? 0 : (prevIndex - 1 + images.length) % images.length);
    };

    const handleNextImage = () => {
    setFullscreenImageIndex((prevIndex) => (prevIndex + 1) % images.length);
};

    return (
        <>
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bg="rgba(0, 0, 0, 0.8)"
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex="1000"
                >
                <Box position="relative">
                    <img src={images[fullscreenImageIndex]} 
                    alt="Fullscreen" 
                    style={{ maxHeight: "90vh", maxWidth: "90vw" }} />
                    <Box 
                        position="absolute" 
                        top="10px" 
                        left="10px" 
                        backgroundColor="rgba(0, 0, 0, 0.5)" 
                        color="white" 
                        padding="5px" 
                        borderRadius="5px"
                    >
                        {fullscreenImageIndex + 1} / {images.length}
                    </Box>

                    <IconButton
                    position="absolute"
                    top="10px"
                    right="10px"
                    aria-label="Close Fullscreen Image"
                    icon={<CloseIcon />}
                    size="xs"
                    onClick={handleCloseFullscreen}
                    />
                    <IconButton
                    position="absolute"
                    top="50%"
                    left="10px"
                    aria-label="Previous Image"
                    icon={<ChevronLeftIcon />}
                    size="xs"
                    onClick={handlePreviousImage}
                    transform="translateY(-50%)"
                    />
                    <IconButton
                    position="absolute"
                    top="50%"
                    right="10px"
                    aria-label="Next Image"
                    icon={<ChevronRightIcon />}
                    size="xs"
                    onClick={handleNextImage}
                    transform="translateY(-50%)"
                    />
                </Box>
            </Box>
        </>
    )
}

export default ImageSlide;